import React from 'react'
import ReactDOM from 'react-dom'
import store, { history } from '@/store'
import { Provider } from 'react-redux'

import App from './app'
import * as serviceWorker from '@/serviceWorker'

const rootDOM = document.getElementById('root')

if (rootDOM) {
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    rootDOM
  )
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
