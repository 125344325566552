import { useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import { IMAGE_FIELDS, SITE_FIELDS, TRACKING_FIELDS, USER_FIELDS } from '@/constants/api-data'
import { getAvatarUrl } from '@/libs/functions/avatar'
import { generatePath } from 'react-router-dom'
import { FORM_ROUTES, IMAGE_ROUTES } from '@/routes/route-path'
import { DATE_FORMAT_HUMANIZED } from '@/constants'
import moment from 'moment'
import { isEmpty } from 'lodash'

const useError = (error) => {
  const previousError = useRef(error)
  useEffect(() => {
    if (error && previousError.current !== error) {
      message.destroy(error.message)
      message.error({ key: error.message, content: error.message })
      if (error?.redirect) {
        setTimeout(() => {
          window.location.href = error?.redirect
        }, 1000)
      }
    }
    previousError.current = error
  }, [error])
}

/**
 * Get current image table height hook
 * @param {JSX.Element} containerEl - current image table
 * @return {number}
 */
const useTableHeight = (containerEl) => {
  const [height, setHeight] = useState(0)
  useEffect(() => {
    if (containerEl) {
      setHeight(containerEl.clientHeight - 48 - 55)
    }
  }, [containerEl])
  return height
}

/**
 * Get user card data when user information change
 * @param {Object} user - user object contains firstName, lastName, role, site, ..
 * @return {{}}
 */
const useUserCardInfo = (user) => {
  const [userCardInfo, setUserCardInfo] = useState(user || {})

  useEffect(() => {
    const {
      [USER_FIELDS.ID]: id,
      [USER_FIELDS.FIRST_NAME]: firstName,
      [USER_FIELDS.LAST_NAME]: lastName,
      [USER_FIELDS.ROLE]: role,
      [USER_FIELDS.AVATAR]: avatar,
      [USER_FIELDS.STATUS]: status,
      [USER_FIELDS.ON_CALL]: onCall
    } = user
    const name = `${firstName || ''} ${lastName || ''}`
    setUserCardInfo({
      id,
      name,
      role,
      avatar: getAvatarUrl(avatar),
      status,
      onCall
    })
  }, [user])
  return userCardInfo
}

/**
 * Event listener to check if current page is visible
 * @return {unknown}
 */
const usePageVisibility = () => {
  const getBrowserVisibilityProp = () => {
    if (typeof document.hidden !== 'undefined') {
      return 'visibilitychange'
    } else if (typeof document?.msHidden !== 'undefined') {
      return 'msvisibilitychange'
    } else if (typeof document?.webkitHidden !== 'undefined') {
      return 'webkitvisibilitychange'
    }
  }
  const getBrowserDocumentHiddenProp = () => {
    if (typeof document.hidden !== 'undefined') {
      return 'hidden'
    } else if (typeof document?.msHidden !== 'undefined') {
      return 'msHidden'
    } else if (typeof document?.webkitHidden !== 'undefined') {
      return 'webkitHidden'
    }
  }
  const getIsDocumentHidden = () => {
    return !document[getBrowserDocumentHiddenProp()]
  }
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
  const onVisibilityChange = () => {
    setIsVisible(getIsDocumentHidden())
  }

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()

    document.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  })

  return isVisible
}

const formatDateTime = (value) => {
  return value ? moment(value).format(DATE_FORMAT_HUMANIZED) : ''
}

/**
 * Format tracks data
 * TODO: backend need to populate image with mrn
 * @param {Array} data - list of track
 * @param {string} studyId
 * @return {*[]}
 */
const useTrackingData = ({ data, studyId, allocatedQuestionSets }) => {
  const [result, setResult] = useState([])

  useEffect(() => {
    const temp = data?.map((each) => {
      let formUrl = ''
      let imageUrl = null
      // format siteId
      let siteId
      if (typeof each?.[TRACKING_FIELDS.SITE] === 'string') {
        siteId = each?.[TRACKING_FIELDS.SITE]
      } else {
        siteId = each?.[TRACKING_FIELDS.SITE]?.[SITE_FIELDS.ID]
      }
      // Generate enter existing form or create new form link
      if (studyId) {
        if (each?.[TRACKING_FIELDS.FORM_ID]) {
          formUrl = generatePath(FORM_ROUTES.EXIST_RECORD_WITH_STUDY_SITE, {
            id: each?.[TRACKING_FIELDS.FORM_ID],
            siteId,
            studyId: studyId
          })
        } else {
          if (allocatedQuestionSets)
            if (allocatedQuestionSets.length > 1) {
              formUrl = FORM_ROUTES.LIST
            } else if (allocatedQuestionSets.length === 1) {
              formUrl = generatePath(FORM_ROUTES.NEW_RECORD_WITH_STUDY_SITE_QUESTION_SET, {
                siteId,
                studyId: studyId,
                questionSetId: allocatedQuestionSets[0]
              })
            }
        }
      }
      // Generate existing image link
      if (each?.[TRACKING_FIELDS.IMAGE_ID] && studyId) {
        imageUrl = generatePath(IMAGE_ROUTES.IMAGE, {
          studyInstanceUID: each?.[TRACKING_FIELDS.IMAGE_ID]?.[IMAGE_FIELDS.STUDY_INSTANCE_UID],
          studyId: studyId
        })
      }
      return {
        ...each,
        key: each?.[TRACKING_FIELDS.ID],
        formUrl,
        imageUrl,
        allocatedQuestionSets,
        // [TRACKING_FIELDS.CREATED_AT]: moment(each?.[TRACKING_FIELDS.CREATED_AT]).format(
        //   DATE_FORMAT_HUMANIZED
        // ),
        // [TRACKING_FIELDS.UPDATED_AT]: moment(each?.[TRACKING_FIELDS.UPDATED_AT]).format(
        //   DATE_FORMAT_HUMANIZED
        // ),
        [TRACKING_FIELDS.DEPARTURE_TIME]: formatDateTime(each?.[TRACKING_FIELDS.DEPARTURE_TIME]),
        [TRACKING_FIELDS.ARRIVAL_TIME]: formatDateTime(each?.[TRACKING_FIELDS.ARRIVAL_TIME]),
        [TRACKING_FIELDS.ONSET_DATE_TIME]: formatDateTime(each?.[TRACKING_FIELDS.ONSET_DATE_TIME]),
        [TRACKING_FIELDS.TREATMENT_TIME]: formatDateTime(each?.[TRACKING_FIELDS.TREATMENT_TIME]),
        [TRACKING_FIELDS.EXPECTED_ARRIVAL_TIME]: moment(
          each?.[TRACKING_FIELDS.EXPECTED_ARRIVAL_TIME]
        ).format(DATE_FORMAT_HUMANIZED),
        [TRACKING_FIELDS.EXPECTED_TRAVEL_TIME]: each?.[TRACKING_FIELDS.EXPECTED_TRAVEL_TIME] * 1000,
        // Raw data
        rawCreateAt: each?.[TRACKING_FIELDS.CREATED_AT],
        [TRACKING_FIELDS.ARRIVAL_RAW_DATETIME]: each?.[TRACKING_FIELDS.ARRIVAL_TIME],
        [TRACKING_FIELDS.ONSET_RAW_DATETIME]: each?.[TRACKING_FIELDS.ONSET_DATE_TIME],
        [TRACKING_FIELDS.DEPARTURE_RAW_DATETIME]: each?.[TRACKING_FIELDS.DEPARTURE_TIME],
        [TRACKING_FIELDS.EXPECTED_ARRIVAL_RAW_DATETIME]:
          each?.[TRACKING_FIELDS.EXPECTED_ARRIVAL_TIME],
        [TRACKING_FIELDS.TREATMENT_RAW_DATETIME]: each?.[TRACKING_FIELDS.TREATMENT_TIME]
      }
    })
    setResult(temp)
  }, [data, setResult])
  return result
}

/**
 * Format single track data
 * @param {Object} data - list of track
 * @param {string} studyId
 * @return {{}}
 */
const useSingleTrackingData = (data, studyId) => {
  const [result, setResult] = useState({})

  useEffect(() => {
    let formUrl = ''
    let imageUrl = 'None'
    // Generate enter existing form or create new form link
    if (
      data?.[TRACKING_FIELDS.FORM_ID] &&
      studyId &&
      data?.[TRACKING_FIELDS.SITE] &&
      data?.[TRACKING_FIELDS.FORM_ID]
    ) {
      formUrl = generatePath(FORM_ROUTES.EXIST_RECORD_WITH_STUDY_SITE, {
        id: data?.[TRACKING_FIELDS.FORM_ID],
        siteId: data?.[TRACKING_FIELDS.SITE],
        studyId: studyId
      })
    } else if (data?.[TRACKING_FIELDS.SITE] && studyId) {
      formUrl = generatePath(FORM_ROUTES.NEW_RECORD_WITH_STUDY_SITE, {
        siteId: data?.[TRACKING_FIELDS.SITE],
        studyId: studyId
      })
    }
    // Generate existing image link
    if (data?.[TRACKING_FIELDS.IMAGE_ID] && studyId) {
      imageUrl = generatePath(IMAGE_ROUTES.IMAGE, {
        studyInstanceUID: data?.[TRACKING_FIELDS.IMAGE_ID]?.[IMAGE_FIELDS.STUDY_INSTANCE_UID],
        studyId: studyId
      })
    }
    const temp = {
      ...data,
      formUrl,
      imageUrl,
      [TRACKING_FIELDS.DEPARTURE_TIME]: moment(data?.[TRACKING_FIELDS.DEPARTURE_TIME]).format(
        DATE_FORMAT_HUMANIZED
      ),
      [TRACKING_FIELDS.EXPECTED_ARRIVAL_TIME]: moment(
        data?.[TRACKING_FIELDS.EXPECTED_ARRIVAL_TIME]
      ).format(DATE_FORMAT_HUMANIZED),
      [TRACKING_FIELDS.EXPECTED_TRAVEL_TIME]: data?.[TRACKING_FIELDS.EXPECTED_TRAVEL_TIME] * 1000,
      // Raw data
      rawCreateAt: data?.[TRACKING_FIELDS.CREATED_AT],
      [TRACKING_FIELDS.ARRIVAL_RAW_DATETIME]: data?.[TRACKING_FIELDS.ARRIVAL_TIME],
      [TRACKING_FIELDS.ONSET_RAW_DATETIME]: data?.[TRACKING_FIELDS.ONSET_DATE_TIME],
      [TRACKING_FIELDS.DEPARTURE_RAW_DATETIME]: data?.[TRACKING_FIELDS.DEPARTURE_TIME],
      [TRACKING_FIELDS.EXPECTED_ARRIVAL_RAW_DATETIME]:
        data?.[TRACKING_FIELDS.EXPECTED_ARRIVAL_TIME],
      [TRACKING_FIELDS.TREATMENT_RAW_DATETIME]: data?.[TRACKING_FIELDS.TREATMENT_TIME]
    }

    setResult(!isEmpty(data) ? temp : {})
  }, [data, setResult])

  return result
}

export {
  useError,
  useTableHeight,
  useUserCardInfo,
  usePageVisibility,
  useTrackingData,
  useSingleTrackingData
}
