import { SHARED_FIELDS } from './shared'

const QUESTION_SET_FIELDS = {
  ...SHARED_FIELDS,
  ABBR: 'abbr',
  LABEL: 'label',
  NAME: 'name',
  VARIABLE: 'variable',
  QUESTIONS: 'questions',
  REPORT_BACKGROUND: 'reportBackground',
  SKIP_REPORT_FOR: 'skipReportFor',
  DESCRIPTION: 'description',
  MAPPER: 'mapper',
  TYPE: 'type',
  IS_SELECTED: 'isSelected'
}

const QUESTION_SET_MAPPER_FIELDS = {
  ID: [SHARED_FIELDS.ID],
  TARGET: 'target',
  SOURCE: 'source'
}
const QUESTION_SET_MAPPER_SOURCE_FIELDS = {
  DATE: 'date',
  TIME: 'time'
}

const QUESTION_SET_QUESTIONS_FIELDS = {
  ID: [SHARED_FIELDS.ID],
  ABBR: 'abbr',
  LABEL: 'label',
  NAME: 'name',
  QUESTIONS: 'questions',
  TYPE: 'type'
}

const QUESTION_SET_QUESTION_FIELDS = {
  ID: [SHARED_FIELDS.ID],
  ABBR: 'abbr',
  AUTHORIZED: 'authorized',
  CONFIG: 'config',
  DESCRIPTION: 'description',
  FORMULA: 'formula',
  INPUT_TYPE: 'inputType',
  SYNC_ANSWER_WITH: 'syncAnswerWith',
  LABEL: 'label',
  NAME: 'name',
  OPTIONS: 'options',
  QUESTIONS: 'questions',
  READ_ONLY: 'readOnly',
  SHOW_IF: 'showIf',
  TYPE: 'type',
  VALIDATION: 'validation',
  VARIABLE: 'variable'
}

const QUESTION_SET_QUESTION_ENUMS = {
  TYPE: {
    QUESTION_SET: 'QUESTION_SET',
    QUESTION: 'QUESTION'
  }
}

export {
  QUESTION_SET_FIELDS,
  QUESTION_SET_MAPPER_FIELDS,
  QUESTION_SET_MAPPER_SOURCE_FIELDS,
  QUESTION_SET_QUESTIONS_FIELDS,
  QUESTION_SET_QUESTION_FIELDS,
  QUESTION_SET_QUESTION_ENUMS
}
