import Immutable from 'immutable'
// import { NOTIFICATION_TYPES } from '@/services/constants'
import { REDUCER_STATE } from '@/store/constants'
import { findIndex, uniq } from 'lodash'

import {
  ACKNOWLEDGE_SENDER,
  ACKNOWLEDGE_SENDER_FULFILLED,
  ACKNOWLEDGE_SENDER_REJECTED,
  CLEAR_NOTIFICATION_IDS,
  CREATE_NOTIFICATION_TEMPLATE,
  CREATE_NOTIFICATION_TEMPLATE_FULFILLED,
  CREATE_NOTIFICATION_TEMPLATE_REJECTED,
  DELETE_NOTIFICATION_TEMPLATE,
  DELETE_NOTIFICATION_TEMPLATE_FULFILLED,
  DELETE_NOTIFICATION_TEMPLATE_REJECTED,
  FIREBASE_INIT_REJECTED,
  FIREBASE_MESSAGING_INIT_FULFILLED,
  FIREBASE_MESSAGING_INIT_REJECTED,
  GET_NOTIFICATION,
  GET_NOTIFICATION_FULFILLED,
  GET_NOTIFICATION_REJECTED,
  GET_NOTIFICATION_TEMPLATES,
  GET_NOTIFICATION_TEMPLATES_FULFILLED,
  GET_NOTIFICATION_TEMPLATES_REJECTED,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FULFILLED,
  GET_NOTIFICATIONS_REJECTED,
  LOGOUT_FULFILLED,
  NEW_NOTIFICATION_INCOMING,
  REQUEST_NOTIFICATION_PERMISSION_FULFILLED,
  REQUEST_NOTIFICATION_PERMISSION_REJECTED,
  SEND_NOTIFICATION_TEMPLATE,
  SEND_NOTIFICATION_TEMPLATE_FULFILLED,
  SEND_NOTIFICATION_TEMPLATE_REJECTED,
  SET_IS_REGISTERING_NOTIFICATION,
  SET_IS_SHOW_ACKNOWLEDGE_MODAL,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_FULFILLED,
  SET_NOTIFICATIONS_REJECTED,
  SET_TWILIO_CHAT_NOTIFICATIONS,
  SET_TWILIO_CHAT_NOTIFICATIONS_FULFILLED,
  SET_TWILIO_CHAT_NOTIFICATIONS_REJECTED,
  UPDATE_IS_NOTIFICATION_SUPPORTED,
  UPDATE_NOTIFICATION_TEMPLATE,
  UPDATE_NOTIFICATION_TEMPLATE_FULFILLED,
  UPDATE_NOTIFICATION_TEMPLATE_ORDER,
  UPDATE_NOTIFICATION_TEMPLATE_ORDER_FULFILLED,
  UPDATE_NOTIFICATION_TEMPLATE_ORDER_REJECTED,
  UPDATE_NOTIFICATION_TEMPLATE_REJECTED
} from '@/store/types'

const {
  NOTIFICATION: { FIELDS }
} = REDUCER_STATE

const defaultValue = {
  [FIELDS.ERROR]: null,
  [FIELDS.FCM_TOKEN]: null,
  [FIELDS.IS_BINDING_CREATED]: false,
  [FIELDS.IS_CREATING_NOTIFICATION_TEMPLATES]: false,
  [FIELDS.IS_DELETING_NOTIFICATION_TEMPLATES]: false,
  [FIELDS.IS_GETTING_NOTIFICATIONS]: false,
  [FIELDS.IS_GETTING_NOTIFICATION_TEMPLATES]: false,
  [FIELDS.IS_NOTIFICATION_SUPPORTED]: false,
  [FIELDS.IS_PERMISSION_GRANTED]: false,
  [FIELDS.IS_SETTING_NOTIFICATIONS]: false,
  [FIELDS.IS_UPDATING_NOTIFICATION_TEMPLATES]: false,
  [FIELDS.IS_SENDING_NOTIFICATION]: false,
  [FIELDS.IS_ACKNOWLEDGING_SENDER]: false,
  [FIELDS.IS_GETTING_NOTIFICATION]: false,
  [FIELDS.IS_SHOW_ACKNOWLEDGE_STATUS_MODAL]: false,
  [FIELDS.NOTIFICATION]: null,
  [FIELDS.NOTIFICATIONS]: [],
  [FIELDS.NOTIFICATION_TOTAL_PAGE]: null,
  [FIELDS.NOTIFICATION_TEMPLATES]: [],
  [FIELDS.TWILIO_CHAT_NOTIFICATION_PAYLOAD]: null,
  [FIELDS.NEW_NOTIFICATION_IDS]: {},
  [FIELDS.INCOMING_NOTIFICATION]: null,
  [FIELDS.IS_REGISTERING_NOTIFICATION]: false
}

const initialState = new Immutable.Map(defaultValue)

export default {
  initialState,
  handlers: {
    [FIREBASE_INIT_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload
      }),
    [UPDATE_IS_NOTIFICATION_SUPPORTED]: (state, action) =>
      state.merge({
        [FIELDS.IS_NOTIFICATION_SUPPORTED]: action.payload
      }),
    [REQUEST_NOTIFICATION_PERMISSION_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_PERMISSION_GRANTED]: true
      }),
    [REQUEST_NOTIFICATION_PERMISSION_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload
      }),
    [FIREBASE_MESSAGING_INIT_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_BINDING_CREATED]: true,
        [FIELDS.FCM_TOKEN]: action.payload
      }),
    [FIREBASE_MESSAGING_INIT_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload
      }),
    [GET_NOTIFICATIONS]: (state, action) => {
      const newState = {
        [FIELDS.ERROR]: null,
        [FIELDS.IS_GETTING_NOTIFICATIONS]: true
      }
      if (action.payload.skip === 0) {
        newState[FIELDS.NOTIFICATIONS] = []
      }
      return state.merge(newState)
    },
    [GET_NOTIFICATIONS_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.NOTIFICATIONS]: [
          ...state.getIn([FIELDS.NOTIFICATIONS]),
          ...action.payload.notifications
        ],
        [FIELDS.NOTIFICATION_TOTAL_PAGE]: action.payload.totalPage,
        [FIELDS.IS_GETTING_NOTIFICATIONS]: false
      }),
    [GET_NOTIFICATIONS_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_GETTING_NOTIFICATIONS]: false
      }),
    [SET_NOTIFICATIONS]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_SETTING_NOTIFICATIONS]: true
      }),
    [SET_NOTIFICATIONS_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.NOTIFICATIONS]: action.payload,
        [FIELDS.IS_SETTING_NOTIFICATIONS]: false
      }),
    [SET_NOTIFICATIONS_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_SETTING_NOTIFICATIONS]: false
      }),
    [SET_TWILIO_CHAT_NOTIFICATIONS]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_SETTING_NOTIFICATIONS]: true
      }),
    [SET_TWILIO_CHAT_NOTIFICATIONS_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.TWILIO_CHAT_NOTIFICATION_PAYLOAD]: action.payload,
        [FIELDS.IS_SETTING_NOTIFICATIONS]: false
      }),
    [SET_TWILIO_CHAT_NOTIFICATIONS_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_SETTING_NOTIFICATIONS]: false
      }),
    [ACKNOWLEDGE_SENDER]: (state) => {
      const newState = {
        [FIELDS.ERROR]: null,
        [FIELDS.IS_ACKNOWLEDGING_SENDER]: true
      }
      return state.merge(newState)
    },
    [ACKNOWLEDGE_SENDER_FULFILLED]: (state, action) => {
      const notifications = state.getIn([FIELDS.NOTIFICATIONS])
      const index = findIndex(notifications, { _id: action.payload.notificationId })
      let newNotifications = [...notifications]
      if (index !== -1) {
        newNotifications = [
          ...notifications.slice(0, index),
          {
            ...notifications[index],
            acknowledge: true
          },
          ...notifications.slice(index + 1)
        ]
      }
      return state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.NOTIFICATIONS]: newNotifications,
        [FIELDS.IS_ACKNOWLEDGING_SENDER]: false
      })
    },
    [ACKNOWLEDGE_SENDER_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_ACKNOWLEDGING_SENDER]: false
      }),
    [GET_NOTIFICATION]: (state) => {
      const newState = {
        [FIELDS.ERROR]: null,
        [FIELDS.IS_GETTING_NOTIFICATION]: true
      }
      return state.merge(newState)
    },
    [GET_NOTIFICATION_FULFILLED]: (state, action) => {
      return state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.NOTIFICATION]: action.payload,
        [FIELDS.IS_GETTING_NOTIFICATION]: false
      })
    },
    [GET_NOTIFICATION_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_GETTING_NOTIFICATION]: false
      }),
    /**
     * Notification templates
     */
    [SEND_NOTIFICATION_TEMPLATE]: (state) => {
      const newState = {
        [FIELDS.ERROR]: null,
        [FIELDS.IS_SENDING_NOTIFICATION]: true
      }
      return state.merge(newState)
    },
    [SEND_NOTIFICATION_TEMPLATE_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_SENDING_NOTIFICATION]: false
      }),
    [SEND_NOTIFICATION_TEMPLATE_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_SENDING_NOTIFICATION]: false
      }),
    [UPDATE_NOTIFICATION_TEMPLATE_ORDER]: (state) => {
      const newState = {
        [FIELDS.ERROR]: null,
        [FIELDS.IS_UPDATING_NOTIFICATION_TEMPLATES]: true
      }
      return state.merge(newState)
    },
    [UPDATE_NOTIFICATION_TEMPLATE_ORDER_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.NOTIFICATION_TEMPLATES]: action.payload,
        [FIELDS.IS_UPDATING_NOTIFICATION_TEMPLATES]: false
      }),
    [UPDATE_NOTIFICATION_TEMPLATE_ORDER_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_UPDATING_NOTIFICATION_TEMPLATES]: false
      }),
    [GET_NOTIFICATION_TEMPLATES]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.NOTIFICATION_TEMPLATES]: [],
        [FIELDS.IS_GETTING_NOTIFICATION_TEMPLATES]: true
      }),
    [GET_NOTIFICATION_TEMPLATES_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.NOTIFICATION_TEMPLATES]: action.payload,
        [FIELDS.IS_GETTING_NOTIFICATION_TEMPLATES]: false
      }),
    [GET_NOTIFICATION_TEMPLATES_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_GETTING_NOTIFICATION_TEMPLATES]: false
      }),
    [CREATE_NOTIFICATION_TEMPLATE]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_CREATING_NOTIFICATION_TEMPLATES]: true
      }),
    [CREATE_NOTIFICATION_TEMPLATE_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.NOTIFICATION_TEMPLATES]: [
          action.payload,
          ...state.getIn([FIELDS.NOTIFICATION_TEMPLATES])
        ],
        [FIELDS.IS_CREATING_NOTIFICATION_TEMPLATES]: false
      }),
    [CREATE_NOTIFICATION_TEMPLATE_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_CREATING_NOTIFICATION_TEMPLATES]: false
      }),
    [UPDATE_NOTIFICATION_TEMPLATE]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_UPDATING_NOTIFICATION_TEMPLATES]: true
      }),
    [UPDATE_NOTIFICATION_TEMPLATE_FULFILLED]: (state, action) => {
      let notificationTemplates = state.getIn([FIELDS.NOTIFICATION_TEMPLATES])
      const idx = notificationTemplates.find((template) => template._id === action.payload._id)
      if (idx !== -1) {
        notificationTemplates[idx] = action.payload
      }
      return state.merge({
        [FIELDS.NOTIFICATION_TEMPLATES]: [...notificationTemplates],
        [FIELDS.IS_UPDATING_NOTIFICATION_TEMPLATES]: false
      })
    },
    [UPDATE_NOTIFICATION_TEMPLATE_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_UPDATING_NOTIFICATION_TEMPLATES]: false
      }),
    [DELETE_NOTIFICATION_TEMPLATE]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_DELETING_NOTIFICATION_TEMPLATES]: true
      }),
    [DELETE_NOTIFICATION_TEMPLATE_FULFILLED]: (state, action) => {
      let notificationTemplates = state.getIn([FIELDS.NOTIFICATION_TEMPLATES])
      const idx = notificationTemplates.find((template) => template._id === action.payload)
      if (idx !== -1) {
        notificationTemplates.splice(idx, 1)
      }
      return state.merge({
        [FIELDS.NOTIFICATION_TEMPLATES]: [...notificationTemplates],
        [FIELDS.IS_DELETING_NOTIFICATION_TEMPLATES]: false
      })
    },
    [DELETE_NOTIFICATION_TEMPLATE_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_DELETING_NOTIFICATION_TEMPLATES]: false
      }),
    // Pass notification type and notification id, then it will add new notification id to the related list
    [NEW_NOTIFICATION_INCOMING]: (state, action) => {
      const currentNewNotificationIds = state.getIn([FIELDS.NEW_NOTIFICATION_IDS])
      const receivedNotificationIds =
        state.getIn([FIELDS.NEW_NOTIFICATION_IDS, action.payload?.type]) || []
      return state.merge({
        [FIELDS.INCOMING_NOTIFICATION]: action.payload,
        [FIELDS.NEW_NOTIFICATION_IDS]: {
          ...currentNewNotificationIds,
          [action.payload?.type]: uniq([...receivedNotificationIds, action.payload?._id])
        }
      })
    },
    // Pass notification type, then it will set related notification id list as []
    [CLEAR_NOTIFICATION_IDS]: (state, action) => {
      const currentNotificationIds = state.getIn([FIELDS.NEW_NOTIFICATION_IDS])
      return state.merge({
        [FIELDS.NEW_NOTIFICATION_IDS]: {
          ...currentNotificationIds,
          [action.payload]: []
        }
      })
    },
    [SET_IS_REGISTERING_NOTIFICATION]: (state, action) => {
      return state.merge({
        [FIELDS.IS_REGISTERING_NOTIFICATION]: action.payload
      })
    },
    [SET_IS_SHOW_ACKNOWLEDGE_MODAL]: (state, action) => {
      if (action.payload) {
        return state.merge({
          [FIELDS.IS_SHOW_ACKNOWLEDGE_STATUS_MODAL]: action.payload
        })
      } else {
        return state.merge({
          [FIELDS.IS_SHOW_ACKNOWLEDGE_STATUS_MODAL]: action.payload,
          [FIELDS.NOTIFICATION]: null
        })
      }
    },
    [LOGOUT_FULFILLED]: (state) => {
      return state.merge({
        ...defaultValue
      })
    }
  }
}
