import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

/**
 * Get general info about roles, studies and sites, which is used for authentication check
 * @return {Promise<{roles: *, studies, sites: *, siteTypes: *}>}
 */
const getGeneralInfo = async () => {
  try {
    const generalInfo = extractResponse(await API.get(ENDPOINTS.GENERAL_APP_INFO))
    return {
      roles: generalInfo.roles,
      siteTypes: generalInfo.availableSiteType,
      sites: generalInfo.sitesWithinStudy,
      studies: generalInfo.studies,
      analyticReportTemplate: generalInfo.analyticReportTemplates
    }
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get current version number
 * @return {Promise<*>}
 */
const getApiVersion = async () => {
  try {
    return extractResponse(await API.get(ENDPOINTS.API_VERSION))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get study list and role list which is used for dropdown list for user registration
 * @return {Promise<*>}
 */
const handleGetRegistrationInfo = async () => {
  try {
    return extractResponse(await API.get(ENDPOINTS.REGISTRATION_INFO))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get mapbox token
 * @return {Promise<*>}
 */
const getMapboxToken = async () => {
  try {
    return extractResponse(await API.get(ENDPOINTS.MAPBOX_TOKEN))
  } catch (err) {
    errorHandler(err)
  }
}

export { getGeneralInfo, getApiVersion, handleGetRegistrationInfo, getMapboxToken }
