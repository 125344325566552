import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { ROOT_ROUTES } from '@/routes/route-path'
import Loading from '@/components/loading'
import authEpics from '@/store/epics/auth'
import { REDUCER_STATE } from '@/store/constants'
import { getUserPermission } from '@/libs/functions/auth'
import {
  AuthLayoutContentWrapper,
  AuthLayoutLowerWrapper,
  AuthLayoutWrapper
} from '@/styles/layout'
import Header from '@/components/header'
import Navigation from '@/components/navigation'
import classNames from 'classnames'

/**
 * Check current user CAN_ACCESS_ADMIN_PAGE permission before enter the view
 */

const PermissionRoute = (props) => {
  const {
    component: Component,
    permission,
    activeTab,
    isBackgroundGrey = false,
    isHideNavigation = false,
    ...otherProps
  } = props
  const { path } = otherProps
  const dispatch = useDispatch()
  const isAuthorized = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.IS_AUTHORIZED])
  )
  const isVerifying = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.IS_VERIFYING])
  )

  const currentUser = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.USER])
  )

  const isGettingGeneralInfo = useSelector((state) =>
    state.getIn([REDUCER_STATE.GENERAL_INFO.NAME, REDUCER_STATE.GENERAL_INFO.FIELDS.IS_PENDING])
  )

  const roles = useSelector((state) =>
    state.getIn([REDUCER_STATE.GENERAL_INFO.NAME, REDUCER_STATE.GENERAL_INFO.FIELDS.DATA])
  )?.roles

  const [isPending, setIsPending] = useState(true)
  const [currentUserPermission, setCurrentUserPermission] = useState(
    getUserPermission(currentUser, roles)
  )
  const prevIsGettingGeneralInfo = useRef(isGettingGeneralInfo)
  const prevIsVerifying = useRef(isVerifying)

  /**
   * Request to check if current user is authorized
   */
  useEffect(() => {
    dispatch(authEpics.auth())
  }, [path])

  useEffect(() => {
    if (!isGettingGeneralInfo && prevIsGettingGeneralInfo.current) {
      setIsPending(false)
    } else if (isVerifying && !isAuthorized) {
      setIsPending(true)
    } else if (!isVerifying && prevIsVerifying.current && !isAuthorized) {
      setIsPending(false)
    }
    prevIsGettingGeneralInfo.current = isGettingGeneralInfo
    prevIsVerifying.current = isVerifying
  }, [isVerifying, isGettingGeneralInfo, isAuthorized])

  useEffect(() => {
    setCurrentUserPermission(getUserPermission(currentUser, roles))
  }, [currentUser, roles])

  let canAccess = isAuthorized
  if (canAccess) {
    for (const key of Object.keys(permission)) {
      if (currentUserPermission[key] !== permission[key]) {
        canAccess = false
        break
      }
    }
  }
  return (
    <AuthLayoutWrapper>
      {activeTab && <Header active={activeTab} />}
      <AuthLayoutLowerWrapper className={classNames({ grey: isBackgroundGrey })}>
        {!isHideNavigation && <Navigation />}
        <AuthLayoutContentWrapper>
          <Route
            {...otherProps}
            render={(props) =>
              !isPending ? (
                canAccess ? (
                  <Component {...props} permission={currentUserPermission} />
                ) : (
                  <Redirect to={ROOT_ROUTES.HOME} />
                )
              ) : (
                <Loading />
              )
            }
          />
        </AuthLayoutContentWrapper>
      </AuthLayoutLowerWrapper>
    </AuthLayoutWrapper>
  )
}

PermissionRoute.propTypes = {
  component: PropTypes.object.isRequired,
  permission: PropTypes.object.isRequired,
  activeTab: PropTypes.string,
  isBackgroundGrey: PropTypes.bool,
  isHideNavigation: PropTypes.bool
}

export default PermissionRoute
