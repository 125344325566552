import { AUTHORIZATIONS, HUB_SITE_TYPE } from '@/constants/authorization'

const isSuperAdmin = (user, roles) => {
  if (user && roles) {
    return user.role === roles.superAdminRole
  }
  return false
}

const isStudyAdmin = (user, roles) => {
  if (user && roles) {
    return user.role === roles.studyAdminRole
  }
  return false
}

const getUserPermission = (user, roles) => {
  if (isSuperAdmin(user, roles)) {
    return AUTHORIZATIONS.SUPER_ADMIN
  } else if (isStudyAdmin(user, roles)) {
    return AUTHORIZATIONS.STUDY_ADMIN
  } else if (user?.site?.siteType === HUB_SITE_TYPE) {
    return AUTHORIZATIONS.HUB_USER
  } else {
    return AUTHORIZATIONS.NORMAL_USER
  }
}
export { getUserPermission, isSuperAdmin, isStudyAdmin }
