import { REDUCER_STATE } from '@/store/constants'
import { findIndex } from 'lodash'
import {
  GET_CONTACTS,
  GET_CONTACTS_FULFILLED,
  GET_CONTACTS_REJECTED,
  UPDATE_CONTACTS
} from '@/store/types'
import Immutable from 'immutable'
import { SHARED_FIELDS } from '@/constants/api-data'

const FIELDS = REDUCER_STATE.CONTACTS.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.DATA]: null,
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING_CONTACTS]: false
})

const handlers = {
  [GET_CONTACTS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_CONTACTS]: true,
      [FIELDS.DATA]: null
    }),
  [GET_CONTACTS_FULFILLED]: (state, action) => {
    return state.merge({
      [FIELDS.IS_GETTING_CONTACTS]: false,
      [FIELDS.DATA]: action.payload
    })
  },
  [GET_CONTACTS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_CONTACTS]: false
    }),

  [UPDATE_CONTACTS]: (state, action) => {
    const updatedUser = action.payload?.user || null
    const currentData = state.getIn([FIELDS.DATA])
    if (updatedUser) {
      const index = findIndex(currentData, { [SHARED_FIELDS.ID]: updatedUser?._id })
      let newUsers
      if (index !== -1) {
        newUsers = [
          ...(currentData || []).slice(0, index),
          updatedUser,
          ...(currentData || []).slice(index + 1)
        ]
      } else {
        newUsers = [...currentData, updatedUser]
      }

      return state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.DATA]: newUsers
      })
    } else {
      return state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.DATA]: currentData
      })
    }
  }
}

export default {
  initialState,
  handlers
}
