import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
  AUTH,
  AUTH_FULFILLED,
  AUTH_REJECTED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FULFILLED,
  CHANGE_PASSWORD_REJECTED,
  DELETE_SELF_ACCOUNT,
  DELETE_SELF_ACCOUNT_FULFILLED,
  DELETE_SELF_ACCOUNT_REJECTED,
  EJECT_USER,
  EJECT_USER_FULFILLED,
  EJECT_USER_REJECTED,
  LOGIN,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  RE_VERIFY_EMAIL,
  RE_VERIFY_EMAIL_FULFILLED,
  RE_VERIFY_EMAIL_REJECTED,
  REGISTER,
  REGISTER_FULFILLED,
  REGISTER_REJECTED,
  RESET_PASSWORD,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_REJECTED,
  SEND_PASSWORD_RESET_EMAIL,
  SEND_PASSWORD_RESET_EMAIL_FULFILLED,
  SEND_PASSWORD_RESET_EMAIL_REJECTED,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_FULFILLED,
  UPDATE_CURRENT_USER_REJECTED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_FULFILLED,
  VERIFY_EMAIL_REJECTED
} from '@/store/types'
import {
  auth,
  changePassword,
  deleteSelfAccount,
  handleEjectUser,
  handleRegister,
  handleResendVerificationEmail,
  handleResetPassword,
  handleSendResetPasswordEmail,
  handleVerifyEmail,
  login,
  logout,
  updateCurrentUser
} from '@/services/api'
import { setCurrentUser } from '@/services/localstorage'
import generalEpics from './general'

const authenticateEpics = {
  auth: () => ({
    type: AUTH
  }),

  authFulfilled: (payload) => ({
    type: AUTH_FULFILLED,
    payload
  }),

  authError: (error) => ({
    type: AUTH_REJECTED,
    payload: error
  }),

  authEpic: (action$) =>
    action$.pipe(
      ofType(AUTH),
      mergeMap(() =>
        from(auth()).pipe(
          mergeMap((user) => setCurrentUser(user)),
          mergeMap((user) => [
            generalEpics.getGeneralInfo(),
            authenticateEpics.authFulfilled(user)
          ]),
          catchError((error) => of(authenticateEpics.authError(error)))
        )
      )
    ),

  login: ({ username, password, remember }) => ({
    type: LOGIN,
    payload: { username, password, remember }
  }),

  loginFulfilled: () => ({
    type: LOGIN_FULFILLED
  }),

  loginError: (error) => ({
    type: LOGIN_REJECTED,
    payload: error
  }),

  loginEpic: (action$) =>
    action$.pipe(
      ofType(LOGIN),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(login(action.payload)).pipe(
          map(() => authenticateEpics.loginFulfilled()),
          catchError((error) => of(authenticateEpics.loginError(error)))
        )
      )
    ),

  register: (payload) => ({
    type: REGISTER,
    payload
  }),

  registerFulFilled: () => ({
    type: REGISTER_FULFILLED
  }),

  registerError: (payload) => ({
    type: REGISTER_REJECTED,
    payload
  }),

  registerEpic: (action$) =>
    action$.pipe(
      ofType(REGISTER),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(handleRegister(action.payload)).pipe(
          map(() => authenticateEpics.registerFulFilled()),
          catchError((error) => of(authenticateEpics.registerError(error)))
        )
      )
    ),

  sendResetPasswordEmail: (payload) => ({
    type: SEND_PASSWORD_RESET_EMAIL,
    payload
  }),

  sendResetPasswordEmailFulfilled: () => ({
    type: SEND_PASSWORD_RESET_EMAIL_FULFILLED
  }),

  sendResetPasswordEmailError: (error) => ({
    type: SEND_PASSWORD_RESET_EMAIL_REJECTED,
    payload: error
  }),

  sendResetPasswordEmailEpic: (action$) =>
    action$.pipe(
      ofType(SEND_PASSWORD_RESET_EMAIL),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(handleSendResetPasswordEmail(action.payload)).pipe(
          map(() => authenticateEpics.sendResetPasswordEmailFulfilled()),
          catchError((error) => of(authenticateEpics.sendResetPasswordEmailError(error)))
        )
      )
    ),

  resetPassword: ({ password, token, userId }) => ({
    type: RESET_PASSWORD,
    payload: { password, token, userId }
  }),

  resetPasswordFulfilled: () => ({
    type: RESET_PASSWORD_FULFILLED
  }),

  resetPasswordError: (error) => ({
    type: RESET_PASSWORD_REJECTED,
    payload: error
  }),

  resetPasswordEpic: (action$) =>
    action$.pipe(
      ofType(RESET_PASSWORD),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(handleResetPassword(action.payload)).pipe(
          map(() => authenticateEpics.resetPasswordFulfilled()),
          catchError((error) => of(authenticateEpics.resetPasswordError(error)))
        )
      )
    ),

  logout: () => ({
    type: LOGOUT
  }),

  logoutFulfilled: () => ({
    type: LOGOUT_FULFILLED
  }),

  logoutError: (error) => ({
    type: LOGOUT_REJECTED,
    payload: error
  }),

  logoutEpic: (action$) =>
    action$.pipe(
      ofType(LOGOUT),
      mergeMap(() =>
        from(logout()).pipe(
          map(() => authenticateEpics.logoutFulfilled()),
          catchError((error) => of(authenticateEpics.logoutError(error)))
        )
      )
    ),

  updateCurrentUser: (payload) => ({
    type: UPDATE_CURRENT_USER,
    payload
  }),

  updateCurrentUserFulfilled: (payload) => ({
    type: UPDATE_CURRENT_USER_FULFILLED,
    payload
  }),

  updateCurrentUserError: (error) => ({
    type: UPDATE_CURRENT_USER_REJECTED,
    payload: error
  }),

  updateCurrentUserEpic: (action$) =>
    action$.pipe(
      ofType(UPDATE_CURRENT_USER),
      mergeMap((action) =>
        from(updateCurrentUser(action.payload)).pipe(
          mergeMap((user) => setCurrentUser(user)),
          map(authenticateEpics.updateCurrentUserFulfilled),
          catchError((error) => of(authenticateEpics.updateCurrentUserError(error)))
        )
      )
    ),

  changePassword: (payload) => ({
    type: CHANGE_PASSWORD,
    payload
  }),

  changePasswordFulfilled: () => ({
    type: CHANGE_PASSWORD_FULFILLED
  }),

  changePasswordError: (error) => ({
    type: CHANGE_PASSWORD_REJECTED,
    payload: error
  }),

  changePasswordEpic: (action$) =>
    action$.pipe(
      ofType(CHANGE_PASSWORD),
      mergeMap((action) =>
        from(changePassword(action.payload)).pipe(
          map(authenticateEpics.changePasswordFulfilled),
          catchError((error) => of(authenticateEpics.changePasswordError(error)))
        )
      )
    ),

  verifyEmail: (payload) => ({
    type: VERIFY_EMAIL,
    payload
  }),

  verifyEmailFulfilled: () => ({
    type: VERIFY_EMAIL_FULFILLED
  }),

  verifyEmailError: (error) => ({
    type: VERIFY_EMAIL_REJECTED,
    payload: error
  }),

  verifyEmailEpic: (action$) =>
    action$.pipe(
      ofType(VERIFY_EMAIL),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(handleVerifyEmail(action.payload)).pipe(
          map(authenticateEpics.verifyEmailFulfilled),
          catchError((error) => of(authenticateEpics.verifyEmailError(error)))
        )
      )
    ),

  reVerifyEmail: (payload) => ({
    type: RE_VERIFY_EMAIL,
    payload
  }),

  reVerifyEmailFulfilled: () => ({
    type: RE_VERIFY_EMAIL_FULFILLED
  }),

  reVerifyEmailError: (error) => ({
    type: RE_VERIFY_EMAIL_REJECTED,
    payload: error
  }),

  reVerifyEmailEpic: (action$) =>
    action$.pipe(
      ofType(RE_VERIFY_EMAIL),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(handleResendVerificationEmail(action.payload)).pipe(
          map(authenticateEpics.reVerifyEmailFulfilled),
          catchError((error) => of(authenticateEpics.reVerifyEmailError(error)))
        )
      )
    ),

  ejectUser: (payload) => ({
    type: EJECT_USER,
    payload
  }),

  ejectUserFulfilled: (payload) => ({
    type: EJECT_USER_FULFILLED,
    payload
  }),

  ejectUserError: (error) => ({
    type: EJECT_USER_REJECTED,
    payload: error
  }),

  ejectUserEpic: (action$) =>
    action$.pipe(
      ofType(EJECT_USER),
      mergeMap((action) =>
        from(handleEjectUser(action.payload)).pipe(
          map(authenticateEpics.ejectUserFulfilled),
          catchError((error) => of(authenticateEpics.ejectUserError(error)))
        )
      )
    ),

  deleteSelfAccount: () => ({
    type: DELETE_SELF_ACCOUNT,
    payload: {}
  }),

  deleteSelfAccountFulfilled: () => ({
    type: DELETE_SELF_ACCOUNT_FULFILLED
  }),

  deleteSelfAccountError: (error) => ({
    type: DELETE_SELF_ACCOUNT_REJECTED,
    payload: error
  }),

  deleteSelfAccountEpic: (action$) =>
    action$.pipe(
      ofType(DELETE_SELF_ACCOUNT),
      filter((action) => action.payload !== undefined),
      mergeMap(() =>
        from(deleteSelfAccount()).pipe(
          map(authenticateEpics.deleteSelfAccountFulfilled),
          catchError((error) => of(authenticateEpics.deleteSelfAccountError(error)))
        )
      )
    )
}

export default authenticateEpics
