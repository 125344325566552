import React, { lazy, Suspense, useEffect, useState } from 'react'
import { HashRouter, matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '@/components/loading'
import notificationEpics from '@/store/epics/notification'
import { AuthRoute, LoginRoute, PermissionRoute, PublicRoute } from '@/routes/utils'
import { PERMISSION_KEYS } from '@/constants/authorization'
import { ROOT_ROUTES, ROOT_ROUTES as ROUTES } from './route-path'
import {
  CLIENT_MESSAGE_TYPE,
  NOTIFICATION_TYPES,
  SERVICE_WORKER_MESSAGE_TYPE
} from '@/services/constants'
import { SYSTEM_NOTIFICATION_FIELDS } from '@/constants/api-data/rawNotification'
import contactEpics from '@/store/epics/contacts'
import conferenceEpics from '@/store/epics/conference'
import { REDUCER_STATE } from '@/store/constants'
import trackEpics from '@/store/epics/track'
import { MENU } from '@/components/header'
import { LayoutBodyWrapper, LayoutFooterWrapper, LayoutWrapper } from '@/styles/layout'
import { STUDY_FIELDS, USER_FIELDS } from '@/constants/api-data'
import audioEpics from '@/store/epics/audio'
import Audio from '@/components/audio'

const AdminView = lazy(() => import('@/views/admin'))
const FormView = lazy(() => import('@/views/form'))
const HomeView = lazy(() => import('@/views/home'))
const ImageView = lazy(() => import('@/views/images'))
const LoginView = lazy(() => import('@/views/login'))
const MobileView = lazy(() => import('@/views/mobile'))
const SessionTimer = lazy(() => import('@/components/session-timer'))
const ChatComponent = lazy(() => import('@/components/chat'))
const TrackingHistoryPopup = lazy(() => import('@/components/tracking-history-popup'))
const ConferenceComponent = lazy(() => import('@/components/floating-conference'))
const LiveCameraComponent = lazy(() => import('@/components/live-camera'))
const EmailSentView = lazy(() => import('@/views/email-sent'))
const ForgetPasswordView = lazy(() => import('@/views/forget-password'))
const ResetPasswordView = lazy(() => import('@/views/reset-password'))
const ResetPasswordSuccessView = lazy(() => import('@/views/reset-password-success'))
const RegisterView = lazy(() => import('@/views/register'))
const RegisterSuccessView = lazy(() => import('@/views/register-success'))
const EmailVerifyingView = lazy(() => import('@/views/email-verifying'))
const EmailNotVerifiedView = lazy(() => import('@/views/email-not-verified'))
const AccountNotApprovedView = lazy(() => import('@/views/account-not-approved'))
const PatientTrackingView = lazy(() => import('@/views/patient-tracking'))
const ChartsView = lazy(() => import('@/views/charts'))

const RootRoutes = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const currentUser = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.USER])
  )
  const [isReady, setIsReady] = useState(false)

  /**
   * Stop notification sound
   */
  const handleStopNotificationSound = () => {
    dispatch(audioEpics.pauseAudio())
  }

  /**
   * listen on notifications when current browser tab is at background
   * Note: notice whether notification is sent from NEW_BACKGROUND_NOTIFICATION or other SERVICE_WORKER_MESSAGE_TYPE
   */
  useEffect(() => {
    setIsReady(true)
    if (navigator?.serviceWorker?.addEventListener) {
      const onBackGroupMessageReceived = (event) => {
        const eventType = event.data.type
        const eventPayload = event.data.payload
        if (!eventType) {
          return
        }
        // Deal with notification click
        if (eventType === SERVICE_WORKER_MESSAGE_TYPE.CONFERENCE_RESPONDED) {
          handleStopNotificationSound()
        }
        // deal with twilio chat
        else if (eventType === SERVICE_WORKER_MESSAGE_TYPE.TWILIO_CHAT_NOTIFICATION) {
          dispatch(notificationEpics.setTwilioChatNotifications(eventPayload))
        }
        // Deal with notification popup
        else if (eventType === SERVICE_WORKER_MESSAGE_TYPE.NEW_BACKGROUND_NOTIFICATION) {
          const notificationType = eventPayload[SYSTEM_NOTIFICATION_FIELDS.TYPE.name]
          // Stop sound when room ended or responded
          if (
            [
              NOTIFICATION_TYPES.CONFERENCE_RESPONDED,
              NOTIFICATION_TYPES.CONFERENCE_ROOM_END
            ].includes(notificationType)
          ) {
            handleStopNotificationSound()
          }
          // update contacts
          if (
            (notificationType === NOTIFICATION_TYPES.USER_ON_CALL_STATUS_UPDATED ||
              notificationType === NOTIFICATION_TYPES.USER_ON_DUTY_STATUS_UPDATED) &&
            location.pathname === ROOT_ROUTES.HOME
          ) {
            dispatch(contactEpics.updateContacts(eventPayload.data))
          }
          // Get and update track list
          if (
            [
              NOTIFICATION_TYPES.TRACK_CREATED,
              NOTIFICATION_TYPES.TRACK_ARRIVED,
              NOTIFICATION_TYPES.TRACK_CREATED_SELF,
              NOTIFICATION_TYPES.TRACK_ARRIVED_SELF,
              NOTIFICATION_TYPES.TRACK_BEFORE_ARRIVAL,
              NOTIFICATION_TYPES.TRACK_COMPLETED
            ].includes(notificationType) &&
            location.pathname === ROOT_ROUTES.PATIENT_TRACKING
          ) {
            dispatch(
              trackEpics.getAndUpdateTrackFromNotify({
                studyId: eventPayload?.data?.studyId,
                id: eventPayload?.data?.trackId,
                notificationType: notificationType
              })
            )
          }
          // If notification contains sound, then play it
          if (eventPayload?.sound) {
            dispatch(audioEpics.pauseAudio())
            dispatch(
              audioEpics.playAudio({ url: eventPayload?.sound, loop: eventPayload?.playAudioLoop })
            )
          }
          // trigger new notification redux action when new notification comes in
          dispatch(notificationEpics.newNotificationIncoming(event.data.payload))
        } else if (eventType === SERVICE_WORKER_MESSAGE_TYPE.CONFERENCE_INVITATION) {
          const { response, roomName, studyId } = eventPayload
          dispatch(
            conferenceEpics.responseInvitation({
              studyId,
              roomName,
              response
            })
          )
        } else if (eventType === SERVICE_WORKER_MESSAGE_TYPE.ACKNOWLEDGE_SENDER) {
          dispatch(notificationEpics.acknowledgeSender(eventPayload))
        }
      }
      /**
       * Listen to service worker message
       */
      navigator.serviceWorker.addEventListener('message', onBackGroupMessageReceived)
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({ type: CLIENT_MESSAGE_TYPE.CLIENT_READY })
      }
      return () => {
        navigator.serviceWorker.removeEventListener('message', onBackGroupMessageReceived)
      }
    }
  }, [location])

  if (!isReady) {
    return <Loading fullscreen={true} />
  } else {
    return (
      <HashRouter getUserConfirmation={() => {}}>
        <Suspense fallback={<Loading fullscreen={true} />}>
          <LayoutWrapper>
            <LayoutBodyWrapper
              ishidefooter={matchPath(location.pathname, {
                path: ROOT_ROUTES.MOBILE,
                strict: false,
                exact: false
              })}
            >
              <Audio />
              <Switch>
                <PublicRoute path={ROUTES.REGISTER} component={RegisterView} />
                <PublicRoute path={ROUTES.REGISTER_SUCCESS} component={RegisterSuccessView} />
                <PublicRoute path={ROUTES.EMAIL_VERIFY} component={EmailVerifyingView} />
                <PublicRoute
                  path={ROUTES.RE_SEND_EMAIL_VERIFICATION}
                  component={EmailNotVerifiedView}
                />
                <PublicRoute
                  path={ROUTES.ACCOUNT_NOT_APPROVED}
                  component={AccountNotApprovedView}
                />
                <PublicRoute path={ROUTES.FORGET_PASSWORD} component={ForgetPasswordView} />
                <PublicRoute path={ROUTES.EMAIL_SENT} component={EmailSentView} />
                <PublicRoute
                  path={[ROUTES.RESET_PASSWORD, ROUTES.INIT_PASSWORD]}
                  component={ResetPasswordView}
                />
                <PublicRoute
                  path={[ROUTES.RESET_PASSWORD_SUCCESS, ROUTES.INIT_PASSWORD_SUCCESS]}
                  component={ResetPasswordSuccessView}
                />

                <AuthRoute
                  path={ROUTES.HOME}
                  component={HomeView}
                  activeTab={MENU.HOME}
                  isBackgroundGrey={true}
                  isHideNavigation={true}
                />
                <AuthRoute path={ROUTES.FORM} component={FormView} activeTab={MENU.FORM} />
                <AuthRoute path={ROUTES.IMAGE} component={ImageView} activeTab={MENU.IMAGE} />
                <AuthRoute
                  path={ROUTES.PATIENT_TRACKING}
                  component={PatientTrackingView}
                  activeTab={MENU.PATIENT_TRACKING}
                />
                <AuthRoute path={ROUTES.CHARTS} component={ChartsView} activeTab={MENU.CHARTS} />
                <AuthRoute path={ROUTES.MOBILE} component={MobileView} isHideHeader={true} />
                <PermissionRoute
                  path={ROUTES.ADMIN}
                  permission={{ [PERMISSION_KEYS.CAN_MANAGE_USERS]: true }}
                  component={AdminView}
                  activeTab={MENU.ADMIN}
                />
                <LoginRoute path={ROUTES.LOGIN} component={LoginView} />
                <Route path={ROUTES.ALL}>
                  <Redirect to={ROUTES.HOME} />
                </Route>
              </Switch>
            </LayoutBodyWrapper>
            <LayoutFooterWrapper
              isbackgroundgrey={location.pathname === ROOT_ROUTES.HOME}
              ishidefooter={matchPath(location.pathname, {
                path: ROOT_ROUTES.MOBILE,
                strict: false,
                exact: false
              })}
            >
              <AuthRoute
                path={[
                  ROUTES.HOME,
                  ROUTES.FORM,
                  ROUTES.CONFERENCE,
                  ROUTES.IMAGE,
                  ROUTES.ADMIN,
                  ROUTES.PATIENT_TRACKING
                ]}
                noAuthReq={true}
                component={SessionTimer}
              />
              <AuthRoute
                path={[
                  ROUTES.HOME,
                  ROUTES.FORM,
                  ROUTES.CONFERENCE,
                  ROUTES.IMAGE,
                  ROUTES.ADMIN,
                  ROUTES.PATIENT_TRACKING
                ]}
                noAuthReq={true}
                component={ChatComponent}
              />
              <AuthRoute
                path={[
                  ROUTES.HOME,
                  ROUTES.FORM,
                  ROUTES.CONFERENCE,
                  ROUTES.IMAGE,
                  ROUTES.ADMIN,
                  ROUTES.PATIENT_TRACKING
                ]}
                noAuthReq={true}
                component={ConferenceComponent}
              />
              <AuthRoute
                path={[
                  ROUTES.HOME,
                  ROUTES.FORM,
                  ROUTES.CONFERENCE,
                  ROUTES.IMAGE,
                  ROUTES.ADMIN,
                  ROUTES.PATIENT_TRACKING
                ]}
                noAuthReq={true}
                component={TrackingHistoryPopup}
              />
              {!!currentUser?.[USER_FIELDS.STUDY]?.[STUDY_FIELDS.LIVE_CAMERA_ENABLED] && (
                <AuthRoute
                  path={[
                    ROUTES.HOME,
                    ROUTES.FORM,
                    ROUTES.CONFERENCE,
                    ROUTES.IMAGE,
                    ROUTES.ADMIN,
                    ROUTES.PATIENT_TRACKING
                  ]}
                  noAuthReq={true}
                  component={LiveCameraComponent}
                />
              )}
            </LayoutFooterWrapper>
          </LayoutWrapper>
        </Suspense>
      </HashRouter>
    )
  }
}

export default RootRoutes
export { ROUTES }
