import { ttl } from '@/libs/utils'

const STRINGS = {
  STUDY_ACTIONS: {
    CREATE_STUDY_ADMIN: {
      tooltip: 'Create Study Admin'
    },
    MANAGE_USERS: {
      tooltip: 'Manage Study Users'
    },
    EDIT_STUDY: {
      tooltip: 'Edit Study'
    },
    MANAGE_HOSPITALS: {
      tooltip: 'Manage hospitals'
    },
    MANAGE_ROLE_QSS: {
      tooltip: 'Manage role to Question sets map.'
    },
    MANAGE_NOTIFICATIONS: {
      tooltip: 'Manage Notifications'
    },
    MANAGE_ROSTERS: {
      tooltip: 'Manage Rosters'
    },
    ASSIGN_QUESTION_SET: {
      tooltip: 'Assign Question Set'
    },
    DOWNLOAD_REPORT: {
      tooltip: 'Download Report'
    },
    DOWNLOAD_ANALYTIC_REPORT_PDF: {
      tooltip: 'Download Analytic Report as PDF'
    },
    VIEW_ANALYTIC_REPORT_HTML: {
      tooltip: 'View Analytic Report as HTML'
    },
    VIEW_CHARTS_DASHBOARD: {
      tooltip: 'View Charts Dashboard'
    },
    REMOVE_STUDY: {
      tooltip: 'Remove Study'
    }
  },
  SITE_ACTIONS: {
    CREATE_USER: {
      tooltip: 'Create User'
    },
    MANAGE_USERS: {
      tooltip: 'Manage Site Users'
    },
    EDIT_SITE: {
      tooltip: 'Edit Site Name'
    },
    REMOVE_SITE: {
      tooltip: 'Remove Site'
    }
  },
  ADMIN_STUDY_HINT: 'Click to manage the sites of this study',
  ADMIN_CREATE_STUDY_ADMIN_HEADER_TITLE: ttl`${'name'} - Create Study Admin`,
  ADMIN_CREATE_USERS_HEADER_TITLE: 'Create Users',
  ADMIN_CREATE_USER_HEADER_TITLE: ttl`${'name'} - Create User`,
  ADMIN_NOTIFICATION_TEMPLATES_HEADER_TITLE: ttl`${'name'} - Notification templates`,
  ADMIN_SELECT_QUESTION_SET: ttl`${'name'} - Question Sets`,
  ADMIN_USERS_HEADER_TITLE: ttl`${'name'} - Users`,
  ADMIN_HOSPITALS_HEADER_TITLE: ttl`${'name'} - Hospitals`,
  ADMIN_ROLE_QS_HEADER_TITLE: ttl`${'name'} - Role to QS Mapping`,
  ADMIN_MONGO_CHARTS_HEADER_TITLE: ttl`${'name'} - Charts`,
  ADMIN_ROSTERS_CALENDAR_HEADER_TITLE: ttl`${'name'} - Roster Calendar`,
  CLOSING_CONFERENCE: 'Closing conference...',
  CONFERENCE_CAN_HAVE_ONE_ONLY: 'You can only join one conference at a time.',
  CONFERENCE_INVALID_SELECTED_STUDY: 'Please select a study before creating the conference',
  CONFERENCE_ROOM_CREATED_SUCCESS: 'A new conference has been created successfully!',
  CONFERENCE_ROOM_JOIN_SUCCESS: 'You have joined the conference successfully!',
  CREATE_SITE_PLACEHOLDER_SITE_NAME: 'Site Name',
  CREATE_SITE_PLACEHOLDER_SITE_TYPE: 'Site Type',
  CREATE_SITE_PLACEHOLDER_SITE_PREFIX: 'Site Prefix',
  CREATE_SITE_VALIDATION_SITE_NAME: 'Please input site name',
  CREATE_SITE_VALIDATION_SITE_TYPE: 'Please input site type',
  CREATE_SITE_VALIDATION_SITE_PREFIX: 'Please input site prefix',
  CREATE_STUDY_PLACEHOLDER_STUDY_NAME: 'Study Name',
  CREATE_STUDY_PLACEHOLDER_STUDY_PREFIX: 'Study Prefix',
  CREATE_STUDY_PLACEHOLDER_CHART_URL: 'Chart URL',
  CREATE_STUDY_PLACEHOLDER_DASHBOARD_ID: 'Dashboard ID',
  CREATE_STUDY_PLACEHOLDER_CHART_SECRET: 'Chart Secret',
  CREATE_STUDY_PLACEHOLDER_SMS_ROSTER_MESSAGE: 'SMS Message',
  CREATE_STUDY_VALIDATION_STUDY_NAME: 'Please input study name',
  CREATE_STUDY_VALIDATION_STUDY_PREFIX: 'Please input study prefix',
  CREATE_STUDY_VALIDATION_CHART_URL: 'Please input chart URL',
  CREATE_STUDY_VALIDATION_DASHBOARD_ID: 'Please input dashboard ID',
  CREATE_STUDY_VALIDATION_CHART_SECRET: 'Please input chart secret',
  CREATE_STUDY_VALIDATION_SMS_MESSAGE: 'Please input SMS roster message',
  CREATING_CHAT_CHANNEL: 'Creating channel...',
  CREATING_CONFERENCE: 'Creating conference...',
  CREATING_DIRECT_MESSAGE: 'Creating direct message...',
  CREATING_USERS: 'Creating users...',
  DELETE_USER_CONFIRM: 'Are you sure you want to delete this user?',
  DELETE_WARNING_MESSAGE: 'This action cannot be undone. Continue to delete?',
  DELETING_USER: 'Deleting user...',
  DELETING_HOSPITAL: 'Deleting hospital...',
  DELETING_ROLEQS: 'Deleting role to questionSet map...',
  GETTING_CONTACTS: 'Getting contacts...',
  GETTING_CHANNEL: 'Getting channel...',
  GETTING_MESSAGES: 'Getting messages...',
  GETTING_USERS: 'Getting users...',
  GETTING_HOSPITALS: 'Getting hospitals...',
  GETTING_TRACKS: 'Getting tracks...',
  GETTING_RECORDS: 'Getting records...',
  GETTING_ANALYTIC_REPORT_DATA: 'Getting analytic report data...',
  GETTING_ROLEQSS: 'Getting role to questionsets map...',
  GETTING_MONGO_CHART: 'Getting Mongo chart config...',
  GETTING_ROSTERS: 'Getting rosters...',
  INIT_MAP: 'Init map...',
  GETTING_CHAT_USERS_RESOURCE: 'Getting channel users...',
  GETTING_CONVERSATION_ACCESS_TOKEN: 'Getting access token...',
  INVITING_CHAT_USERS: 'Inviting users...',
  INVITING_PARTICIPANTS: 'Inviting participants',
  JOINING_CONFERENCE: 'Joining conference...',
  EJECT_USER_SUCCESSFUL: 'User is forced to log out successfully',
  EJECT_USER_OFFLINE: 'User is currently logged out',
  HOSPITAL_NAME_VALIDATION: 'Please input the hospital name',
  HOSPITAL_ADDRESS_VALIDATION: 'Please input the hospital address',
  HOSPITAL_CONTACT_TYPE_VALIDATION: 'Please input the hospital contact type',
  HOSPITAL_CONTACT_URI_VALIDATION: 'Please input the hospital contact uri',
  MESSAGE_MEDIA_FORMAT_ERROR: 'Please upload an image file',
  NOTIFICATION_CONFERENCE_INVITATION: ttl`${'hostName'} is inviting you to participate a conference.`,
  NOTIFICATION_CONFERENCE_INVITATION_RESPONSE_ACCEPT: ttl`${'participant'} accepted you invitation to the conference.`,
  NOTIFICATION_CONFERENCE_INVITATION_RESPONSE_REJECT: ttl`${'participant'} rejected you invitation to the conference.`,
  NOTIFICATION_CONFERENCE_INVITATION_NO_RESPONSE: ttl`There is no response from ${'participant'} for your conference invitation.`,
  NOTIFICATION_PERMISSION_DENIED:
    'The notification permission is denied. Please allow notifications for better user experience.',
  NOTIFICATION_RECORD_CREATED: ttl`${'name'}'s record for patient ${'mrn'} is ready.`,
  NOTIFICATION_RECORD_UPDATED: ttl`${'name'}'s record for patient ${'mrn'} is updated.`,
  NOTIFICATION_UNSUPPORTED: 'This browser does not support notifications.',
  NOTIFICATION_USER_ON_CALL_UPDATED_ON_CALL: ttl`${'name'} is On-call now`,
  NOTIFICATION_USER_ON_CALL_UPDATED_OFF_CALL: ttl`${'name'} is Off-call now`,
  NOTIFICATION_USER_ON_DUTY_UPDATED_ON_DUTY: ttl`${'name'} is On-duty now`,
  NOTIFICATION_USER_ON_DUTY_UPDATED_OFF_DUTY: ttl`${'name'} is Off-duty now`,
  NOTIFICATION_CONFERENCE_MISS_CALL: ttl`You missed a conference invitation from ${'name'}`,
  NO_NOTIFICATIONS: 'No notifications found.',
  PATIENT_TRACKS_LOADING_TIP: 'Loading tracks',
  RESTORING_USER: 'Restoring user...',
  ROLE_VALIDATION: 'Please input the role',
  ROSTER_PLACEHOLDER_USERNAME: 'Select a name',
  SITE_DELETE_WARNING_MESSAGE: ttl`This action cannot be undone. Continue to delete site '${'siteName'}'?`,
  STUDY_DELETE_WARNING_MESSAGE: ttl`This action cannot be undone. Continue to delete study '${'studyName'}'?`,
  UNABLE_TO_PLAY_NOTIFICATION_SOUND:
    'There is an incoming conference call, but the ringer has been muted for your privacy.',
  UPDATING_CHAT_CHANNEL: 'Updating channel...',
  UPDATING_USER: 'Updating user...',
  UPDATING_HOSPITAL: 'Updating hospital...',
  UPDATING_ROLEQS: 'Updating role to questionSet map...',
  USERS_CREATED_SUCCESS: 'New users created successfully!',
  USER_CREATED_SUCCESS: 'New user created successfully!',
  USER_LOGIN_DISPLAY_REMEMBER_ME: 'Remember me',
  USER_LOGIN_DISPLAY_SUBMIT: 'Sign In',
  USER_REGISTER_DISPLAY_SUBMIT: 'Register',
  USER_FORGET_PASSWORD_DISPLAY_SUBMIT: 'Confirm',
  USER_LOGIN_ERROR_INCORRECT_KEYPASS: 'Incorrect username or password',
  USER_LOGIN_ERROR_UNKNOWN: 'Unknown error',
  USER_LOGIN_LOADING_TIP: 'Signing in...',
  USER_LOGIN_PLACEHOLDER_PASSWORD: 'Password',
  USER_LOGIN_PLACEHOLDER_USERNAME: 'Username',
  USER_LOGIN_TITLE: 'Sign in',
  USER_LOGIN_VALIDATION_EMPTY_PASSWORD: 'Please input your password',
  USER_LOGIN_VALIDATION_EMPTY_USERNAME: 'Please input your username',
  USER_PROFILE_PASSWORD_VALIDATION:
    'The password must be at least 8 characters and must contain lower case, upper case, number and symbol',
  USER_FORGET_PASSWORD_VALIDATION_EMPTY_EMAIL: 'Please input your email address',
  USER_PROFILE_PLACEHOLDER_FIRST_NAME: 'Firstname',
  USER_PROFILE_PLACEHOLDER_LAST_NAME: 'Lastname',
  USER_PROFILE_PLACEHOLDER_MOBILE_PHONE: '04xxxxxxxx or +61xxxxxxxxx',
  USER_PROFILE_PLACEHOLDER_ROLE: 'Role',
  USER_PROFILE_PLACEHOLDER_STUDY: 'Study',
  USER_PROFILE_EMAIL_VALIDATION: 'Please enter a valid email address',
  USER_PROFILE_MODAL_TITLE: 'My Account',
  USER_PROFILE_NAME_VALIDATION: 'Please input a valid name',
  USER_PROFILE_PHONE_VALIDATION: 'Mobile phone is not an Australian mobile number',
  USER_PROFILE_USERNAME_REQUIRED: 'Please input your username',
  USER_PROFILE_NEW_PASSWORD_REQUIRED: 'Please input new password',
  USER_PROFILE_OLD_PASSWORD_REQUIRED: 'Please input old password',
  USER_PROFILE_PASSWORD_CONFIRM: 'Please confirm the password',
  USER_PROFILE_PASSWORD_CONFIRM_ERROR: 'The two passwords that you entered do not match',
  USER_PROFILE_PLACEHOLDER_EMAIL: 'Please input the email address',
  USER_PROFILE_PLACEHOLDER_FIRSTNAME: 'Please input the first name',
  USER_PROFILE_PLACEHOLDER_LASTNAME: 'Please input the last name',
  USER_PROFILE_PLACEHOLDER_PASSWORD: 'Please input the password',
  USER_PROFILE_ROLE_VALIDATION: 'Please select the role',
  USER_PROFILE_SITE_VALIDATION: 'Please select the site',
  USER_PROFILE_STUDY_VALIDATION: 'Please select the study',
  USER_PROFILE_USERNAME_VALIDATION: 'Please input a valid username',
  USER_STATUS_ON_DUTY_SYNC: 'Your status is also switched to On-duty',
  VERIFICATION_EMAIL_SENT_SUCCESSFULLY: 'The new verification email has been sent',
  INVALID_LOGIN_DETAIL: ttl`You have remaining ${'remaining'} attempts before your account is temporarily locked`,
  REACH_MAX_LOGIN_FAIL_TIME: ttl`Your account has been locked out until ${'resetAt'} due multiple failed login attempts.`,
  TOOLTIPS: {
    // tracking
    EDIT_TRACING_NOTIFICATION: 'Edit tracking notification',
    LIVE_CAMERA_PLAY: 'Play',
    LIVE_CAMERA_PAUSE: 'Pause',
    LIVE_CAMERA_CLOSE: 'Close',
    LIVE_CAMERA_GOTO_HOME: 'Move camera to home position',
    LIVE_CAMERA_SET_HOME: 'Set current location as home',
    REFRESH_IMAGE: 'Refresh image(s)',
    FORCE_USER_LOG_OUT: 'Force User Logout'
  },
  VALIDATION: {
    // tracking
    AT_LEAST_ONE_NOTIFICATION_SETTINGS: 'Please set up at least one notification threshold',
    EMPTY_THRESHOLD_VALUE: 'Please input the value',
    // User
    USER_ROLE_VALIDATION: 'Please select a valid role'
  },
  PLACEHOLDER: {
    // tracking
    NOTIFICATION_THRESHOLDS: 'Please set up the notification threshold',
    FLIGHT_ID: 'Flight id'
  },
  LOADING: {
    DEFAULT: 'Loading...',
    // Chat
    LEAVING_CONVERSATION: 'Leaving...',
    // Camera
    OPERATING: 'Operating...',
    // Tracking
    PRE_CHECKING_FLIGHT: 'Checking if flight exists...',
    CREATING_TRACKING: 'Creating tracking...'
  },
  CONFIRMATION: {
    DELETION: 'Are you sure to delete this?',
    LOCK_ACCOUNT: 'Are you sure you want to lock your account?'
  }
}

export default STRINGS
