import { useDispatch, useSelector } from 'react-redux'
import { REDUCER_STATE } from '@/store/constants'
import React, { useEffect, useRef, useState } from 'react'
import { message, notification as NotificationComponent } from 'antd'
import STRINGS from '@/constants/strings'
import { USER_FIELDS } from '@/constants/api-data'
import conferenceEpics from '@/store/epics/conference'
import { createRoomOptions } from '@/components/floating-conference/utils'
import { CONFERENCE, NOTIFICATION_RESPONSES } from '@/constants'
import _ from 'lodash'
import convoEpics from '@/store/epics/conversation'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { Action } from '@/styles'
import PropTypes from 'prop-types'

const CallBackButton = ({
  buttonActions,
  isDisableActionBtn,
  isEventNotification,
  notificationSender,
  notificationKey
}) => {
  const dispatch = useDispatch()
  const isCreatingConference = useSelector((stateInSelector) =>
    stateInSelector.getIn([
      REDUCER_STATE.CONFERENCE.NAME,
      REDUCER_STATE.CONFERENCE.FIELDS.IS_CREATING_ROOM
    ])
  )
  const conference = useSelector((stateInSelector) =>
    stateInSelector.getIn([REDUCER_STATE.CONFERENCE.NAME, REDUCER_STATE.CONFERENCE.FIELDS.DATA])
  )

  const conferenceError = useSelector((stateInSelector) =>
    stateInSelector.getIn([REDUCER_STATE.CONFERENCE.NAME, REDUCER_STATE.CONFERENCE.FIELDS.ERROR])
  )

  const isChatReady = useSelector((stateInSelector) =>
    stateInSelector.getIn([
      REDUCER_STATE.CONVERSATION.NAME,
      REDUCER_STATE.CONVERSATION.FIELDS.IS_CONVERSATION_READY
    ])
  )
  const isChatCreatingChannel = useSelector((stateInSelector) =>
    stateInSelector.getIn([
      REDUCER_STATE.CONVERSATION.NAME,
      REDUCER_STATE.CONVERSATION.FIELDS.IS_CREATING_CONVERSATION
    ])
  )
  const isChatCreatingDirectMessage = useSelector((stateInSelector) =>
    stateInSelector.getIn([
      REDUCER_STATE.CONVERSATION.NAME,
      REDUCER_STATE.CONVERSATION.FIELDS.IS_CREATING_PRIVATE_MESSAGE
    ])
  )

  const prevIsCreatingConference = useRef(isCreatingConference)
  const [targetUser, setTargetUser] = useState(null)

  /**
   * Invite user after room created
   */
  useEffect(() => {
    if (!isCreatingConference && prevIsCreatingConference.current && targetUser) {
      if (!conferenceError) {
        message.success(STRINGS.CONFERENCE_ROOM_CREATED_SUCCESS)
        if (conference?.accessToken) {
          const studyId = targetUser?.[USER_FIELDS.STUDY]
          const participants = [targetUser]
          dispatch(
            conferenceEpics.inviteParticipants({
              studyId,
              roomName: conference.roomName,
              participants,
              newParticipant: targetUser,
              participantId: targetUser?.[USER_FIELDS.ID]
            })
          )
        }
      } else {
        message.error(conferenceError.message)
      }
    }
    prevIsCreatingConference.current = isCreatingConference
  }, [isCreatingConference, targetUser, conferenceError])

  /**
   * Create a one to one conference
   */
  const handleClickCallBackBtn = () => {
    NotificationComponent.close(notificationKey)
    const studyId = notificationSender?.[USER_FIELDS.STUDY]
    const options = createRoomOptions({ studyId }, CONFERENCE.TYPE.ONE_TO_ONE)
    if (!_.isEmpty(notificationSender)) {
      setTargetUser(notificationSender)
      dispatch(conferenceEpics.createRoom(options))
    }
  }

  /**
   * Handle click chat text button
   */
  const handleClickTextBtn = () => {
    NotificationComponent.close(notificationKey)
    if (!_.isEmpty(notificationSender)) {
      dispatch(convoEpics.setPrivateMessageTargetFromContacts(notificationSender))
    }
  }

  if (isDisableActionBtn && isEventNotification) {
    return (
      <div style={{ color: 'green' }}>
        <CheckCircleTwoTone twoToneColor='#52c41a' /> Already in a conference
      </div>
    )
  } else if (isEventNotification) {
    return buttonActions?.map((action, index) => {
      const { title, icon: Icon, color } = action
      switch (title) {
        case NOTIFICATION_RESPONSES.CONFERENCE_END.CALL_BACK: {
          return (
            <Action
              key={index}
              icon={<Icon size={16} color={color} />}
              onClick={handleClickCallBackBtn}
              disabled={isDisableActionBtn}
            >
              {title}
            </Action>
          )
        }

        case NOTIFICATION_RESPONSES.CONFERENCE_END.MESSAGE: {
          return (
            <Action
              key={index}
              icon={<Icon size={16} color={color} />}
              onClick={handleClickTextBtn}
              disabled={
                isDisableActionBtn ||
                !isChatReady ||
                isChatCreatingChannel ||
                isChatCreatingDirectMessage
              }
            >
              {title}
            </Action>
          )
        }
        default:
          return null
      }
    })
  } else {
    return null
  }
}

CallBackButton.propTypes = {
  buttonActions: PropTypes.array,
  isDisableActionBtn: PropTypes.bool,
  isEventNotification: PropTypes.bool,
  notificationSender: PropTypes.object,
  notificationKey: PropTypes.string
}

export default CallBackButton
