import { USER_ENUMS } from '@/constants/api-data'

const MRN_ANSWER_PATH = ['ACUTE', 'MRN']
const ONSET_DATE_PATH = ['ACUTE', 'ONSET_DATE']

const TRACKING_SIMULATION_ENABLED = false

const ENV = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development'
}

const BOOLEAN = {
  TRUE: 'true',
  FALSE: 'false'
}

const DATE_FORMAT = 'DD/MM/YYYY HH:mm'
const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm:ss'
const DATE_FORMAT_HUMANIZED = 'DD MMM YYYY HH:mm'
const TIME_FORMAT_WITH_INDICATOR = 'HH:mm A'

const USER_TABLE_COLOR = {
  ROLES: {
    ADMIN: '#ffbb96',
    SITE_ADMIN: '#b7eb8f',
    SENIOR_NURSE: '#adc6ff',
    RADIOLOGIST: '#42f5e9',
    SELF: '#db03fc'
  },
  DELETED_COL: {
    TRUE: '#5af542',
    FALSE: 'red'
  }
}

const USER_NAME_REGEX = /^[a-zA-Z0-9._-]{3,}$/
const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const USER_STATE_OPTIONS = [
  {
    label: 'Pending',
    state: USER_ENUMS.STATE.PENDING,
    tip: 'Users who have not verified their email'
  },
  {
    label: 'Needs approval',
    state: USER_ENUMS.STATE.VERIFIED,
    tip: 'Please edit and allocate a site first before enabling user'
  },
  {
    label: 'Provisional',
    state: USER_ENUMS.STATE.PROVISIONAL,
    tip: 'Users who have not verified their email'
  },
  {
    label: 'Enabled',
    state: USER_ENUMS.STATE.ENABLED,
    tip: 'Enabled users who can access the application'
  },
  {
    label: 'Disabled',
    state: USER_ENUMS.STATE.DISABLED,
    tip: 'Disabled users who do not have access to the application'
  }
]

const USER_FIELD_HEADER = {
  CONFIRM_PASSWORD: 'Confirm password',
  CREATED_AT: 'Created at',
  DELETED: 'Disabled',
  EMAIL: 'Email',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  MOBILE_PHONE: 'Mobile phone',
  LAST_LOGIN: 'Last login',
  PASSWORD: 'Password',
  ROLE: 'Role',
  SITE: 'Site',
  STUDY: 'Study',
  USERNAME: 'Username',
  STATE: 'State'
}

// table settings
const USER_TABLE_LIMIT = 50
const DEFAULT_USER_QUERY = { skip: 0, limit: USER_TABLE_LIMIT, sortBy: 'username', orderBy: 1 }
const RECORD_TABLE_LIMIT = 50
const DEFAULT_RECORD_QUERY = {
  skip: 0,
  limit: RECORD_TABLE_LIMIT,
  sortBy: 'createdAt',
  orderBy: -1
}
const IMAGE_TABLE_LIMIT = 50
const DEFAULT_IMAGE_QUERY = {
  skip: 0,
  limit: IMAGE_TABLE_LIMIT,
  sortBy: 'studyDateTime',
  orderBy: -1
}
const PAGINATION_POSITION = ['bottomCenter']

const CONFERENCE = {
  ROOM: {
    STATE_DISCONNECT: 'disconnect',
    STATE_ENDED_BY_HOST: 'ended-by-host'
  },
  TRACK: {
    KIND_VIDEO: 'video',
    KIND_AUDIO: 'audio'
  },
  ROOM_TYPE: {
    GROUP: 'group',
    GO: 'go'
  },
  TYPE: {
    ONE_TO_ONE: 'ONE_TO_ONE',
    GROUP: 'GROUP',
    PHONE_CALL: 'PHONE_CALL'
  },
  MAX_PARTICIPANTS: {
    ONE_TO_ONE: 2,
    GROUP: 50
  },
  PARTICIPANT_INVITATION_STATUS: {
    WAIT_FOR_RESPONSE: 'WAIT_FOR_RESPONSE',
    ACCEPT: 'ACCEPTED',
    REJECT: 'REJECTED',
    NO_RESPONSE: 'NO_RESPONSE'
  },
  PARTICIPANT_INVITATION_STATUS_FIELD_IN_USER: 'invitationStatus'
}

const CHAT = {
  MESSAGE_ELEMENT_ID_PREFIX: 'message-box-',
  MAX_DISPLAYED_NOTIFICATION_NUM: 10,
  CHANNEL_RIGHT_CLICK_MENU_FIELD: {
    CHANNEL_SID: 'channelSid',
    IS_ENABLE_NOTIFICATION: 'isEnabledNotification'
  },
  DEFAULT_VISIBILITY: {
    showCreateChannel: false,
    showEditChannel: false,
    deleteChannelVisible: false,
    showUserSelection: false,
    showMemberList: false
  }
}

const CONFERENCE_INVITATION_RESPONSE = {
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  NO_RESPONSE: 'NO_RESPONSE'
}
const NOTIFICATION_RESPONSES = {
  MANUAL_TRIGGERED: {
    ACKNOWLEDGE: 'Acknowledge',
    VIEW_ACKNOWLEDGEMENT: 'View acknowledgement'
  },
  CONFERENCE_END: {
    CALL_BACK: 'Call back',
    MESSAGE: 'Chat'
  }
}
// The time that conference host waits for participant responsing to the invitation, 60s
const CONFERENCE_INVITATION_RESPONSE_WAITING_TIME = 60000

const TWILIO_CONVO = {
  NOTIFICATION_TYPES: {
    NEW_MESSAGE: 'twilio.conversations.new_message'
  }
}

const USER_STATUS = {
  OFF_DUTY: {
    label: 'Off duty',
    value: 'offDuty',
    color: '#8c8c8c'
  },
  ON_DUTY: {
    label: 'On duty',
    value: 'onDuty',
    color: '#52c41a'
  }
}

const USER_ON_CALL = {
  OFF_CALL: {
    label: 'Off-call',
    value: false,
    color: '#8c8c8c'
  },
  ON_CALL: {
    label: 'On-call',
    value: true,
    color: '#00aaff'
  }
}

const TABLE_SORT_ORDER = {
  ASCEND: 'ascend',
  DESCEND: 'descend'
}

// Split string by using divider
const STRING_DIVIDER = {
  CONFERENCE_PARTICIPANT_IDENTITY: '#'
}

// Wait until to refresh the map to make ambulance move
const MAP_DATA_REFRESH_INTERVAL = 10000

const LIVE_CAMERA_MOVING_MODE = {
  ABSOLUTE: 'absolute',
  CONTINUE: 'continue'
}

const JOYSTICK_DIRECTION = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}

export {
  TRACKING_SIMULATION_ENABLED,
  BOOLEAN,
  CHAT,
  CONFERENCE,
  CONFERENCE_INVITATION_RESPONSE,
  CONFERENCE_INVITATION_RESPONSE_WAITING_TIME,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_FORMAT_HUMANIZED,
  DEFAULT_IMAGE_QUERY,
  DEFAULT_RECORD_QUERY,
  DEFAULT_USER_QUERY,
  EMAIL_REGEX,
  ENV,
  IMAGE_TABLE_LIMIT,
  MRN_ANSWER_PATH,
  ONSET_DATE_PATH,
  PASSWORD_REGEX,
  PAGINATION_POSITION,
  RECORD_TABLE_LIMIT,
  TIME_FORMAT_WITH_INDICATOR,
  TWILIO_CONVO,
  TABLE_SORT_ORDER,
  USER_FIELD_HEADER,
  USER_NAME_REGEX,
  USER_ON_CALL,
  USER_STATUS,
  USER_STATE_OPTIONS,
  USER_TABLE_COLOR,
  USER_TABLE_LIMIT,
  STRING_DIVIDER,
  MAP_DATA_REFRESH_INTERVAL,
  LIVE_CAMERA_MOVING_MODE,
  JOYSTICK_DIRECTION,
  NOTIFICATION_RESPONSES
}
