import { SYSTEM_NOTIFICATION_FIELDS } from '@/constants/api-data/rawNotification'
import { NOTIFICATION_STALE_DURATION, NOTIFICATION_TYPES } from '@/services/constants'
import { USER_ENUMS } from '@/constants/api-data'
import { get } from 'lodash'

/**
 * Format notification data
 * @param {Object} data
 * @returns {*}
 */
const normalizeSystemNotificationData = (data) => {
  const FIELD_KEYS = Object.keys(SYSTEM_NOTIFICATION_FIELDS)
  let normalizedData = Object.assign({}, data)
  for (const KEY of FIELD_KEYS) {
    const { required, name, normalize } = SYSTEM_NOTIFICATION_FIELDS[KEY]
    if (required && !Object.prototype.hasOwnProperty.call(data, name)) {
      // eslint-disable-next-line
      console.error(data, name)
    }
    if (normalize) {
      normalizedData[name] = normalize(normalizedData[name])
    }
  }
  return normalizedData
}

/**
 * Check stale notification
 * @param {*} notification - Normalized notification
 */
const isNotificationStale = ({ createdAt }) => {
  if (!isNaN(createdAt) && (Date.now() - createdAt) / 1000 > NOTIFICATION_STALE_DURATION) {
    return true
  }
  return false
}

/**
 * Check if current notification muted
 * @param {string} type - notification type
 * @param {Object} notifySettings - current notification settings of user
 * @return {*}
 */
const isNotificationMuted = ({ type, notifySettings }) => {
  if (
    [
      NOTIFICATION_TYPES.CONFERENCE_RESPONDED,
      NOTIFICATION_TYPES.TRACK_CREATED_SELF,
      NOTIFICATION_TYPES.TRACK_ARRIVED_SELF,
      NOTIFICATION_TYPES.TRACK_COMPLETED
    ].includes(type)
  ) {
    return true
  } else if (
    [
      NOTIFICATION_TYPES.MANUAL_TRIGGERED,
      NOTIFICATION_TYPES.USER_ON_CALL_STATUS_UPDATED,
      NOTIFICATION_TYPES.USER_ON_DUTY_STATUS_UPDATED
    ].includes(type)
  ) {
    return !get(notifySettings, [USER_ENUMS.NOTIFICATION_SETTINGS.HOME], true)
  } else if ([NOTIFICATION_TYPES.IMAGE_CREATED].includes(type)) {
    return !get(notifySettings, [USER_ENUMS.NOTIFICATION_SETTINGS.IMAGE], true)
  } else if ([NOTIFICATION_TYPES.RECORD_CREATED].includes(type)) {
    return !get(notifySettings, USER_ENUMS.NOTIFICATION_SETTINGS.FORM_CREATED, true)
  } else if ([NOTIFICATION_TYPES.RECORD_UPDATED].includes(type)) {
    return !get(notifySettings, USER_ENUMS.NOTIFICATION_SETTINGS.FORM_UPDATED, true)
  } else if (
    [
      NOTIFICATION_TYPES.CONFERENCE_MISS_CALL,
      NOTIFICATION_TYPES.CONFERENCE_INVITATION_RESPONSE,
      NOTIFICATION_TYPES.CONFERENCE_INVITATION,
      NOTIFICATION_TYPES.CONFERENCE_ROOM_END
    ].includes(type)
  ) {
    return !get(notifySettings, USER_ENUMS.NOTIFICATION_SETTINGS.CONFERENCE, true)
  } else if (
    [
      NOTIFICATION_TYPES.TRACK_BEFORE_ARRIVAL,
      NOTIFICATION_TYPES.TRACK_CREATED,
      NOTIFICATION_TYPES.TRACK_ARRIVED
    ].includes(type)
  ) {
    return !get(notifySettings, USER_ENUMS.NOTIFICATION_SETTINGS.TRACKING, true)
  }
}

export { isNotificationStale, normalizeSystemNotificationData, isNotificationMuted }
