import { SHARED_FIELDS } from './shared'

const NOTIFICATION_TEMPLATE_FIELDS = {
  ...SHARED_FIELDS,
  LABEL: 'label',
  MESSAGE: 'message',
  ORDER: 'order'
}

export { NOTIFICATION_TEMPLATE_FIELDS }
