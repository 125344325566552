const DEFAULT_STATE = {
  DATA: 'data',
  IS_PENDING: 'isPending',
  ERROR: 'error'
}

const REDUCER_STATE = {
  ROUTER: {
    NAME: 'router',
    FIELDS: {
      LOCATION: 'location'
    }
  },
  AUDIO: {
    NAME: 'audio',
    FIELDS: {
      URL: 'url',
      IS_PLAYING: 'isPlaying',
      LOOP: 'loop'
    }
  },
  AUTH: {
    NAME: 'auth',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_AUTHORIZED: 'isAuthorized',
      IS_LOGGING_IN: 'isLoggingIn',
      IS_LOGGING_OUT: 'isLoggingOut',
      IS_DELETING: 'isDeleting',
      IS_VERIFYING: 'isVerifying',
      IS_UPDATING: 'isUpdating',
      IS_REGISTERING: 'isRegistering',
      IS_SENDING_RESET_PASSWORD_EMAIL: 'isSendingResetPasswordEmail',
      IS_RESETTING_PASSWORD: 'isResettingPassword',
      IS_VERIFYING_EMAIL: 'isVerifyingEmail',
      IS_RESENDING_VERIFY_EMAIL: 'isResendingVerifyEmail',
      IS_EJECTING_USER: 'isEjectingUser',
      IS_EJECTED_SUCCESSFULLY: 'isEjectedSuccessfully',
      USER: 'user'
    }
  },
  GENERAL_INFO: {
    NAME: 'generalInfo',
    FIELDS: {
      DATA: DEFAULT_STATE.DATA,
      ERROR: DEFAULT_STATE.ERROR,
      IS_PENDING: DEFAULT_STATE.IS_PENDING,
      IS_GETTING_API_VERSION: 'isGettingApiVersion',
      IS_GETTING_MAPBOX_TOKEN: 'isGettingMapboxToken',
      API_VERSION: 'apiVersion',
      REGISTRATION_INFO: 'registrationInfo',
      MAPBOX_TOKEN: 'mapboxToken'
    }
  },
  ADMIN: {
    NAME: 'admin',
    FIELDS: {
      STUDIES: {
        NAME: 'studies',
        FIELDS: {
          DATA: DEFAULT_STATE.DATA,
          ERROR: DEFAULT_STATE.ERROR,
          IS_CREATING: 'isCreating',
          IS_DELETING: 'isDeleting',
          IS_PENDING: DEFAULT_STATE.IS_PENDING,
          IS_UPDATING: 'isUpdating'
        }
      },
      SITES: {
        NAME: 'sites',
        FIELDS: {
          CURRENT_STUDY: 'currentStudy',
          DATA: DEFAULT_STATE.DATA,
          ERROR: DEFAULT_STATE.ERROR,
          IS_CREATING: 'isCreating',
          IS_DELETING: 'isDeleting',
          IS_GETTING_STUDY: 'isGettingStudy',
          IS_PENDING: DEFAULT_STATE.IS_PENDING,
          IS_UPDATING: 'isUpdating'
        }
      },
      USERS: {
        NAME: 'users',
        FIELDS: {
          DATA: DEFAULT_STATE.DATA,
          ERROR: DEFAULT_STATE.ERROR,
          IS_CREATING_USER: 'isCreatingUser',
          IS_CREATING_USERS: 'isCreatingUsers',
          IS_DELETING_USER: 'isDeletingUser',
          IS_GETTING_USERS: 'isGettingUsers',
          IS_RESTORING_USER: 'isRestoringUser',
          IS_PENDING: DEFAULT_STATE.IS_PENDING,
          IS_UPDATING_USER: 'isUpdatingUser'
        }
      },
      QUESTIONSETS: {
        NAME: 'questionSets',
        FIELDS: {
          DATA: DEFAULT_STATE.DATA,
          ERROR: DEFAULT_STATE.ERROR,
          OPTIONS: 'options',
          IS_PENDING: DEFAULT_STATE.IS_PENDING,
          IS_UPDATING: 'isUpdating'
        }
      },
      MONGOCHART: {
        NAME: 'mongoChart',
        FIELDS: {
          DATA: DEFAULT_STATE.DATA,
          ERROR: DEFAULT_STATE.ERROR,
          IS_GETTING_MONGO_CHART: 'isGettingMongoChart',
          IS_PENDING: DEFAULT_STATE.IS_PENDING
        }
      }
    }
  },
  NOTIFICATION: {
    NAME: 'notification',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      FCM_TOKEN: 'fcmToken',
      TWILIO_CHAT_NOTIFICATION_PAYLOAD: 'twilioChatNotificationPayload',
      IS_SHOW_ACKNOWLEDGE_STATUS_MODAL: 'isShowAcknowledgeStatusModal',
      IS_REGISTERING_NOTIFICATION: 'isRegisteringNotification',
      IS_BINDING_CREATED: 'isBindingCreated',
      IS_CREATING_NOTIFICATION_TEMPLATES: 'isCreatingNotificationTemplates',
      IS_DELETING_NOTIFICATION_TEMPLATES: 'isDeletingNotificationTemplates',
      IS_GETTING_NOTIFICATIONS: 'isGettingNotifications',
      IS_GETTING_NOTIFICATION_TEMPLATES: 'isGettingNotificationTemplates',
      IS_NOTIFICATION_SUPPORTED: 'isSupported',
      IS_PERMISSION_GRANTED: 'isPermissionGranted',
      IS_SETTING_NOTIFICATIONS: 'isSettingNotifications',
      IS_UPDATING_NOTIFICATION_TEMPLATES: 'isUpdatingNotificationTemplates',
      IS_SENDING_NOTIFICATION: 'isSendingNotification',
      IS_ACKNOWLEDGING_SENDER: 'isAcknowledgingSender',
      IS_GETTING_NOTIFICATION: 'isGettingNotification',
      NOTIFICATIONS: 'notifications',
      NOTIFICATION: 'notification',
      NOTIFICATION_TOTAL_PAGE: 'notificationTotalPage',
      NOTIFICATION_TEMPLATES: 'notificationTemplates',
      NEW_NOTIFICATION_IDS: 'newNotificationIds',
      INCOMING_NOTIFICATION: 'incomingNotification'
    }
  },
  RECORD: {
    NAME: 'record',
    FIELDS: {
      CURRENT_RECORD: 'currentRecord',
      CURRENT_STUDY: 'currentStudy',
      CURRENT_SITE: 'currentSite',
      ERROR: DEFAULT_STATE.ERROR,
      IS_CREATING: 'isCreating',
      IS_FETCHING: 'isFetching',
      IS_FETCHING_LAST_CREATED: 'isFetchingLastCreated',
      IS_LOCKING: 'isLocking',
      IS_UNLOCKING: 'isUnlocking',
      IS_UPDATING: 'isUpdating',
      IS_DELETING: 'isDeleting',
      QUESTION_SETS: 'questionSets',
      ROLE_QUESTION_SETS: 'roleQuestionSets',
      OPTIONS: 'options',
      RECORDS: 'records',
      LAST_CREATED_AT: 'lastCreatedAtRecord'
    }
  },
  CONTACTS: {
    NAME: 'contacts',
    FIELDS: {
      DATA: DEFAULT_STATE.DATA,
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_CONTACTS: 'isGettingContacts'
    }
  },
  CONFERENCE: {
    NAME: 'conference',
    FIELDS: {
      DATA: DEFAULT_STATE.DATA,
      ERROR: DEFAULT_STATE.ERROR,
      INVITED_USERS: 'invitedUsers',
      IS_CREATING_ROOM: 'isCreatingRoom',
      IS_ENDING: 'isEnding',
      IS_GETTING_STUDIES: 'isGettingStudies',
      IS_INVITING_PARTICIPANTS: 'isInvitingParticipants',
      IS_JOINING_ROOM: 'isJoiningRoom',
      IS_RESPONSE: 'isResponse',
      ONE_TO_ONE_TARGET_FROM_CONTACTS: 'oneToOneTargetFromTarget',
      STUDIES: 'studies',
      USERS: 'users',
      TWILIO_CONFERENCE_ROOM: 'twilioConferenceRoom'
    }
  },
  PATIENT: {
    NAME: 'patient',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_DICOM_STUDIES: 'isGettingDicomStudies',
      IS_GETTING_PATIENT_STUDY: 'isGettingPatientStudy',
      IS_GETTING_PATIENT_STUDY_SERIES: 'isGettingPatientSeries',
      PATIENT_STUDY: 'patientStudy',
      PATIENT_STUDY_SERIES: 'patientSeries',
      STUDIES: 'studies'
    }
  },
  CONVERSATION: {
    NAME: 'conversation',
    FIELDS: {
      DATA: DEFAULT_STATE.DATA,
      PRIVATE_MESSAGE_TARGET_FROM_CONTACTS: 'privateMessageTargetFromTarget',
      ERROR: DEFAULT_STATE.ERROR,
      IS_CREATING_CONVERSATION: 'isCreatingConversation',
      IS_CREATING_PRIVATE_MESSAGE: 'isCreatingDirectMessage',
      IS_LEAVING_CONVERSATION: 'isLeaving',
      IS_DELETING_CONVERSATION: 'isDeletingConversation',
      IS_GETTING_CONVERSATION: 'isGettingConversation',
      IS_GETTING_CONVERSATION_USERS: 'isGettingConversationUsers',
      IS_GETTING_PARTICIPANT_RESOURCE: 'isGettingMemberResource',
      IS_GETTING_ZEUS_SITE: 'isGettingZeusSite',
      IS_GETTING_ZEUS_STUDY: 'isGettingZeusStudy',
      IS_GETTING_ZEUS_USER: 'isGettingZeusUser',
      IS_GETTING_ACCESS_TOKEN: 'isGettingAccessToken',
      IS_INVITING_USERS: 'isInvitingUsers',
      IS_SENDING_MESSAGE: 'isSendingMessage',
      IS_UPDATING_CONVERSATION: 'isUpdatingConversation',
      IS_UPDATING_CONVERSATION_USER: 'isUpdatingConversationUser',
      IS_UPDATING_PARTICIPANT_RESOURCE: 'isUpdatingMemberResource',
      IS_CONVERSATION_READY: 'isConversationReady',
      SELF: 'self',
      SITE_LIST: 'site',
      STUDY_LIST: 'study',
      USER_LIST: 'user'
    }
  },
  VOICE: {
    NAME: 'voice',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      ACCESS_TOKEN: 'accessToken',
      IS_GETTING_ACCESS_TOKEN: 'isGettingAccessToken',
      USER: 'user'
    }
  },
  REPORT: {
    NAME: 'report',
    FIELDS: {
      DATA: DEFAULT_STATE.DATA,
      ERROR: DEFAULT_STATE.ERROR,
      ANALYTIC_REPORT_DATA: 'analyticReportData',
      IS_GETTING_STUDY_EXCEL_DATA: 'isGettingStudyExcelData',
      IS_GETTING_ANALYTIC_REPORT_PDF: 'isGettingAnalyticReportPdf',
      IS_GETTING_ANALYTIC_REPORT_HTML: 'isGettingAnalyticReportHtml',
      IS_GETTING_PDF: 'isGettingPdf'
    }
  },
  HOSPITAL: {
    NAME: 'hospital',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_HOSPITALS: 'isGettingHospitals',
      IS_GETTING_HOSPITAL: 'isGettingHospital',
      IS_CREATING_HOSPITAL: 'isCreatingHospital',
      IS_DELETING_HOSPITAL: 'isDeletingHospital',
      IS_UPDATING_HOSPITAL: 'isUpdatingHospital',
      HOSPITALS: 'hospitals',
      HOSPITAL: 'hospital'
    }
  },
  TRACK: {
    NAME: 'track',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_TRACK: 'isGettingTrack',
      IS_GETTING_TRACK_FROM_NOTIFY: 'isGettingTrackFromNotify',
      IS_UPDATING_TRACK: 'isUpdatingTrack',
      TRACKS: 'tracks',
      COMPLETED_TRACKS: 'completedTracks',
      TRACK: 'track',
      TRACK_FROM_NOTIFICATION: 'trackFromNotification',
      CLICKED_STUDY_ID_AND_TRACK_ID: 'clickedStudyIdAndTrackId'
    }
  },
  LIVE_CAMERA: {
    NAME: 'live-camera',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_STUDIES: 'isGettingStudies',
      IS_OPERATING_CAMERA: 'isOperatingCamera',
      IS_GETTING_CAMERA: 'isGettingCamera',
      STUDIES: 'studies',
      CURRENT_STATUS: 'currentStatus'
    }
  },
  ROLE_QS: {
    NAME: 'roleQuestionSet',
    FIELDS: {
      ERROR: DEFAULT_STATE.ERROR,
      IS_GETTING_ROLE_QSS: 'isGettingRoleQuestionSets',
      IS_GETTING_ROLE_QS: 'isGettingRoleQuestionSet',
      IS_CREATING_ROLE_QS: 'isCreatingRoleQuestionSet',
      IS_DELETING_ROLE_QS: 'isDeletingRoleQuestionSet',
      IS_GETTING_ALLOCATED_ROLE: 'isGettingAllocatedRole',
      IS_UPDATING_ROLE_QS: 'isUpdatingRoleQuestionSet',
      ROLE_QSS: 'roleQuestionSets',
      ROLE_QS: 'roleQuestionSet'
    }
  },
  ROSTER: {
    NAME: 'roster.js',
    FIELDS: {
      DATA: DEFAULT_STATE.DATA,
      ERROR: DEFAULT_STATE.ERROR,
      IS_CREATING: 'isCreatingRoster',
      IS_DELETING: 'isDeletingRoster',
      IS_FETCHING_ROSTERS: 'isFetchingRosters',
      IS_FETCHING_ROSTER: 'isFetchingRoster',
      IS_UPDATING: 'isUpdatingRoster',
      ROSTERS: 'rosters',
      ROSTER: 'roster.js'
    }
  }
}

export { DEFAULT_STATE, REDUCER_STATE }
