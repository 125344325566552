import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

/**
 * Get camera status
 * @param {string} studyId
 * @param {string} rtsp - rtsp url
 * @returns {Promise<*>}
 */
const getCameraStatus = async ({ studyId, rtsp }) => {
  try {
    const params = {}
    if (rtsp) params.rtspUrl = rtsp
    const res = await API.get(ENDPOINTS.LIVE_CAMERA({ studyId }), {
      params
    })
    return extractResponse(res)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Operate camera
 * @param {string} studyId
 * @param {Object} movingOptions
 * @param {string} movingMode - relative, absolute
 * @param {string} rtsp - rtsp url
 * @returns {Promise<*>}
 */
const operateCamera = async ({ studyId, movingOptions, movingMode, rtsp }) => {
  try {
    const res = await API.post(ENDPOINTS.LIVE_CAMERA_OPERATION({ studyId }), {
      movingOptions,
      movingMode,
      rtspUrl: rtsp
    })
    return extractResponse(res)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Go home position
 * @param studyId
 * @param rtsp
 * @returns {Promise<*>}
 */
const gotoHomePosition = async ({ studyId, rtsp }) => {
  try {
    const params = {}
    if (rtsp) params.rtspUrl = rtsp

    const res = await API.get(ENDPOINTS.LIVE_CAMERA_HOME({ studyId }), {
      params
    })

    return extractResponse(res)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Set home position
 * @param studyId
 * @param rtsp
 * @returns {Promise<*>}
 */
const setHomePosition = async ({ studyId, rtsp }) => {
  try {
    const res = await API.post(ENDPOINTS.LIVE_CAMERA_HOME({ studyId }), {
      rtspUrl: rtsp
    })
    return extractResponse(res)
  } catch (err) {
    errorHandler(err)
  }
}

export { getCameraStatus, operateCamera, gotoHomePosition, setHomePosition }
