const ROOT_ROUTES = {
  ADMIN: '/admin',
  ALL: '*',
  CONFERENCE: '/conference',
  FORM: '/form',
  HOME: '/home',
  IMAGE: '/image',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  SEND_RESET_PASSWORD_LINK: '/send-reset-password-link',
  EMAIL_SENT: '/email-sent',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_SUCCESS: '/reset-password-success',
  PATIENT_TRACKING: '/patient-tracking',
  CHARTS: '/charts',
  INIT_PASSWORD: '/init-password',
  INIT_PASSWORD_SUCCESS: '/init-password-success',
  REGISTER: '/register',
  REGISTER_SUCCESS: '/register-success',
  EMAIL_VERIFY: '/verify-email',
  RE_SEND_EMAIL_VERIFICATION: '/resend-email-verification',
  ACCOUNT_NOT_APPROVED: '/account-not-approved',
  MOBILE: '/mobile'
}

const IMAGE_ROUTES = {
  IMAGE_LIST: '/image',
  IMAGE: '/image/:studyId/:studyInstanceUID'
}

const FORM_ROUTES = {
  LIST: '/form',
  NEW_RECORD: '/form/new-record',
  // TODO: Old constant. (Single form).
  NEW_RECORD_WITH_STUDY_SITE: '/form/:studyId/:siteId/new-record',
  NEW_RECORD_WITH_STUDY_SITE_QUESTION_SET: '/form/:studyId/:siteId/:questionSetId/new-record',
  EXIST_RECORD_WITH_STUDY_SITE: '/form/:studyId/:siteId/:id'
}

const PATIENT_TRACKING_ROUTES = {
  PATIENT_TRACKING_LIST: '/patient-tracking'
}

const CHARTS_ROUTES = {
  CHARTS: '/charts'
}

const CONFERENCE_ROUTES = {
  CONFERENCE_HOMEPAGE: '/conference',
  CONFERENCE_ROOM: '/conference/studies/:studyId/room/:conferenceType'
}

const ADMIN_ROUTES = {
  ADMIN: '/admin',
  STUDIES: '/admin/studies',
  SITES_IN_STUDY: '/admin/studies/:studyId/sites'
}

const MOBILE_ROUTES = {
  MRN_STUDY_DATE: '/mobile/:studyId/mrn/:mrn/:studyDate',
  STUDY_INSTANCE_UID: '/mobile/:studyId/studyInstanceUID/:studyInstanceUID'
}

export {
  ROOT_ROUTES,
  IMAGE_ROUTES,
  FORM_ROUTES,
  CONFERENCE_ROUTES,
  ADMIN_ROUTES,
  MOBILE_ROUTES,
  PATIENT_TRACKING_ROUTES,
  CHARTS_ROUTES
}
