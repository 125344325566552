import localforage from 'localforage'

const VERSION = 2
const STORE_NAME = 'zeus-application'

const LOCAL_STORAGE_KEYS = {
  // NOTIFICATIONS: 'notifications',
  COOKIE_EXPIRY: 'cookieExpiry',
  CURRENT_USER: 'currentUser',
  GENERAL_INFO: 'generalInfo',
  NOTIFICATION_SETTINGS: 'ZEUS_NOTIFICATION_SETTINGS'
}

const store = localforage.createInstance({
  name: STORE_NAME,
  version: VERSION
})

export default store
export { LOCAL_STORAGE_KEYS }
