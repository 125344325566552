import React from 'react'
import PropTypes from 'prop-types'
import {
  Map,
  MessageCircle,
  Notes,
  PhoneIncoming,
  PhoneOff,
  PhoneOutgoing,
  PictureInPicture
} from 'tabler-icons-react'
import moment from 'moment'

import SimpleAvatar from '@/components/avatar/simple'
import style from './style/index.module.less'
import { NOTIFICATION_TYPES } from '@/services/constants'
import { NOTIFICATION_RESPONSES, TIME_FORMAT_WITH_INDICATOR } from '@/constants'

const eventConf = {
  [NOTIFICATION_TYPES.RECORD_CREATED]: {
    actions: [
      {
        title: 'View Record',
        icon: Notes
      }
    ]
  },
  [NOTIFICATION_TYPES.RECORD_UPDATED]: {
    actions: [
      {
        title: 'View Record',
        icon: Notes
      }
    ]
  },
  [NOTIFICATION_TYPES.IMAGE_CREATED]: {
    actions: [
      {
        title: 'View Image',
        icon: PictureInPicture
      }
    ]
  },
  [NOTIFICATION_TYPES.CONFERENCE_INVITATION]: {
    actions: [
      {
        title: 'Accept',
        icon: PhoneIncoming,
        color: 'green'
      },
      {
        title: 'Reject',
        icon: PhoneOff,
        color: 'red'
      }
    ]
  },
  [NOTIFICATION_TYPES.CONFERENCE_ROOM_END]: {
    actions: [
      {
        title: NOTIFICATION_RESPONSES.CONFERENCE_END.CALL_BACK,
        icon: PhoneOutgoing,
        color: 'green'
      },
      {
        title: NOTIFICATION_RESPONSES.CONFERENCE_END.MESSAGE,
        icon: MessageCircle,
        color: 'orange'
      }
    ]
  },
  [NOTIFICATION_TYPES.TRACK_CREATED]: {
    actions: [
      {
        title: 'View Track',
        icon: Map,
        color: 'orange'
      }
    ]
  },
  [NOTIFICATION_TYPES.TRACK_BEFORE_ARRIVAL]: {
    actions: [
      {
        title: 'View Track',
        icon: Map,
        color: 'orange'
      }
    ]
  },
  [NOTIFICATION_TYPES.MANUAL_TRIGGERED]: {
    actions: [
      {
        title: NOTIFICATION_RESPONSES.MANUAL_TRIGGERED.ACKNOWLEDGE,
        icon: Notes
      },
      {
        title: NOTIFICATION_RESPONSES.MANUAL_TRIGGERED.VIEW_ACKNOWLEDGEMENT,
        icon: Notes
      }
    ]
  }
  // [TYPES.MISSED_VOICE_CALL]: {
  //   actions: [
  //     {
  //       title: 'Check Voice Mail',
  //       icon: RecordMail
  //     },
  //     {
  //       title: 'Callback',
  //       icon: PhoneOutgoing
  //     }
  //   ]
  // },
  // [TYPES.TEXT_MESSAGE]: {
  //   actions: [
  //     {
  //       title: 'View Message',
  //       icon: MessageCircle
  //     }
  //   ]
  // },
  // [TYPES.COMPLETE_ASSESSMENT]: {
  //   actions: [
  //     {
  //       title: 'View Assessment',
  //       icon: Notes
  //     }
  //   ]
  // },
  // [TYPES.COMPLE_SCAN]: {
  //   actions: [
  //     {
  //       title: 'View Scan',
  //       icon: Maximize
  //     }
  //   ]
  // }
}

const Event = ({
  isDisableActionBtn,
  event,
  isHideActionBtn,
  isShowViewAcknowledgeButton,
  isShowAcknowledgeButton
}) => {
  const { notification, btn: ActionBtn, description } = event
  const { createdAt, sender } = notification
  let user
  if (notification.type === NOTIFICATION_TYPES.IMAGE_CREATED) {
    user = {
      avatar: '/public/avatars/dicom.png',
      name: 'DICOM'
    }
  } else if (!sender) {
    user = {
      avatar: '',
      name: 'SYSTEM'
    }
  } else {
    user = {
      avatar: sender.avatar,
      name: `${sender.firstName} ${sender.lastName}`
    }
  }
  moment.relativeTimeThreshold('m', 60)
  const when = moment.duration(createdAt - Date.now()).humanize(true)
  const exactWhen = moment(createdAt).format(TIME_FORMAT_WITH_INDICATOR)

  return (
    <div className={style.wrapper}>
      <div className={style.avatar}>
        <SimpleAvatar user={user}></SimpleAvatar>
      </div>
      <div className={style.detail}>
        <div>
          <span className='when'>{when} </span>
          <span className='when'>
            ({user?.name} , {exactWhen})
          </span>
        </div>
        <div className={style.description}>{description}</div>
        {!isHideActionBtn && ActionBtn && (
          <div>
            <ActionBtn
              isDisableActionBtn={isDisableActionBtn}
              clickedFromHistory={true}
              isShowViewAcknowledgeButton={isShowViewAcknowledgeButton}
              isShowAcknowledgeButton={isShowAcknowledgeButton}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Event.propTypes = {
  event: PropTypes.object,
  isDisableActionBtn: PropTypes.bool,
  isHideActionBtn: PropTypes.bool.isRequired,
  isShowViewAcknowledgeButton: PropTypes.bool,
  isShowAcknowledgeButton: PropTypes.bool
}

export default Event

export { eventConf }
