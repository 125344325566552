import { REDUCER_STATE } from '@/store/constants'
import Immutable from 'immutable'
import { PAUSE_AUDIO, PLAY_AUDIO } from '@/store/types/audio'

const FIELDS = REDUCER_STATE.AUDIO.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.URL]: null,
  [FIELDS.IS_PLAYING]: false,
  [FIELDS.LOOP]: false
})

const handlers = {
  [PLAY_AUDIO]: (state, action) =>
    state.merge({
      [FIELDS.URL]: action.payload?.url,
      [FIELDS.LOOP]: action.payload?.loop,
      [FIELDS.IS_PLAYING]: action.payload?.autoplay || true
    }),
  [PAUSE_AUDIO]: (state) =>
    state.merge({
      [FIELDS.IS_PLAYING]: false,
      [FIELDS.URL]: null,
      [FIELDS.LOOP]: false
    })
}

export default {
  initialState,
  handlers
}
