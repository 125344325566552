/**
 * Roster actions
 */
export const CREATE_ROSTER = 'CREATE_ROSTER'
export const CREATE_ROSTER_FULFILLED = 'CREATE_ROSTER_FULFILLED'
export const CREATE_ROSTER_REJECTED = 'CREATE_ROSTER_REJECTED'

export const GET_ROSTER = 'GET_ROSTER'
export const GET_ROSTER_FULFILLED = 'GET_ROSTER_FULFILLED'
export const GET_ROSTER_REJECTED = 'GET_ROSTER_REJECTED'

export const GET_ROSTERS = 'GET_ROSTERS'
export const GET_ROSTERS_FULFILLED = 'GET_ROSTERS_FULFILLED'
export const GET_ROSTERS_REJECTED = 'GET_ROSTERS_REJECTED'

export const UPDATE_ROSTER = 'UPDATE_ROSTER'
export const UPDATE_ROSTER_FULFILLED = 'UPDATE_ROSTER_FULFILLED'
export const UPDATE_ROSTER_REJECTED = 'UPDATE_ROSTER_REJECTED'

export const DELETE_ROSTER = 'DELETE_ROSTER'
export const DELETE_ROSTER_FULFILLED = 'DELETE_ROSTER_FULFILLED'
export const DELETE_ROSTER_REJECTED = 'DELETE_ROSTER_REJECTED'
