import { BOOLEAN } from '@/constants'

const SYSTEM_NOTIFICATION_FIELDS = {
  // message body
  BODY: {
    required: true,
    name: 'body'
  },
  CREATED_AT: {
    required: false,
    name: 'createdAt',
    normalize: (data) => {
      return Number(data)
    }
  },
  DATA: {
    required: false,
    name: 'data',
    normalize: (data) => {
      if (!data) {
        return {}
      }
      return JSON.parse(data)
    }
  },
  DURATION: {
    required: true,
    name: 'duration',
    normalize: (data) => {
      return Number(data)
    }
  },
  ID: {
    required: false,
    name: '_id'
  },
  PERSISTED: {
    required: false,
    name: 'persisted',
    normalize: (data) => {
      if (!data) {
        return false
      }
      return data.toLowerCase() === BOOLEAN.TRUE.toLowerCase()
    }
  },
  SENDER: {
    required: true,
    name: 'sender',
    normalize: (data) => {
      if (!data) {
        return 'SYSTEM'
      }
      return JSON.parse(data)
    }
  },
  REQUIRE_ACKNOWLEDGE: {
    required: false,
    name: 'requireAcknowledge',
    normalize: (data) => {
      if (!data) {
        return false
      }
      return data.toLowerCase() === BOOLEAN.TRUE.toLowerCase()
    }
  },
  NOTIFICATION_RECEIVERS: {
    required: false,
    name: 'notificationReceivers',
    normalize: (data) => {
      if (!data) {
        return []
      }
      return JSON.parse(data)
    }
  },
  TAG: {
    required: false,
    name: 'tag',
    normalize: (data) => {
      if (!data) {
        return []
      }
      return JSON.parse(data)
    }
  },
  // message title
  TITLE: {
    required: true,
    name: 'title'
  },
  TWI_MESSAGE_ID: {
    required: true,
    name: 'twi_message_id'
  },
  TYPE: {
    required: true,
    name: 'type'
  },
  UPDATED_AT: {
    required: false,
    name: 'updatedAt'
  }
}

const CHAT_NOTIFICATION_FIELDS = {
  AUTHOR: 'author',
  CHANNEL_ID: 'channel_id',
  CHANNEL_SID: 'channel_sid',
  CHANNEL_TITLE: 'channel_title',
  MESSAGE_ID: 'message_id',
  MESSAGE_INDEX: 'message_index',
  MESSAGE_SID: 'message_sid',
  TWI_BODY: 'twi_body',
  TWI_MESSAGE_ID: 'twi_message_id',
  TWI_MESSAGE_TYPE: 'twi_message_type',
  TWI_SOUND: 'twi_sound'
}

export { SYSTEM_NOTIFICATION_FIELDS, CHAT_NOTIFICATION_FIELDS }
