import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import authEpics from '@/store/epics/auth'
import { REDUCER_STATE } from '@/store/constants'
import { ROOT_ROUTES } from '@/routes/route-path'
import { PublicLayoutWrapper } from '@/styles/layout'

const LoginRoute = (props) => {
  const { component: Component, ...otherProps } = props
  const dispatch = useDispatch()
  const isAuthorized = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.IS_AUTHORIZED])
  )
  useEffect(() => {
    /**
     * Request to check if current user is authorized
     */
    if (!isAuthorized) {
      dispatch(authEpics.auth())
    }
  }, [isAuthorized])
  /**
   * Redirect to home page if current user is authorized
   */
  return (
    <PublicLayoutWrapper>
      <Route
        {...otherProps}
        render={(props) =>
          !isAuthorized ? <Component {...props} /> : <Redirect to={ROOT_ROUTES.HOME} />
        }
      />
    </PublicLayoutWrapper>
  )
}

LoginRoute.propTypes = {
  component: PropTypes.object.isRequired
}

export default LoginRoute
