const PERMISSION_KEYS = {
  CAN_CREATE_FORM: 'CAN_CREATE_FORM',
  CAN_CREATE_STUDY_ADMIN: 'CAN_CREATE_STUDY_ADMIN',
  CAN_DELETE_STUDY: 'CAN_DELETE_STUDY',
  CAN_EDIT_NOTIFICATION_TEMPLATE: 'CAN_EDIT_NOTIFICATION_TEMPLATE',
  CAN_GET_SITES: 'CAN_GET_SITES',
  CAN_GET_USERS: 'CAN_GET_USERS',
  CAN_MANAGE_SITES_USERS: 'CAN_MANAGE_SITES_USERS',
  CAN_MANAGE_STUDIES_USERS: 'CAN_MANAGE_STUDIES_USERS',
  CAN_MANAGE_USERS: 'CAN_MANAGE_USERS',
  CAN_TRACK_PATIENT: 'CAN_TRACK_PATIENT',
  CAN_UPDATE_STUDY: 'CAN_UPDATE_STUDY',
  CAN_VIEW_FORM: 'CAN_VIEW_FORM',
  CAN_VIEW_FORM_FOR_ALL_SITES: 'CAN_VIEW_FORM_FOR_ALL_SITES',
  CAN_VIEW_FORM_FOR_ALL_STUDIES: 'CAN_VIEW_FORM_FOR_ALL_STUDIES',
  CAN_VIEW_MONGO_CHART_DASHBOARD: 'CAN_VIEW_MONGO_CHART_DASHBOARD',
  CAN_SEND_MANUAL_TRIGGERED_NOTIFICATIONS: 'CAN_SEND_MANUAL_TRIGGERED_NOTIFICATIONS',
  CAN_VIEW_TRACK_PREVIEW_ON_HOME_PAGE: 'CAN_VIEW_TRACK_PREVIEW_ON_HOME_PAGE',
  CAN_SELECT_STUDY_QUESTIONSET: 'CAN_SELECT_STUDY_QUESTIONSET',
  CAN_VIEW_IMAGE_FOR_ALL_STUDIES: 'CAN_VIEW_IMAGE_FOR_ALL_STUDIES',
  CAN_DELETE_RECORD: 'CAN_DELETE_RECORD',
  CAN_MANAGE_STUDY_HOSPITALS: 'CAN_MANAGE_STUDY_HOSPITALS',
  CAN_MANAGE_USERS_ROSTERS: 'CAN_MANAGE_USERS_ROSTERS',
  // tracking
  CAN_EDIT_TRACKING_NOTIFICATION: 'CAN_EDIT_TRACKING_NOTIFICATION',
  SELF_SITE_IS_EDITABLE: 'SELF_SITE_IS_EDITABLE',
  // SORTING
  CAN_SORT_NOTIFICATION_TEMPLATES: 'CAN_SORT_NOTIFICATION_TEMPLATES',
  // Camera
  CAN_SET_CAMERA_HOME_POSITION: 'CAN_SET_CAMERA_HOME_POSITION'
}

const AUTHORIZATIONS = {
  SUPER_ADMIN: {
    [PERMISSION_KEYS.CAN_CREATE_FORM]: false,
    [PERMISSION_KEYS.CAN_CREATE_STUDY_ADMIN]: true,
    [PERMISSION_KEYS.CAN_DELETE_STUDY]: true,
    [PERMISSION_KEYS.CAN_EDIT_NOTIFICATION_TEMPLATE]: true,
    [PERMISSION_KEYS.CAN_GET_SITES]: true,
    [PERMISSION_KEYS.CAN_GET_USERS]: true,
    [PERMISSION_KEYS.CAN_MANAGE_SITES_USERS]: true,
    [PERMISSION_KEYS.CAN_MANAGE_STUDIES_USERS]: true,
    [PERMISSION_KEYS.CAN_MANAGE_USERS_ROSTERS]: true,
    [PERMISSION_KEYS.CAN_TRACK_PATIENT]: true,
    [PERMISSION_KEYS.CAN_MANAGE_USERS]: true,
    [PERMISSION_KEYS.CAN_UPDATE_STUDY]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_SITES]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_STUDIES]: true,
    [PERMISSION_KEYS.CAN_VIEW_MONGO_CHART_DASHBOARD]: true,
    [PERMISSION_KEYS.CAN_SEND_MANUAL_TRIGGERED_NOTIFICATIONS]: false,
    [PERMISSION_KEYS.CAN_SELECT_STUDY_QUESTIONSET]: true,
    [PERMISSION_KEYS.CAN_VIEW_IMAGE_FOR_ALL_STUDIES]: true,
    [PERMISSION_KEYS.CAN_DELETE_RECORD]: true,
    [PERMISSION_KEYS.CAN_MANAGE_STUDY_HOSPITALS]: false,
    [PERMISSION_KEYS.SELF_SITE_IS_EDITABLE]: false,
    [PERMISSION_KEYS.CAN_EDIT_TRACKING_NOTIFICATION]: true,
    [PERMISSION_KEYS.CAN_VIEW_TRACK_PREVIEW_ON_HOME_PAGE]: false,
    [PERMISSION_KEYS.CAN_SORT_NOTIFICATION_TEMPLATES]: true,
    [PERMISSION_KEYS.CAN_SET_CAMERA_HOME_POSITION]: false
  },
  STUDY_ADMIN: {
    [PERMISSION_KEYS.CAN_CREATE_FORM]: false,
    [PERMISSION_KEYS.CAN_CREATE_STUDY_ADMIN]: false,
    [PERMISSION_KEYS.CAN_DELETE_STUDY]: false,
    [PERMISSION_KEYS.CAN_EDIT_NOTIFICATION_TEMPLATE]: true,
    [PERMISSION_KEYS.CAN_GET_SITES]: true,
    [PERMISSION_KEYS.CAN_GET_USERS]: true,
    [PERMISSION_KEYS.CAN_MANAGE_SITES_USERS]: true,
    [PERMISSION_KEYS.CAN_MANAGE_STUDIES_USERS]: false,
    [PERMISSION_KEYS.CAN_MANAGE_USERS_ROSTERS]: true,
    [PERMISSION_KEYS.CAN_TRACK_PATIENT]: true,
    [PERMISSION_KEYS.CAN_MANAGE_USERS]: true,
    [PERMISSION_KEYS.CAN_UPDATE_STUDY]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_SITES]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_STUDIES]: false,
    [PERMISSION_KEYS.CAN_VIEW_MONGO_CHART_DASHBOARD]: true,
    [PERMISSION_KEYS.CAN_SEND_MANUAL_TRIGGERED_NOTIFICATIONS]: true,
    [PERMISSION_KEYS.CAN_SELECT_STUDY_QUESTIONSET]: true,
    [PERMISSION_KEYS.CAN_VIEW_IMAGE_FOR_ALL_STUDIES]: false,
    [PERMISSION_KEYS.CAN_DELETE_RECORD]: true,
    [PERMISSION_KEYS.CAN_MANAGE_STUDY_HOSPITALS]: true,
    [PERMISSION_KEYS.SELF_SITE_IS_EDITABLE]: false,
    [PERMISSION_KEYS.CAN_EDIT_TRACKING_NOTIFICATION]: true,
    [PERMISSION_KEYS.CAN_VIEW_TRACK_PREVIEW_ON_HOME_PAGE]: true,
    [PERMISSION_KEYS.CAN_SORT_NOTIFICATION_TEMPLATES]: true,
    [PERMISSION_KEYS.CAN_SET_CAMERA_HOME_POSITION]: true
  },
  NORMAL_USER: {
    [PERMISSION_KEYS.CAN_CREATE_FORM]: true,
    [PERMISSION_KEYS.CAN_CREATE_STUDY_ADMIN]: false,
    [PERMISSION_KEYS.CAN_DELETE_STUDY]: false,
    [PERMISSION_KEYS.CAN_EDIT_NOTIFICATION_TEMPLATE]: false,
    [PERMISSION_KEYS.CAN_GET_SITES]: true,
    [PERMISSION_KEYS.CAN_GET_USERS]: true,
    [PERMISSION_KEYS.CAN_MANAGE_SITES_USERS]: false,
    [PERMISSION_KEYS.CAN_MANAGE_STUDIES_USERS]: false,
    [PERMISSION_KEYS.CAN_MANAGE_USERS_ROSTERS]: false,
    [PERMISSION_KEYS.CAN_TRACK_PATIENT]: true,
    [PERMISSION_KEYS.CAN_MANAGE_USERS]: false,
    [PERMISSION_KEYS.CAN_UPDATE_STUDY]: false,
    [PERMISSION_KEYS.CAN_VIEW_FORM]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_SITES]: false,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_STUDIES]: false,
    [PERMISSION_KEYS.CAN_VIEW_MONGO_CHART_DASHBOARD]: false,
    [PERMISSION_KEYS.CAN_SEND_MANUAL_TRIGGERED_NOTIFICATIONS]: true,
    [PERMISSION_KEYS.CAN_SELECT_STUDY_QUESTIONSET]: false,
    [PERMISSION_KEYS.CAN_VIEW_IMAGE_FOR_ALL_STUDIES]: false,
    [PERMISSION_KEYS.CAN_DELETE_RECORD]: false,
    [PERMISSION_KEYS.CAN_MANAGE_STUDY_HOSPITALS]: false,
    [PERMISSION_KEYS.SELF_SITE_IS_EDITABLE]: true,
    [PERMISSION_KEYS.CAN_EDIT_TRACKING_NOTIFICATION]: false,
    [PERMISSION_KEYS.CAN_VIEW_TRACK_PREVIEW_ON_HOME_PAGE]: true,
    [PERMISSION_KEYS.CAN_SORT_NOTIFICATION_TEMPLATES]: false,
    [PERMISSION_KEYS.CAN_SET_CAMERA_HOME_POSITION]: false
  },
  HUB_USER: {
    [PERMISSION_KEYS.CAN_CREATE_FORM]: true,
    [PERMISSION_KEYS.CAN_CREATE_STUDY_ADMIN]: false,
    [PERMISSION_KEYS.CAN_DELETE_STUDY]: false,
    [PERMISSION_KEYS.CAN_EDIT_NOTIFICATION_TEMPLATE]: false,
    [PERMISSION_KEYS.CAN_GET_SITES]: true,
    [PERMISSION_KEYS.CAN_GET_USERS]: true,
    [PERMISSION_KEYS.CAN_MANAGE_SITES_USERS]: false,
    [PERMISSION_KEYS.CAN_MANAGE_STUDIES_USERS]: false,
    [PERMISSION_KEYS.CAN_MANAGE_USERS_ROSTERS]: false,
    [PERMISSION_KEYS.CAN_TRACK_PATIENT]: true,
    [PERMISSION_KEYS.CAN_MANAGE_USERS]: false,
    [PERMISSION_KEYS.CAN_UPDATE_STUDY]: false,
    [PERMISSION_KEYS.CAN_VIEW_FORM]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_SITES]: true,
    [PERMISSION_KEYS.CAN_VIEW_FORM_FOR_ALL_STUDIES]: false,
    [PERMISSION_KEYS.CAN_VIEW_MONGO_CHART_DASHBOARD]: false,
    [PERMISSION_KEYS.CAN_SEND_MANUAL_TRIGGERED_NOTIFICATIONS]: true,
    [PERMISSION_KEYS.CAN_SELECT_STUDY_QUESTIONSET]: false,
    [PERMISSION_KEYS.CAN_VIEW_IMAGE_FOR_ALL_STUDIES]: false,
    [PERMISSION_KEYS.CAN_DELETE_RECORD]: false,
    [PERMISSION_KEYS.CAN_MANAGE_STUDY_HOSPITALS]: false,
    [PERMISSION_KEYS.SELF_SITE_IS_EDITABLE]: true,
    [PERMISSION_KEYS.CAN_EDIT_TRACKING_NOTIFICATION]: false,
    [PERMISSION_KEYS.CAN_VIEW_TRACK_PREVIEW_ON_HOME_PAGE]: true,
    [PERMISSION_KEYS.CAN_SORT_NOTIFICATION_TEMPLATES]: false,
    [PERMISSION_KEYS.CAN_SET_CAMERA_HOME_POSITION]: false
  }
}
// FIXME: The site type for 'hub' users shall be provided by api instead of hard coded in ui
const HUB_SITE_TYPE = 'HUB'

export { AUTHORIZATIONS, HUB_SITE_TYPE, PERMISSION_KEYS }
