import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'
import {
  CLEAR_TRACKS,
  GET_AND_UPDATE_TRACK_FROM_NOTIFY,
  GET_AND_UPDATE_TRACK_FROM_NOTIFY_FULFILLED,
  GET_AND_UPDATE_TRACK_FROM_NOTIFY_REJECTED,
  GET_COMPLETED_TRACKS,
  GET_COMPLETED_TRACKS_FULFILLED,
  GET_TRACK,
  GET_TRACK_FROM_NOTIFICATION,
  GET_TRACK_FROM_NOTIFICATION_FULFILLED,
  GET_TRACK_FROM_NOTIFICATION_REJECTED,
  GET_TRACK_FULFILLED,
  GET_TRACK_REJECTED,
  GET_TRACKS,
  GET_TRACKS_FULFILLED,
  GET_TRACKS_REJECTED,
  LINK_TRACK,
  LINK_TRACK_FULFILLED,
  LINK_TRACK_REJECTED,
  SET_CLICKED_STUDY_ID_AND_TRACK_ID,
  UPDATE_TRACK,
  UPDATE_TRACK_FULFILLED,
  UPDATE_TRACK_REJECTED
} from '@/store/types'
import { getTrack, getTracks, linkTrackWithRecord, updateTrack } from '@/services/api'

const trackEpics = {
  getTracks: (query) => ({
    type: GET_TRACKS,
    payload: query
  }),

  getTracksFulfilled: (payload) => ({
    type: GET_TRACKS_FULFILLED,
    payload
  }),

  getTracksError: (error) => ({
    type: GET_TRACKS_REJECTED,
    payload: error
  }),

  getCompletedTracks: (payload) => ({
    type: GET_COMPLETED_TRACKS,
    payload
  }),
  getCompletedTracksFulfilled: (payload) => ({
    type: GET_COMPLETED_TRACKS_FULFILLED,
    payload
  }),

  getTracksEpic: (action$) =>
    action$.pipe(
      ofType(GET_COMPLETED_TRACKS, GET_TRACKS),
      mergeMap((action) => {
        switch (action.type) {
          case GET_COMPLETED_TRACKS: {
            return of(action.payload).pipe(
              mergeMap((payload) =>
                from(getTracks(payload)).pipe(
                  map((response) => trackEpics.getCompletedTracksFulfilled(response)),
                  catchError((error) => of(trackEpics.getTracksError(error)))
                )
              )
            )
          }
          case GET_TRACKS: {
            return of(action.payload).pipe(
              mergeMap((payload) =>
                from(getTracks(payload)).pipe(
                  map((response) => trackEpics.getTracksFulfilled(response)),
                  catchError((error) => of(trackEpics.getTracksError(error)))
                )
              )
            )
          }
        }
      })
    ),

  clearTracks: () => ({
    type: CLEAR_TRACKS,
    payload: []
  }),

  getTrack: ({ studyId, id }) => ({
    type: GET_TRACK,
    payload: { studyId, id }
  }),

  getTrackFulfilled: (payload) => ({
    type: GET_TRACK_FULFILLED,
    payload
  }),

  getTrackError: (error) => ({
    type: GET_TRACK_REJECTED,
    payload: error
  }),

  getTrackEpic: (action$) =>
    action$.pipe(
      ofType(GET_TRACK),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(getTrack(action.payload)).pipe(
          map((response) => trackEpics.getTrackFulfilled(response)),
          catchError((error) => of(trackEpics.getTrackError(error)))
        )
      )
    ),
  // Get and update list when new notify coming
  getAndUpdateTrackFromNotify: ({ studyId, id, notificationType }) => ({
    type: GET_AND_UPDATE_TRACK_FROM_NOTIFY,
    payload: { studyId, id, notificationType }
  }),
  getAndUpdateTrackFromNotifyFulfilled: (payload) => ({
    type: GET_AND_UPDATE_TRACK_FROM_NOTIFY_FULFILLED,
    payload
  }),
  getAndUpdateTrackFromNotifyError: (error) => ({
    type: GET_AND_UPDATE_TRACK_FROM_NOTIFY_REJECTED,
    payload: error
  }),
  getAndUpdateTrackFromNotifyEpic: (action$) =>
    action$.pipe(
      ofType(GET_AND_UPDATE_TRACK_FROM_NOTIFY),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(getTrack({ studyId: action.payload?.studyId, id: action.payload?.id })).pipe(
          map((response) =>
            trackEpics.getAndUpdateTrackFromNotifyFulfilled({
              ...response,
              notificationType: action.payload?.notificationType
            })
          ),
          catchError((error) => of(trackEpics.getAndUpdateTrackFromNotifyError(error)))
        )
      )
    ),

  // Update one track status
  updateTrack: ({ studyId, trackId, payload }) => ({
    type: UPDATE_TRACK,
    payload: { studyId, trackId, payload }
  }),
  updateTrackFulfilled: (payload) => ({
    type: UPDATE_TRACK_FULFILLED,
    payload: payload
  }),
  updateTrackError: (error) => ({
    type: UPDATE_TRACK_REJECTED,
    payload: error
  }),
  updateTrackEpic: (action$) =>
    action$.pipe(
      ofType(UPDATE_TRACK),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(updateTrack(action.payload)).pipe(
          map((response) => trackEpics.updateTrackFulfilled(response)),
          catchError((err) => of(trackEpics.updateTrackError(err)))
        )
      )
    ),

  // Link track with existing record
  linkTrack: ({ studyId, trackId, formId, imageId }) => ({
    type: LINK_TRACK,
    payload: { studyId, trackId, formId, imageId }
  }),
  linkTrackFulfilled: (payload) => ({
    type: LINK_TRACK_FULFILLED,
    payload: payload
  }),
  linkTrackError: (error) => ({
    type: LINK_TRACK_REJECTED,
    payload: error
  }),
  linkTrackEpic: (action$) =>
    action$.pipe(
      ofType(LINK_TRACK),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(linkTrackWithRecord(action.payload)).pipe(
          map((response) => trackEpics.linkTrackFulfilled(response)),
          catchError((err) => of(trackEpics.linkTrackError(err)))
        )
      )
    ),

  // Get track data when click notification
  getTrackFromNotification: ({ studyId, id }) => ({
    type: GET_TRACK_FROM_NOTIFICATION,
    payload: { studyId, id }
  }),

  getTrackFromNotificationFulfilled: (payload) => ({
    type: GET_TRACK_FROM_NOTIFICATION_FULFILLED,
    payload
  }),

  getTrackFromNotificationError: (error) => ({
    type: GET_TRACK_FROM_NOTIFICATION_REJECTED,
    payload: error
  }),

  getTrackFromNotificationEpic: (action$) =>
    action$.pipe(
      ofType(GET_TRACK_FROM_NOTIFICATION),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(getTrack(action.payload)).pipe(
          map((response) => trackEpics.getTrackFromNotificationFulfilled(response)),
          catchError((error) => of(trackEpics.getTrackFromNotificationError(error)))
        )
      )
    ),
  setClickedStudyIdAndTrackId: (payload) => ({
    type: SET_CLICKED_STUDY_ID_AND_TRACK_ID,
    payload
  })
}

export default trackEpics
