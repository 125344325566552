import PropTypes from 'prop-types'
import React from 'react'
import { ConnectedRouter } from 'connected-react-router/immutable'
import { hot } from 'react-hot-loader/root'

import RootRoutes from '@/routes/root'
import '@/styles/index.less'

const App = (props) => {
  const { history } = props

  return (
    <ConnectedRouter history={history}>
      <RootRoutes />
    </ConnectedRouter>
  )
}

App.propTypes = {
  history: PropTypes.object.isRequired
}

export default hot(App)
