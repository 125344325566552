import API from './client'
import ENDPOINTS from './endpoints'
import { BINDING_TYPE } from '../constants'
import { errorHandler, extractResponse } from './utils'

/**
 * Register notification
 * @param {String} address     - device token
 * @param {String} tag         - Tag for selecting bindings during sending notifications
 * @returns {Promise<*>}
 */
const registerBindingResource = async ({ address, tag }) => {
  const body = { address, bindingType: BINDING_TYPE, tag }
  try {
    await API.post(ENDPOINTS.NOTIFICATION_REGISTER, body)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get notifications
 * @param {object} options
 * @param {string} [options.skip]    - pagination skip
 * @param {string} [options.limit]   - pagination limit
 * @returns {Promise<object>}
 */
const getNotifications = async ({ skip, limit }) => {
  try {
    const params = {
      skip,
      limit
    }
    return extractResponse(await API.get(ENDPOINTS.NOTIFICATIONS, { params }))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get one notification
 * @param notificationId
 * @return {Promise<*>}
 */
const getNotification = async ({ notificationId }) => {
  try {
    return extractResponse(await API.get(ENDPOINTS.NOTIFICATION({ id: notificationId })))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Send manual triggered notification
 * @param {string} templateId - notification template id
 * @returns {Promise}
 */
const sendNotification = async ({ templateId }) => {
  try {
    const body = { id: templateId }
    await API.post(ENDPOINTS.NOTIFICATIONS, body)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get notification templates in study
 * @param {string} studyId      - study id
 * @returns {Promise<object>}
 */
const getNotificationTemplates = async (studyId) => {
  try {
    return extractResponse(await API.get(ENDPOINTS.NOTIFICATION_TEMPLATES({ studyId })))
      .notificationTemplates
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Create a notification template in study
 * @param {object} options
 * @param {string} options.studyId  - study id
 * @param {string} options.label  - notification template label
 * @param {string} options.message  - notification template message
 * @param {string} options.acknowledgement  - notification template acknowledgement
 * @returns {Promise<*>}
 */
const createNotificationTemplate = async ({ studyId, label, message, acknowledgement }) => {
  const body = { message, label, acknowledgement }
  try {
    return extractResponse(await API.post(ENDPOINTS.NOTIFICATION_TEMPLATES({ studyId }), body))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update a notification templates in study
 * @param {object} options
 * @param {string} options.studyId     - study id
 * @param {string} options.templateId  - notification template id
 * @param {string} options.label  - notification template label
 * @param {string} options.message  - notification template message
 * @returns {Promise<object>}
 */
const updateNotificationTemplate = async ({
  studyId,
  templateId,
  message,
  label,
  acknowledgement
}) => {
  const body = { label, message, acknowledgement }
  try {
    return extractResponse(
      await API.put(ENDPOINTS.NOTIFICATION_TEMPLATE({ studyId, templateId }), body)
    )
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update notification templates order in study
 * @param {object} options
 * @param {string} studyId     - study id
 * @param {Array} templateIds  - notification template id with correct order
 * @returns {Promise<object>}
 */
const updateNotificationTemplateOrder = async ({ studyId, templateIds }) => {
  const body = { templateIds }
  try {
    return extractResponse(
      await API.put(ENDPOINTS.NOTIFICATION_TEMPLATES_SORT_ORDER({ studyId }), body)
    )
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Acknowledge sender
 * @param {String} notificationId
 * @return {Promise<*>}
 */
const acknowledgeSender = async ({ notificationId }) => {
  try {
    return extractResponse(
      await API.post(ENDPOINTS.NOTIFICATION_ACKNOWLEDGEMENT, { id: notificationId })
    )
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Delete a notification templates in study
 * @param {object} options
 * @param {string} options.studyId     - study id
 * @param {string} options.templateId  - notification template id
 * @returns {Promise<string>}
 */
const deleteNotificationTemplate = async ({ studyId, templateId }) => {
  try {
    await API.delete(ENDPOINTS.NOTIFICATION_TEMPLATE({ studyId, templateId }))
    return templateId
  } catch (err) {
    errorHandler(err)
  }
}

export {
  createNotificationTemplate,
  deleteNotificationTemplate,
  getNotificationTemplates,
  getNotifications,
  registerBindingResource,
  sendNotification,
  updateNotificationTemplate,
  updateNotificationTemplateOrder,
  acknowledgeSender,
  getNotification
}
