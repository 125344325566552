import styled from 'styled-components'

const Wrapper = styled.div`
  margin: 10px 20px;
  height: 5%;
  .breadcrumb-item {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
      vertical-align: middle;
    }
    .label {
      font-size: 14px;
      vertical-align: middle;
    }
  }
`

export { Wrapper }
