export const GET_GENERAL_INFO = 'GET_GENERAL_INFO'
export const GET_GENERAL_INFO_FULFILLED = 'GET_GENERAL_INFO_FULFILLED'
export const GET_GENERAL_INFO_REJECTED = 'GET_GENERAL_INFO_REJECTED'

export const GET_API_VERSION = 'GET_API_VERSION'
export const GET_API_VERSION_FULFILLED = 'GET_API_VERSION_FULFILLED'
export const GET_API_VERSION_REJECTED = 'GET_API_VERSION_REJECTED'

export const GET_REGISTER_INFO = 'GET_REGISTER_INFO'
export const GET_REGISTER_INFO_FULFILLED = 'GET_REGISTER_INFO_FULFILLED'
export const GET_REGISTER_INFO_REJECTED = 'GET_REGISTER_INFO_REJECTED'

export const GET_MAPBOX_TOKEN = 'GET_MAPBOX_TOKEN'
export const GET_MAPBOX_TOKEN_FULFILLED = 'GET_MAPBOX_TOKEN_FULFILLED'
export const GET_MAPBOX_TOKEN_REJECTED = 'GET_MAPBOX_TOKEN_REJECTED'
