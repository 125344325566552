import { findIndex } from 'lodash'
import Immutable from 'immutable'

import { REDUCER_STATE } from '@/store/constants'
import {
  CREATE_ROSTER,
  CREATE_ROSTER_FULFILLED,
  CREATE_ROSTER_REJECTED,
  UPDATE_ROSTER,
  UPDATE_ROSTER_FULFILLED,
  UPDATE_ROSTER_REJECTED,
  DELETE_ROSTER,
  DELETE_ROSTER_FULFILLED,
  DELETE_ROSTER_REJECTED,
  GET_ROSTERS,
  GET_ROSTERS_FULFILLED,
  GET_ROSTERS_REJECTED,
  GET_ROSTER,
  GET_ROSTER_FULFILLED,
  GET_ROSTER_REJECTED
} from '@/store/types'
import { ROSTER_FIELDS } from '@/constants/api-data'

const FIELDS = REDUCER_STATE.ROSTER.FIELDS
const initialState = new Immutable.Map({
  [FIELDS.ERROR]: null,
  [FIELDS.IS_CREATING]: false,
  [FIELDS.IS_DELETING]: false,
  [FIELDS.IS_FETCHING_ROSTER]: false,
  [FIELDS.IS_FETCHING_ROSTERS]: false,
  [FIELDS.IS_UPDATING]: false,
  [FIELDS.ROSTERS]: { content: [], total: 0 },
  [FIELDS.ROSTER]: null
})

const handlers = {
  [GET_ROSTERS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_FETCHING_ROSTERS]: true,
      [FIELDS.ROSTERS]: { content: [], total: 0 }
    }),
  [GET_ROSTERS_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.ROSTERS]: action.payload,
      [FIELDS.IS_FETCHING_ROSTERS]: false
    }),
  [GET_ROSTERS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_FETCHING_ROSTERS]: false
    }),

  [GET_ROSTER]: (state) =>
    state.merge({
      [FIELDS.IS_FETCHING_ROSTER]: true,
      [FIELDS.ERROR]: null,
      [FIELDS.ROSTER]: null
    }),
  [GET_ROSTER_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.ROSTER]: action.payload,
      [FIELDS.IS_FETCHING_ROSTER]: false
    }),
  [GET_ROSTER_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_FETCHING_ROSTER]: false
    }),

  [CREATE_ROSTER]: (state) =>
    state.merge({
      [FIELDS.IS_CREATING]: true,
      [FIELDS.ERROR]: null
    }),
  [CREATE_ROSTER_FULFILLED]: (state, action) => {
    const data = state.getIn([FIELDS.ROSTERS])
    // const rosters = data?.content
    const totalNum = data?.total + 1
    return state.merge({
      [FIELDS.IS_CREATING]: false,
      [FIELDS.ROSTERS]: {
        total: totalNum,
        content: [action.payload]
      }
    })
  },
  [CREATE_ROSTER_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_CREATING]: false
    }),
  [DELETE_ROSTER]: (state) =>
    state.merge({
      [FIELDS.IS_DELETING]: true,
      [FIELDS.ERROR]: null,
      [FIELDS.ROSTER]: null
    }),
  [DELETE_ROSTER_FULFILLED]: (state, action) => {
    const { id } = action.payload
    const data = state.getIn([FIELDS.ROLE_QSS])
    const rosters = data?.content
    const newTotalNum = data?.total - 1
    const index = findIndex(rosters || [], { [ROSTER_FIELDS.ID]: id })
    let newContent
    if (index !== -1) {
      newContent = [...rosters.slice(0, index), ...rosters.slice(index + 1)]
    }
    return state.merge({
      [FIELDS.IS_DELETING]: false,
      [FIELDS.ROSTERS]: { content: newContent, total: newTotalNum }
    })
  },
  [DELETE_ROSTER_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_DELETING]: false
    }),

  [UPDATE_ROSTER]: (state) =>
    state.merge({
      [FIELDS.IS_UPDATING]: true,
      [FIELDS.ERROR]: null,
      [FIELDS.ROSTER]: null
    }),
  [UPDATE_ROSTER_FULFILLED]: (state, action) => {
    const { _id } = action.payload
    const data = state.getIn([FIELDS.ROSTERS])
    const rosters = data?.content || []
    const index = findIndex(rosters, { [ROSTER_FIELDS.ID]: _id })
    let newContent
    if (index !== -1) {
      newContent = [
        ...rosters.slice(0, index),
        action.payload,
        ...rosters.slice(index + 1, rosters.length)
      ]
    }
    return state.merge({
      [FIELDS.IS_UPDATING]: false,
      [FIELDS.ROSTERS]: { content: newContent || rosters, total: data?.total }
    })
  },
  [UPDATE_ROSTER_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_UPDATING]: false
    })
}

export default {
  initialState,
  handlers
}
