import { BehaviorSubject, of } from 'rxjs'
import { combineReducers } from 'redux-immutable'
import { connectRouter } from 'connected-react-router/immutable'
import { switchMap } from 'rxjs/operators'

import auth from '@/store/reducers/auth'
import roleQS from '@/store/reducers/roleQS'
import general from '@/store/reducers/general'
import notification from '@/store/reducers/notification'
import conferenceReducer from '@/store/reducers/conference'
import conversationReducer from '@/store/reducers/conversation'
import reportReducer from '@/store/reducers/report'
import trackReducer from '@/store/reducers/track'
import contactReducer from '@/store/reducers/contacts'
import liveCameraReducer from '@/store/reducers/live-camera'
import voiceReducer from '@/store/reducers/voice'
import audioReducer from '@/store/reducers/audio'
import roster from '@/store/reducers/roster'
import { REDUCER_STATE } from '@/store/constants'

export const createReducer = (initialState, handlers) => {
  return function reducer(state = initialState, action) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

let reducerRegistry = {}

const reducer$ = new BehaviorSubject()

export const reducerRegistry$ = reducer$.pipe(
  switchMap((reducer) => {
    reducerRegistry = { ...reducerRegistry, ...reducer }
    return of(combineReducers(reducerRegistry))
  })
)

const createRootReducer = (history) => {
  const initialReducer = {
    [REDUCER_STATE.AUTH.NAME]: createReducer(auth.initialState, auth.handlers),
    [REDUCER_STATE.GENERAL_INFO.NAME]: createReducer(general.initialState, general.handlers),
    [REDUCER_STATE.ROUTER.NAME]: connectRouter(history),
    [REDUCER_STATE.NOTIFICATION.NAME]: createReducer(
      notification.initialState,
      notification.handlers
    ),
    [REDUCER_STATE.CONFERENCE.NAME]: createReducer(
      conferenceReducer.initialState,
      conferenceReducer.handlers
    ),
    [REDUCER_STATE.CONVERSATION.NAME]: createReducer(
      conversationReducer.initialState,
      conversationReducer.handlers
    ),
    [REDUCER_STATE.CONTACTS.NAME]: createReducer(
      contactReducer.initialState,
      contactReducer.handlers
    ),
    [REDUCER_STATE.REPORT.NAME]: createReducer(reportReducer.initialState, reportReducer.handlers),
    [REDUCER_STATE.TRACK.NAME]: createReducer(trackReducer.initialState, trackReducer.handlers),
    [REDUCER_STATE.ROLE_QS.NAME]: createReducer(roleQS.initialState, roleQS.handlers),
    [REDUCER_STATE.ROSTER.NAME]: createReducer(roster.initialState, roster.handlers),
    [REDUCER_STATE.LIVE_CAMERA.NAME]: createReducer(
      liveCameraReducer.initialState,
      liveCameraReducer.handlers
    ),
    [REDUCER_STATE.VOICE.NAME]: createReducer(voiceReducer.initialState, voiceReducer.handlers),
    [REDUCER_STATE.AUDIO.NAME]: createReducer(audioReducer.initialState, audioReducer.handlers)
  }
  reducerRegistry$.next(initialReducer)
  return combineReducers(initialReducer)
}

export default createRootReducer
