import styled, { keyframes } from 'styled-components'
import { Button, TimePicker } from 'antd'
import { BellOutlined } from '@ant-design/icons'

const leftPanelWidth = '130px'
const rightPanelWidth = 'calc(50% - 60px)'
const boxLetMargin = '20px'

export const AntdTimePicker = styled(TimePicker)`
  border: 1px solid #ccc;
  border-radius: 4px;
`

export const Lower = styled.div`
  background-color: white;
  box-shadow: 3px 0 6px rgba(100, 100, 100, 0.2);
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 66px;
  bottom: 0;
  min-height: 400px;
  overflow: hidden;
  padding-top: 10px;

  &.grey {
    background-color: #f0f0f0;
  }
`

export const Middle = styled.div`
  position: absolute;
  top: 0;
  left: calc(${leftPanelWidth} + ${boxLetMargin} + ${boxLetMargin});
  right: calc(${rightPanelWidth} + ${boxLetMargin} + ${boxLetMargin});
  box-sizing: border-box;
`

export const Action = styled(Button)`
  border-radius: 4px;
  padding: 3px 10px 4px 10px;
  margin: 4px 8px 4px 0;
  background: white;
  border-color: var(--widget-color);
  color: var(--widget-color);

  svg {
    margin-top: 3px;
    vertical-align: top;
    margin-right: 8px;
  }
`

export const FloatButtonWrapper = styled.div`
  //position: absolute;
  z-index: var(--level-one-z-index);
`

/**
 * Float button like conference and chat
 */
export const FloatingBtn = styled(Button)`
  display: flex;
  background-color: var(--c-zeus-blue-dark);
  align-items: center;
  height: 44px;
  font-size: 16px;
  border-radius: 10px;

  &:hover {
    background-color: #3b80bf;
  }

  &:focus,
  &:active {
    background-color: var(--c-zeus-blue-dark) !important;
  }

  svg {
    width: 30px;
    height: 30px;
    color: white;
  }

  .text {
    color: white;
    font-weight: bold;
    align-self: center;
    margin-left: 7px;
  }
`

/**
 * Bell shaaking
 */
const bellAnimation = keyframes`
  0% {transform: rotateZ(-45deg)}
  25% {transform: rotateZ(0)}
  50% {transform: rotateZ(45deg)}
  75% {transform: rotateZ(0)}
  100% {transform: rotateZ(-45deg)}
`

export const BellShakingIcon = styled(BellOutlined)`
  animation: ${bellAnimation};
  animation-iteration-count: infinite;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  svg {
    width: 20px !important;
    height: 20px !important;
    color: ${(props) => (props.color ? props.color : 'yellow')} !important;
  }
`
