export const GET_STUDY_EXCEL_REPORT_DATA = 'GET_STUDY_EXCEL_REPORT_DATA'
export const GET_STUDY_EXCEL_REPORT_DATA_FULFILLED = 'GET_STUDY_EXCEL_REPORT_DATA_FULFILLED'
export const GET_STUDY_EXCEL_REPORT_DATA_REJECTED = 'GET_STUDY_EXCEL_REPORT_DATA_REJECTED'

export const GET_PDF_REPORT = 'GET_PDF_REPORT'
export const GET_PDF_REPORT_FULFILLED = 'GET_PDF_REPORT_FULFILLED'
export const GET_PDF_REPORT_REJECTED = 'GET_PDF_REPORT_REJECTED'

export const GET_ANALYTIC_REPORT_PDF = 'GET_ANALYTIC_REPORT_PDF'
export const GET_ANALYTIC_REPORT_PDF_FULFILLED = 'GET_ANALYTIC_REPORT_PDF_FULFILLED'
export const GET_ANALYTIC_REPORT_PDF_REJECTED = 'GET_ANALYTIC_REPORT_PDF_REJECTED'

export const GET_ANALYTIC_REPORT_HTML = 'GET_ANALYTIC_REPORT_HTML'
export const GET_ANALYTIC_REPORT_HTML_FULFILLED = 'GET_ANALYTIC_REPORT_HTML_FULFILLED'
export const GET_ANALYTIC_REPORT_HTML_REJECTED = 'GET_ANALYTIC_REPORT_HTML_REJECTED'
