import Immutable from 'immutable'
import { REDUCER_STATE } from '@/store/constants'
import {
  CREATE_ROLE_QS,
  CREATE_ROLE_QS_FULFILLED,
  CREATE_ROLE_QS_REJECTED,
  DELETE_ROLE_QS,
  DELETE_ROLE_QS_FULFILLED,
  DELETE_ROLE_QS_REJECTED,
  GET_ALLOCATED_ROLE,
  GET_ALLOCATED_ROLE_FULFILLED,
  GET_ALLOCATED_ROLE_REJECTED,
  GET_ROLE_QS,
  GET_ROLE_QS_FULFILLED,
  GET_ROLE_QS_REJECTED,
  GET_ROLE_QSS,
  GET_ROLE_QSS_FULFILLED,
  GET_ROLE_QSS_REJECTED,
  UPDATE_ROLE_QS,
  UPDATE_ROLE_QS_FULFILLED,
  UPDATE_ROLE_QS_REJECTED
} from '@/store/types'
import { findIndex } from 'lodash'
import { ROLE_QS_FIELDS } from '@/constants/api-data'

const FIELDS = REDUCER_STATE.ROLE_QS.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING_ROLE_QS]: false,
  [FIELDS.IS_GETTING_ROLE_QSS]: false,
  [FIELDS.IS_GETTING_ALLOCATED_ROLE]: false,
  [FIELDS.IS_CREATING_ROLE_QS]: false,
  [FIELDS.IS_UPDATING_ROLE_QS]: false,
  [FIELDS.IS_DELETING_ROLE_QS]: false,
  [FIELDS.ROLE_QSS]: { content: [], total: 0 },
  [FIELDS.ROLE_QS]: null
})

const handlers = {
  [GET_ROLE_QSS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ROLE_QSS]: true,
      [FIELDS.ROLE_QSS]: { content: [], total: 0 }
    }),
  [GET_ROLE_QSS_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ROLE_QSS]: false,
      [FIELDS.ROLE_QSS]: action.payload
    }),
  [GET_ROLE_QSS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_ROLE_QSS]: false
    }),

  [GET_ROLE_QS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ROLE_QS]: true,
      [FIELDS.ROLE_QS]: null
    }),
  [GET_ROLE_QS_FULFILLED]: (state, action) => {
    return state.merge({
      [FIELDS.IS_GETTING_ROLE_QS]: false,
      [FIELDS.ROLE_QS]: action.payload
    })
  },
  [GET_ROLE_QS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_ROLE_QS]: false
    }),
  [GET_ALLOCATED_ROLE]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ALLOCATED_ROLE]: true,
      [FIELDS.ROLE_QS]: null
    }),
  [GET_ALLOCATED_ROLE_FULFILLED]: (state, action) => {
    return state.merge({
      [FIELDS.IS_GETTING_ALLOCATED_ROLE]: false,
      [FIELDS.ROLE_QS]: action.payload
    })
  },
  [GET_ALLOCATED_ROLE_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_ALLOCATED_ROLE]: false
    }),

  [CREATE_ROLE_QS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING_ROLE_QS]: true
    }),
  [CREATE_ROLE_QS_FULFILLED]: (state, action) => {
    const data = state.getIn([FIELDS.ROLE_QSS])
    const roleQSs = data?.content
    const totalNum = data?.total + 1
    return state.merge({
      [FIELDS.IS_CREATING_ROLE_QS]: false,
      [FIELDS.ROLE_QSS]: {
        total: totalNum,
        content: [action.payload, ...roleQSs]
      }
    })
  },
  [CREATE_ROLE_QS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_CREATING_ROLE_QS]: false
    }),

  [DELETE_ROLE_QS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_DELETING_ROLE_QS]: true,
      [FIELDS.ROLE_QS]: null
    }),
  [DELETE_ROLE_QS_FULFILLED]: (state, action) => {
    const { id } = action.payload
    const data = state.getIn([FIELDS.ROLE_QSS])
    const roleQSs = data?.content
    const newTotalNum = data?.total - 1
    const index = findIndex(roleQSs || [], { [ROLE_QS_FIELDS.ID]: id })
    let newContent
    if (index !== -1) {
      newContent = [...roleQSs.slice(0, index), ...roleQSs.slice(index + 1)]
    }
    return state.merge({
      [FIELDS.IS_DELETING_ROLE_QS]: false,
      [FIELDS.ROLE_QSS]: { content: newContent, total: newTotalNum }
    })
  },
  [DELETE_ROLE_QS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_DELETING_ROLE_QS]: false
    }),

  [UPDATE_ROLE_QS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING_ROLE_QS]: true
    }),
  [UPDATE_ROLE_QS_FULFILLED]: (state, action) => {
    const { _id } = action.payload
    const data = state.getIn([FIELDS.ROLE_QSS])
    const roleQSs = data?.content || []
    const index = findIndex(roleQSs, { [ROLE_QS_FIELDS.ID]: _id })
    let newContent
    if (index !== -1) {
      newContent = [
        ...roleQSs.slice(0, index),
        action.payload,
        ...roleQSs.slice(index + 1, roleQSs.length)
      ]
    }
    return state.merge({
      [FIELDS.IS_UPDATING_ROLE_QS]: false,
      [FIELDS.ROLE_QSS]: { content: newContent || roleQSs, total: data?.total }
    })
  },
  [UPDATE_ROLE_QS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_UPDATING_ROLE_QS]: false
    })
}

export default {
  initialState,
  handlers
}
