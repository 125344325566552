export const LOGIN = 'LOGIN'
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED'
export const LOGIN_REJECTED = 'LOGIN_REJECTED'

export const LOGOUT = 'LOGOUT'
export const LOGOUT_FULFILLED = 'LOGOUT_FULFILLED'
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED'

export const AUTH = 'AUTH'
export const AUTH_FULFILLED = 'AUTH_FULFILLED'
export const AUTH_REJECTED = 'AUTH_REJECTED'

export const REGISTER = 'REGISTER'
export const REGISTER_FULFILLED = 'REGISTER_FULFILLED'
export const REGISTER_REJECTED = 'REGISTER_REJECTED'

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const UPDATE_CURRENT_USER_FULFILLED = 'UPDATE_CURRENT_USER_FULFILLED'
export const UPDATE_CURRENT_USER_REJECTED = 'UPDATE_CURRENT_USER_REJECTED'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED'
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED'

export const SEND_PASSWORD_RESET_EMAIL = 'SEND_PASSWORD_RESET_EMAIL'
export const SEND_PASSWORD_RESET_EMAIL_FULFILLED = 'SEND_PASSWORD_RESET_EMAIL_FULFILLED'
export const SEND_PASSWORD_RESET_EMAIL_REJECTED = 'SEND_PASSWORD_RESET_EMAIL_REJECTED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED'
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED'

export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const VERIFY_EMAIL_FULFILLED = 'VERIFY_EMAIL_FULFILLED'
export const VERIFY_EMAIL_REJECTED = 'VERIFY_EMAIL_REJECTED'

export const RE_VERIFY_EMAIL = 'RE_VERIFY_EMAIL'
export const RE_VERIFY_EMAIL_FULFILLED = 'RE_VERIFY_EMAIL_FULFILLED'
export const RE_VERIFY_EMAIL_REJECTED = 'RE_VERIFY_EMAIL_REJECTED'

export const EJECT_USER = 'EJECT_USER'
export const EJECT_USER_FULFILLED = 'EJECT_USER_FULFILLED'
export const EJECT_USER_REJECTED = 'EJECT_USER_REJECTED'
export const DELETE_SELF_ACCOUNT = 'DELETE_SELF_ACCOUNT'
export const DELETE_SELF_ACCOUNT_FULFILLED = 'DELETE_SELF_ACCOUNT_FULFILLED'
export const DELETE_SELF_ACCOUNT_REJECTED = 'DELETE_SELF_ACCOUNT_REJECTED'
