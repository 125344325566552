import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

/**
 * Create a new conference room at twilio
 * @param {String} studyId - studyId
 * @param {String} roomName           - room name
 * @param {String} roomType - roomType within ['group', 'peer-to-peer']
 * @param {String} conferenceType - conferenceType within ['study', 'one-to-one', 'group']
 * @return {Promise<*>}
 */
const createRoom = async ({ studyId, roomName, roomType, conferenceType }) => {
  const body = { roomName, roomType, conferenceType }
  try {
    return {
      ...extractResponse(await API.post(ENDPOINTS.CONFERENCE_ROOMS({ studyId }), body)),
      studyId,
      roomType
    }
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Invite participants to conference room
 * @param {Object} options
 * @param {string} options.studyId        - studyId
 * @param {string} options.roomName       - room name
 * @param {Array}  options.participantId   - invited user id
 * @param {Object} [options.newParticipant] - newly added participant
 * @return {Promise<Object>} options
 */
const inviteParticipants = async (options) => {
  const { studyId, roomName, participantId } = options
  const body = { participantId }
  try {
    await API.post(ENDPOINTS.CONFERENCE_INVITE({ studyId, roomName }), body)
    return options
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Participant response to an invitation
 * @param {String} studyId - studyId
 * @param {String} roomName - conference name
 * @param {String} response - participant response of invitation, can be ACCEPTED/REJECTED/NO_RESPONSE
 * @return {Promise<*>}
 */
const invitationResponse = async ({ studyId, roomName, response }) => {
  const body = { response }
  try {
    await API.post(ENDPOINTS.CONFERENCE_INVITATION_RESPONSE({ studyId, roomName }), body)
    return {
      studyId,
      roomName,
      response
    }
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Join a room
 * @param {String} studyId - studyId
 * @param {String} roomName - room name
 * @return {Promise<*>}
 */
const joinRoom = async ({ studyId, roomName }) => {
  try {
    return {
      ...extractResponse(await API.post(ENDPOINTS.CONFERENCE_JOIN({ studyId, roomName }))),
      studyId
    }
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Finish a meeting and close the room connection
 * @param {String} studyId - studyId
 * @param {String} roomName - room name
 * @return {Promise<*>}
 */
const endRoom = async ({ studyId, roomName }) => {
  try {
    return extractResponse(await API.delete(ENDPOINTS.CONFERENCE_FINISH({ studyId, roomName })))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get all available conference rooms within study
 * @param {string} studyId
 * @return {Promise<*>}
 */
const getAvailableConferenceRooms = async (studyId) => {
  try {
    return extractResponse(await API.get(ENDPOINTS.CONFERENCE_ROOMS({ studyId })))
  } catch (err) {
    errorHandler(err)
  }
}
export {
  createRoom,
  inviteParticipants,
  invitationResponse,
  joinRoom,
  endRoom,
  getAvailableConferenceRooms
}
