import client, { LOCAL_STORAGE_KEYS } from './client'

/**
 * Get general info from local storage
 * @returns {Promise<object>}
 */
const getGeneralInfo = () => client.getItem(LOCAL_STORAGE_KEYS.GENERAL_INFO)

/**
 * Set general info to local storage
 * @param   {object}   data
 * @returns {Promise}
 */
const setGeneralInfo = (data) => client.setItem(LOCAL_STORAGE_KEYS.GENERAL_INFO, data)

/**
 * Get notification settings from local storage
 * @return {Promise<object>}
 */
const getNotificationSettingsInLocalStorage = async () =>
  await client.getItem(LOCAL_STORAGE_KEYS.NOTIFICATION_SETTINGS)

/**
 * Set notification settings to local storage
 * @param   {object}   data
 * @returns {Promise}
 */
const setNotificationSettingsInLocalStorage = async (data) =>
  await client.setItem(LOCAL_STORAGE_KEYS.NOTIFICATION_SETTINGS, data)

/**
 * Remove notification settings from local storage
 * @return {Promise<void>}
 */
const removeNotificationSettingsInLocalStorage = async () =>
  await client.removeItem(LOCAL_STORAGE_KEYS.NOTIFICATION_SETTINGS)

export {
  getGeneralInfo,
  setGeneralInfo,
  getNotificationSettingsInLocalStorage,
  setNotificationSettingsInLocalStorage,
  removeNotificationSettingsInLocalStorage
}
