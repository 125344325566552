const BINDING_TYPE = 'fcm'

const NOTIFICATION_PERMISSION = {
  GRANTED: 'granted',
  DENIED: 'denied'
}

const NOTIFICATION_STALE_DURATION = 30 * 60 // 30 minutes in seconds

const NOTIFICATION_TYPES = {
  CONFERENCE_INVITATION: 'CONFERENCE_INVITATION',
  CONFERENCE_INVITATION_RESPONSE: 'CONFERENCE_INVITATION_RESPONSE',
  CONFERENCE_MISS_CALL: 'CONFERENCE_MISS_CALL',
  CONFERENCE_ROOM_END: 'CONFERENCE_ROOM_END',
  MANUAL_TRIGGERED: 'MANUAL_TRIGGERED',
  RECORD_CREATED: 'RECORD_CREATED',
  RECORD_UPDATED: 'RECORD_UPDATED',
  USER_ON_CALL_STATUS_UPDATED: 'USER_ON_CALL_STATUS_UPDATED',
  USER_ON_DUTY_STATUS_UPDATED: 'USER_ON_DUTY_STATUS_UPDATED',
  IMAGE_CREATED: 'IMAGE_CREATED',
  CONFERENCE_RESPONDED: 'CONFERENCE_RESPONDED',
  TRACK_CREATED: 'TRACK_CREATED',
  TRACK_CREATED_SELF: 'TRACK_CREATED_SELF',
  TRACK_BEFORE_ARRIVAL: 'TRACK_BEFORE_ARRIVAL',
  TRACK_ARRIVED: 'TRACK_ARRIVED',
  TRACK_COMPLETED: 'TRACK_COMPLETED',
  TRACK_ARRIVED_SELF: 'TRACK_ARRIVED_SELF'
}

const SERVICE_WORKER_MESSAGE_TYPE = {
  CONFERENCE_INVITATION: 'CONFERENCE_INVITATION',
  CONFERENCE_ROOM_END: 'CONFERENCE_ROOM_END',
  CONFERENCE_JOIN: 'CONFERENCE_JOIN',
  NEW_BACKGROUND_NOTIFICATION: 'NEW_BACKGROUND_NOTIFICATION',
  TWILIO_CHAT_NOTIFICATION: 'TWILIO_CHAT_NOTIFICATION',
  USER_ON_CALL_STATUS_UPDATED: 'USER_ON_CALL_STATUS_UPDATED',
  USER_ON_DUTY_STATUS_UPDATED: 'USER_ON_DUTY_STATUS_UPDATED',
  CONFERENCE_RESPONDED: 'CONFERENCE_RESPONDED',
  TRACK_CREATED: 'TRACK_CREATED',
  TRACK_BEFORE_ARRIVAL: 'TRACK_BEFORE_ARRIVAL',
  ACKNOWLEDGE_SENDER: 'ACKNOWLEDGE_SENDER'
}

const CLIENT_MESSAGE_TYPE = {
  CLIENT_READY: 'CLIENT_READY'
}

export {
  BINDING_TYPE,
  NOTIFICATION_PERMISSION,
  NOTIFICATION_STALE_DURATION,
  NOTIFICATION_TYPES,
  SERVICE_WORKER_MESSAGE_TYPE,
  CLIENT_MESSAGE_TYPE
}
