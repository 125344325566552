import { SHARED_FIELDS } from './shared'

const TRACKING_FIELDS = {
  ...SHARED_FIELDS,
  MRN: 'mrn',
  ZEUS_ID: 'zeusId',
  FORM_ID: 'formId',
  IMAGE_ID: 'imageId',
  PATIENT_NAME: 'patientName',
  SITE: 'site',
  STATUS: 'status',
  CREATOR: 'creator', // Creator
  RESOURCES: 'resources',
  TRACKING_TYPE: 'trackingType',
  VEHICLE_ID: 'vehicleId', // Vehicle/Flight ID
  DESTINATION: 'destination',
  ONSET_DATE_TIME: 'onsetDateTime',
  IS_AUTO_TRACKED: 'isAutoTracked',
  EXPECTED_TRAVEL_TIME: 'expectedTravelTime',
  EXPECTED_ARRIVAL_TIME: 'expectedArrivalTime',
  CURRENT_LOCATION: 'currentLocation',
  DEPARTURE: 'departure',
  THRESHOLDS: 'thresholds',
  DEPARTURE_TIME: 'departureTime',
  ARRIVAL_TIME: 'arrivalTime',
  TREATMENT_TIME: 'treatmentDateTime',
  LOCATION_HISTORY: 'locationHistory',
  NAVIGATION_WAY_POINTS: 'navigationWayPoints',
  ONSET_RAW_DATETIME: 'onsetRawDateTime',
  DEPARTURE_RAW_DATETIME: 'departureRawDateTime',
  ARRIVAL_RAW_DATETIME: 'arrivalRawDateTime',
  EXPECTED_ARRIVAL_RAW_DATETIME: 'expectedArrivalRawDateTime',
  TREATMENT_RAW_DATETIME: 'treatmentRawDateTime'
}
const TRACKING_LOCATION_HISTORY_FIELDS = {
  ...SHARED_FIELDS,
  TIME: 'time',
  LOCATION: 'location',
  SENDER: 'sender'
}
const TRACKING_ENUMS = {
  STATUS: {
    DEPARTED_TO_HOSPITAL: 'DEPARTED_TO_HOSPITAL',
    ARRIVED_AT_HOSPITAL: 'ARRIVED_AT_HOSPITAL',
    COMPLETED: 'COMPLETED'
  },
  TRACKING_TYPE: {
    AMBULANCE: 'AMBULANCE',
    FLIGHT: 'FLIGHT'
  },
  THRESHOLD_FIELDS: {
    TYPE: 'type',
    VALUE: 'value',
    COUNT: 'count'
  },
  THRESHOLD_ENUMS: {
    TYPE: {
      BEFORE_ARRIVAL: 'BEFORE_ARRIVAL'
    }
  }
}

export { TRACKING_LOCATION_HISTORY_FIELDS, TRACKING_FIELDS, TRACKING_ENUMS }
