import { REDUCER_STATE } from '@/store/constants'
import {
  CLEAR_TRACKS,
  GET_AND_UPDATE_TRACK_FROM_NOTIFY,
  GET_AND_UPDATE_TRACK_FROM_NOTIFY_FULFILLED,
  GET_AND_UPDATE_TRACK_FROM_NOTIFY_REJECTED,
  GET_COMPLETED_TRACKS,
  GET_COMPLETED_TRACKS_FULFILLED,
  GET_TRACK,
  GET_TRACK_FROM_NOTIFICATION,
  GET_TRACK_FROM_NOTIFICATION_FULFILLED,
  GET_TRACK_FROM_NOTIFICATION_REJECTED,
  GET_TRACK_FULFILLED,
  GET_TRACK_REJECTED,
  GET_TRACKS,
  GET_TRACKS_FULFILLED,
  GET_TRACKS_REJECTED,
  LINK_TRACK,
  LINK_TRACK_FULFILLED,
  LINK_TRACK_REJECTED,
  SET_CLICKED_STUDY_ID_AND_TRACK_ID,
  UPDATE_TRACK,
  UPDATE_TRACK_FULFILLED,
  UPDATE_TRACK_REJECTED
} from '@/store/types'
import Immutable from 'immutable'
import { findIndex } from 'lodash'
import { TRACKING_FIELDS } from '@/constants/api-data'
import { NOTIFICATION_TYPES } from '@/services/constants'

const FIELDS = REDUCER_STATE.TRACK.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING_TRACK]: false,
  [FIELDS.IS_GETTING_TRACK_FROM_NOTIFY]: false,
  [FIELDS.TRACKS]: [],
  [FIELDS.COMPLETED_TRACKS]: [],
  [FIELDS.TRACK]: null,
  [FIELDS.CLICKED_STUDY_ID_AND_TRACK_ID]: null
})

const handlers = {
  [GET_TRACKS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_TRACK]: true
    }),
  [GET_TRACKS_FULFILLED]: (state, action) => {
    const currentTracks = state.getIn([FIELDS.TRACKS])
    const newTracksContent = [...(currentTracks?.content || []), ...(action.payload?.content || [])]
    return state.merge({
      [FIELDS.IS_GETTING_TRACK]: false,
      [FIELDS.TRACKS]: { ...action.payload, content: newTracksContent }
    })
  },
  [GET_TRACKS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_TRACK]: false
    }),

  [GET_COMPLETED_TRACKS]: (state) =>
    state.merge({
      [FIELDS.IS_GETTING_TRACK]: true,
      [FIELDS.COMPLETED_TRACKS]: []
    }),
  [GET_COMPLETED_TRACKS_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_TRACK]: false,
      [FIELDS.COMPLETED_TRACKS]: action.payload
    }),
  // Get a track by id and update track list if exists
  [GET_TRACK]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      // [FIELDS.TRACK]: null,
      [FIELDS.IS_GETTING_TRACK]: true
    }),
  [GET_TRACK_FULFILLED]: (state, action) => {
    const currentTracks = state.getIn([FIELDS.TRACKS])?.content || []
    const index = findIndex(currentTracks, {
      [TRACKING_FIELDS.ID]: action.payload?.[[TRACKING_FIELDS.ID]]
    })
    let newTracksContent = [...currentTracks]
    if (index !== -1) {
      newTracksContent = [
        ...(currentTracks || []).slice(0, index),
        action.payload,
        ...(currentTracks || []).slice(index + 1)
      ]
    }
    return state.merge({
      [FIELDS.IS_GETTING_TRACK]: false,
      [FIELDS.TRACK]: action.payload,
      [FIELDS.TRACKS]: {
        ...state.getIn([FIELDS.TRACKS]),
        content: newTracksContent
      }
    })
  },
  [GET_TRACK_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_TRACK]: false
    }),
  // Get a track when click notification
  [GET_TRACK_FROM_NOTIFICATION]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.TRACK_FROM_NOTIFICATION]: null,
      [FIELDS.IS_GETTING_TRACK_FROM_NOTIFY]: true
    }),
  [GET_TRACK_FROM_NOTIFICATION_FULFILLED]: (state, action) => {
    return state.merge({
      [FIELDS.IS_GETTING_TRACK_FROM_NOTIFY]: false,
      [FIELDS.TRACK_FROM_NOTIFICATION]: action.payload
    })
  },
  [GET_TRACK_FROM_NOTIFICATION_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_TRACK_FROM_NOTIFY]: false
    }),
  // Get track from notify and update the list
  [GET_AND_UPDATE_TRACK_FROM_NOTIFY]: (state) =>
    state.merge({
      [FIELDS.IS_GETTING_TRACK_FROM_NOTIFY]: true,
      [FIELDS.ERROR]: null
    }),
  [GET_AND_UPDATE_TRACK_FROM_NOTIFY_FULFILLED]: (state, action) => {
    const currentTracks = state.getIn([FIELDS.TRACKS])?.content || []
    const indexOfNormalList = findIndex(currentTracks, {
      [TRACKING_FIELDS.ID]: action.payload?.[[TRACKING_FIELDS.ID]]
    })
    let newTracksContent = [...currentTracks]
    // Update normal track list
    if (indexOfNormalList !== -1) {
      newTracksContent = [
        ...(currentTracks?.slice(0, indexOfNormalList) || []),
        action.payload,
        ...(currentTracks?.slice(indexOfNormalList + 1) || [])
      ]
    } else if (
      [NOTIFICATION_TYPES.TRACK_CREATED, NOTIFICATION_TYPES.TRACK_CREATED_SELF].includes(
        action.payload?.notificationType
      )
    ) {
      newTracksContent = [action.payload, ...currentTracks]
    }
    let currentActiveTrack = state.getIn([FIELDS.TRACK])
    // If current active track is completed, then de-active it because it's been moved to completed tab
    if (
      currentActiveTrack &&
      currentActiveTrack?.[TRACKING_FIELDS.ID] === action.payload?.[TRACKING_FIELDS.ID] &&
      [NOTIFICATION_TYPES.TRACK_COMPLETED, NOTIFICATION_TYPES.TRACK_ARRIVED_SELF].includes(
        action.payload?.notificationType
      )
    ) {
      currentActiveTrack = null
    }
    return state.merge({
      [FIELDS.IS_GETTING_TRACK_FROM_NOTIFY]: false,
      [FIELDS.TRACKS]: {
        ...state.getIn([FIELDS.TRACKS]),
        content: newTracksContent
      },
      [FIELDS.TRACK]: currentActiveTrack
    })
  },
  [GET_AND_UPDATE_TRACK_FROM_NOTIFY_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_TRACK_FROM_NOTIFY]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [UPDATE_TRACK]: (state) =>
    state.merge({
      [FIELDS.IS_UPDATING_TRACK]: true,
      [FIELDS.ERROR]: null
    }),
  [UPDATE_TRACK_FULFILLED]: (state, action) => {
    const currentTracks = state.getIn([FIELDS.TRACKS])?.content
    const index = findIndex(currentTracks, {
      [TRACKING_FIELDS.ID]: action.payload?.[TRACKING_FIELDS.ID]
    })
    let newTrackContent = currentTracks
    if (index !== -1) {
      newTrackContent = [
        ...(currentTracks?.slice(0, index) || []),
        action.payload,
        ...(currentTracks?.slice(index + 1) || [])
      ]
    }
    return state.merge({
      [FIELDS.IS_UPDATING_TRACK]: false,
      [FIELDS.TRACKS]: {
        ...state.getIn([FIELDS.TRACKS]),
        content: newTrackContent
      }
    })
  },
  [UPDATE_TRACK_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_UPDATING_TRACK]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [LINK_TRACK]: (state) =>
    state.merge({
      [FIELDS.IS_UPDATING_TRACK]: true,
      [FIELDS.ERROR]: null
    }),
  [LINK_TRACK_FULFILLED]: (state, action) => {
    // Active tracks
    const currentTracks = state.getIn([FIELDS.TRACKS])?.content
    const index = findIndex(currentTracks, {
      [TRACKING_FIELDS.ID]: action.payload?.[TRACKING_FIELDS.ID]
    })
    let newTrackContent = currentTracks
    if (index !== -1) {
      newTrackContent = [
        ...(currentTracks?.slice(0, index) || []),
        action.payload,
        ...(currentTracks?.slice(index + 1) || [])
      ]
    }
    // Completed tracks
    const currentCompletedTracks = state.getIn([FIELDS.COMPLETED_TRACKS])?.content
    const indexOfCompleted = findIndex(currentCompletedTracks, {
      [TRACKING_FIELDS.ID]: action.payload?.[TRACKING_FIELDS.ID]
    })
    let newCompletedTrackContent = currentCompletedTracks
    if (indexOfCompleted !== -1) {
      newCompletedTrackContent = [
        ...(currentCompletedTracks?.slice(0, indexOfCompleted) || []),
        action.payload,
        ...(currentCompletedTracks?.slice(indexOfCompleted + 1) || [])
      ]
    }
    return state.merge({
      [FIELDS.IS_UPDATING_TRACK]: false,
      [FIELDS.TRACKS]: {
        ...state.getIn([FIELDS.TRACKS]),
        content: newTrackContent
      },
      [FIELDS.COMPLETED_TRACKS]: {
        ...state.getIn([FIELDS.COMPLETED_TRACKS]),
        content: newCompletedTrackContent
      }
    })
  },
  [LINK_TRACK_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_UPDATING_TRACK]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [CLEAR_TRACKS]: (state) =>
    state.merge({
      [FIELDS.TRACKS]: [],
      [FIELDS.COMPLETED_TRACKS]: []
    }),

  [SET_CLICKED_STUDY_ID_AND_TRACK_ID]: (state, action) =>
    state.merge({
      [FIELDS.CLICKED_STUDY_ID_AND_TRACK_ID]: action.payload
    })
}

export default {
  initialState,
  handlers
}
