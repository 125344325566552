import React, { useEffect, useRef, useState } from 'react'
import { useError } from '@/libs/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { REDUCER_STATE } from '@/store/constants'

const Audio = () => {
  const dispatch = useDispatch()

  const [error, setError] = useState(null)
  const audioRef = useRef(null)
  const audioSrc = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUDIO.NAME, REDUCER_STATE.AUDIO.FIELDS.URL])
  )
  const isPlaySound = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUDIO.NAME, REDUCER_STATE.AUDIO.FIELDS.IS_PLAYING])
  )
  const isPlaySoundAsLoop = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUDIO.NAME, REDUCER_STATE.AUDIO.FIELDS.LOOP])
  )
  /**
   * Play notification sound (no matter where the notification comes from, as long as we are using dispatch(audioEpics.playAudio()))
   */
  useEffect(() => {
    if (audioRef.current) {
      const audio = audioRef.current
      audio.src = audioSrc
      audio.loop = isPlaySoundAsLoop
      audio.autoplay = isPlaySound
      // when the sound has been loaded, play the sound
      audio.oncanplaythrough = () => {
        const playedPromise = audio.play()
        if (playedPromise) {
          playedPromise.catch((e) => {
            if (e?.name === 'NotAllowedError' || e?.name === 'NotSupportedError') {
              setError({
                ...e,
                message: 'The ringer has been muted for your privacy.'
              })
            }
          })
        }
      }
    }
  }, [dispatch, audioSrc, isPlaySound, isPlaySoundAsLoop])

  useError(error)

  return <audio ref={audioRef} />
}

export default Audio
