import styled from 'styled-components'
import { Space } from 'antd'

const HeaderWrapper = styled.div`
  // background-image: linear-gradient(to top, var(--c-zeus-blue-light), var(--c-zeus-blue-lighter));
  /*
  background-image: linear-gradient(
    to top,
    var(--c-zeus-blue-dark),
    var(--c-zeus-blue),
    var(--c-zeus-blue-lighter)
  );
  */
  background-image: url('public/images/bg-header.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 70px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

  .logo {
    position: absolute;
    height: 45px !important;
    right: 14px;
    top: 10px;
  }
`
const Tabs = styled.div`
  position: absolute;
  top: 70px;
  bottom: 70px;
  right: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 70px);
`

const TabHeader = styled.div`
  background-color: #f0f0f0;
  border-radius: 0 10px 30px 0;
  box-shadow: 3px -3px 6px rgba(100, 100, 100, 0.6);
  clip-path: polygon(0% -20%, 120% -20%, 120% calc(100% - 36px), 0 100%);
  color: var(--c-zeus-blue) !important;
  font-size: 14px;
  margin: 0 18px 0px 0;
  padding: 2px 4px 32px 10px;
  position: relative;
  text-align: center;
  width: 52px;
  z-index: 80;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;

    span.label {
      ${'' /* font-size: 18px; */}
      font-weight: 400;
      display: block;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      margin-top: 6px;
    }
  }

  * {
    vertical-align: middle;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  &.active {
    z-index: 200;
    background-color: white;

    a {
      color: var(--c-zeus-blue-dark) !important;
    }
  }

  &.home,
  &.admin {
    background-color: #f0f0f0 !important;
  }

  @media screen and (max-height: 640px) {
    & {
      a span.label {
        display: none;
      }
    }
  }

  @media screen and (min-height: 640px) {
    & {
      font-size: calc(14px + (100vh - 640px) / 50);
      padding-top: calc(6px + (100vh - 640px) / 25);

      svg {
        width: calc(16px + (100vh - 640px) / 50);
        height: calc(16px + (100vh - 640px) / 50);
      }
    }
  }
  @media screen and (min-height: 798px) {
    & {
      font-size: 20px;
      padding-top: 20px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`

const Actions = styled(Space)`
  position: absolute;
  bottom: 20px;
  width: 100%;
`

const Action = styled.div`
  margin: 3px;
  display: flex;
  justify-content: center;

  .anticon {
    cursor: pointer;
  }

  svg {
    color: white;
    font-size: 20px;
  }
`

export { HeaderWrapper, Actions, Action, Tabs, TabHeader }
