const FIELDS = {
  ID: '_id',
  STUDY_DATE_TIME: 'studyDateTime',
  PATIENT_ID: 'patientId',
  PATIENT_NAME: 'patientName',
  STUDY_DESCRIPTION: 'studyDescription',
  STUDY_INSTANCE_UID: 'studyInstanceUID',
  MODALITY: 'modality',
  INSTITUTION_NAME: 'institutionName',
  LAST_UPDATE: 'lastUpdate',
  TOTAL_NUMBER_OF_IMAGES: 'totalNumberOfImages'
}

export default FIELDS
