import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

/**
 * Create a group conversation
 * @param {String} friendlyName
 * @returns {Promise<*>}
 */
const createGroupConversation = async ({ friendlyName }) => {
  const body = { friendlyName }
  try {
    return extractResponse(await API.post(ENDPOINTS.GROUP_CONVERSATIONS, body))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Create a private conversation
 * @return {Promise<*>}
 */
const createPrivateConversation = async ({ invitedUserId }) => {
  const body = { invitedUserId }
  try {
    const res = extractResponse(await API.post(ENDPOINTS.PRIVATE_CONVERSATIONS, body))
    return res
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Leave conversation
 * @return {Promise<*>}
 */
const leaveConversation = async ({ conversationSid, participantSid }) => {
  try {
    const res = extractResponse(
      await API.delete(ENDPOINTS.CONVERSATION_PARTICIPANT({ conversationSid, participantSid }))
    )
    return res
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Init convo when user open convo for the first time after each login
 * @return {Promise<*>}
 */
const getConvoAccessToken = async () => {
  try {
    return extractResponse(await API.get(ENDPOINTS.CONVERSATION_ACCESS_TOKEN))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Invite participants to conversation
 * @param {String} convoSid
 * @param {String} inviteUsers - [userObject]
 * @return {Promise<*>}
 */
const inviteConvoParticipants = async ({ convoSid, inviteUsers }) => {
  try {
    const body = { inviteUsers }
    return extractResponse(
      await API.post(ENDPOINTS.CONVERSATION_PARTICIPANTS({ conversationSid: convoSid }), body)
    )
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get participant resource for last read consumption
 * @param {String} conversationSid
 * @param {String} participantSid
 * @return {Promise<*>}
 */
const getParticipantResource = async ({ conversationSid, participantSid }) => {
  try {
    return extractResponse(
      await API.get(ENDPOINTS.CONVERSATION_PARTICIPANT({ conversationSid, participantSid }))
    )
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Send message
 * @param {String} conversationSid
 * @param {Object} message
 * @return {Promise<*>}
 */
const sendMessage = async ({ conversationSid, message }) => {
  try {
    const body = { message }
    return extractResponse(
      await API.post(ENDPOINTS.CONVERSATION_MESSAGES({ conversationSid }), body)
    )
  } catch (err) {
    errorHandler(err)
  }
}

const updateConversation = async ({ conversationSid, friendlyName }) => {
  try {
    const body = { friendlyName }
    return extractResponse(await API.put(ENDPOINTS.CONVERSATION({ conversationSid }), body))
  } catch (err) {
    errorHandler(err)
  }
}

const updateParticipantResource = async ({
  conversationSid,
  participantIdentity,
  lastReadMessageIndex,
  lastReadTimestamp
}) => {
  try {
    const body = { lastReadMessageIndex, lastReadTimestamp }
    return extractResponse(
      await API.put(
        ENDPOINTS.CONVERSATION_PARTICIPANT({
          conversationSid,
          participantSid: participantIdentity
        }),
        body
      )
    )
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update self notification setting for conversation
 * @param {String} conversationSid
 * @param {Boolean} isNotifiable
 * @return {Promise<*>}
 */
const updateConversationNotification = async ({ conversationSid, isNotifiable }) => {
  try {
    const body = {
      isNotifiable
    }
    return extractResponse(
      await API.put(ENDPOINTS.CONVERSATION_NOTIFICATION({ conversationSid }), body)
    )
  } catch (err) {
    errorHandler(err)
  }
}

export {
  createGroupConversation,
  createPrivateConversation,
  getConvoAccessToken,
  getParticipantResource,
  inviteConvoParticipants,
  sendMessage,
  updateConversation,
  updateParticipantResource,
  leaveConversation,
  updateConversationNotification
}
