export const GET_TRACKS = 'GET_TRACKS'
export const GET_TRACKS_FULFILLED = 'GET_TRACKS_FULFILLED'
export const GET_TRACKS_REJECTED = 'GET_TRACKS_REJECTED'

export const GET_COMPLETED_TRACKS = 'GET_COMPLETED_TRACKS'
export const GET_COMPLETED_TRACKS_FULFILLED = 'GET_COMPLETED_TRACKS_FULFILLED'

export const GET_TRACK = 'GET_TRACK'
export const GET_TRACK_FULFILLED = 'GET_TRACK_FULFILLED'
export const GET_TRACK_REJECTED = 'GET_TRACK_REJECTED'

export const GET_TRACK_FROM_NOTIFICATION = 'GET_TRACK_FROM_NOTIFICATION'
export const GET_TRACK_FROM_NOTIFICATION_FULFILLED = 'GET_TRACK_FROM_NOTIFICATION_FULFILLED'
export const GET_TRACK_FROM_NOTIFICATION_REJECTED = 'GET_TRACK_FROM_NOTIFICATION_REJECTED'

// Get track from notify and update the list
export const GET_AND_UPDATE_TRACK_FROM_NOTIFY = 'GET_AND_UPDATE_TRACK_FROM_NOTIFY'
export const GET_AND_UPDATE_TRACK_FROM_NOTIFY_FULFILLED =
  'GET_AND_UPDATE_TRACK_FROM_NOTIFY_FULFILLED'
export const GET_AND_UPDATE_TRACK_FROM_NOTIFY_REJECTED = 'GET_AND_UPDATE_TRACK_FROM_NOTIFY_REJECTED'

export const UPDATE_TRACK = 'UPDATE_TRACK'
export const UPDATE_TRACK_FULFILLED = 'UPDATE_TRACK_FULFILLED'
export const UPDATE_TRACK_REJECTED = 'UPDATE_TRACK_REJECTED'

export const LINK_TRACK = 'LINK_TRACK'
export const LINK_TRACK_FULFILLED = 'LINK_TRACK_FULFILLED'
export const LINK_TRACK_REJECTED = 'LINK_TRACK_REJECTED'

export const SET_CLICKED_STUDY_ID_AND_TRACK_ID = 'SET_CLICKED_STUDY_ID_AND_TRACK_ID'
export const CLEAR_TRACKS = 'CLEAR_TRACKS'
