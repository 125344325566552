import axios from 'axios'
import Qs from 'qs'
import { unauthorizedInterceptor } from './utils'

const baseURL = process.env.REACT_APP_API_PATH || '/api'

const paramsSerializer = (params) => Qs.stringify(params, { arrayFormat: 'comma' })

const API = axios.create({
  baseURL,
  paramsSerializer
})

unauthorizedInterceptor(API)

const createAxiosInstance = (config) =>
  axios.create({
    baseURL,
    paramsSerializer,
    ...config
  })

const makeUrl = (endpoint) => `${baseURL}${endpoint}`

export default API
export { createAxiosInstance, makeUrl }
