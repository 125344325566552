import API, { createAxiosInstance, makeUrl } from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'
import { updateUser } from './admin/users'
import { uploadFile } from './file'
import { USER_FIELDS } from '@/constants/api-data'
import {
  getNotificationSettingsInLocalStorage,
  removeNotificationSettingsInLocalStorage,
  setCookieExpiry,
  setNotificationSettingsInLocalStorage
} from '@/services/localstorage'

/**
 * Check whether current user still have login states
 * @return {Promise<*>}
 */
const auth = async () => {
  try {
    const res = extractResponse(await API.post(ENDPOINTS.AUTH))
    // store notification setting in localstorage for service-worker to read
    const currentSettingCache = await getNotificationSettingsInLocalStorage()
    if (!currentSettingCache) {
      await setNotificationSettingsInLocalStorage(res?.[USER_FIELDS.NOTIFICATION_SETTINGS])
    }

    return res
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Login
 * @param {string} username - username
 * @param {string} password - user password
 * @param {Boolean} remember - whether remember current user's login states
 * @return {Promise<void>}
 */
const login = async ({ username, password, remember }) => {
  const body = {
    username,
    password,
    remember
  }
  try {
    const res = extractResponse(await API.post(ENDPOINTS.LOGIN, body))
    await setCookieExpiry(res.cookieExpiry)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Logout
 * @return {Promise<void>}
 */
const logout = async () => {
  const axiosNoInterceptors = createAxiosInstance()
  try {
    await removeNotificationSettingsInLocalStorage()
    await setCookieExpiry(0)
    await axiosNoInterceptors.post(ENDPOINTS.LOGOUT)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Change user's password
 * @param {string} oldPassword - user's old password
 * @param {string} newPassword - user's new password
 * @return {Promise<void>}
 */
const changePassword = async ({ oldPassword, newPassword }) => {
  const body = {
    oldPassword,
    newPassword
  }
  try {
    await API.post(ENDPOINTS.CHANGE_PASSWORD, body)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update current user's profile
 * @param {Object} updates - user data
 * @return {Promise<*>}
 */
const updateCurrentUser = async (updates) => {
  try {
    const payload = { ...updates }
    if (typeof updates.avatar !== 'string') {
      if (payload.avatar) {
        const { id } = await uploadFile({ file: payload.avatar })
        payload.avatar = makeUrl(ENDPOINTS.FILE({ id }))
      }
    }
    // store notification setting in localstorage for service-worker to read
    if (payload?.[USER_FIELDS.NOTIFICATION_SETTINGS]) {
      await setNotificationSettingsInLocalStorage(payload?.[USER_FIELDS.NOTIFICATION_SETTINGS])
    }
    return await updateUser(payload)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Handle send reset password email
 * @param {string} username - username, used to confirm user reset correct user
 * @param {string} email - email address
 * @return {Promise<void>}
 */
const handleSendResetPasswordEmail = async ({ email, username }) => {
  try {
    await API.post(ENDPOINTS.SEND_RESET_PASSWORD, {
      email,
      username
    })
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Handle reset password
 * @param {string} password - new password
 * @param {string} token - a valid token in the email link
 * @param {string} userId - current user's id in the email link
 * @return {Promise<void>}
 */
const handleResetPassword = async ({ password, token, userId }) => {
  try {
    await API.post(ENDPOINTS.RESET_PASSWORD, { password, token, userId })
    await removeNotificationSettingsInLocalStorage()
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Handle ejectUser
 * @param {string} userId - current user's id in the email link
 * @return {Promise<void>}
 */
const handleEjectUser = async ({ userId }) => {
  try {
    const res = await API.post(ENDPOINTS.EJECT_USER({ userId }))
    return res.data
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Handle register user
 * @param {String} username - username
 * @param {String} email - email address
 * @param {String} firstName - first name
 * @param {String} lastName - last name
 * @param {String} mobilePhone - mobile phone number
 * @param {String} studyId - which study the user belongs to
 * @param {String} role - the role of user, only support normal roles
 * @return {Promise<void>}
 */
const handleRegister = async ({
  username,
  email,
  firstName,
  lastName,
  mobilePhone,
  studyId,
  role
}) => {
  try {
    const body = {
      username,
      email,
      firstName,
      lastName,
      mobilePhone,
      studyId,
      role
    }
    await API.post(ENDPOINTS.REGISTER, body)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Verify user email
 * @param {string} userId - user id from email
 * @param {string} token - valid token from email
 * @return {Promise<void>}
 */
const handleVerifyEmail = async ({ userId, token }) => {
  try {
    const body = {
      userId,
      token
    }

    await API.post(ENDPOINTS.VERIFY_EMAIL, body)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Resend verification email
 * @param {string} username - username, used to find a user to resend email
 * @return {Promise<void>}
 */
const handleResendVerificationEmail = async (username) => {
  try {
    await API.post(ENDPOINTS.RESEND_VERIFICATION_EMAIL, { username })
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * User delete self account
 * @return {Promise<void>}
 */
const deleteSelfAccount = async () => {
  try {
    await API.delete(ENDPOINTS.USER_DELETION_SELF)
  } catch (err) {
    errorHandler(err)
  }
}

export {
  login,
  logout,
  handleRegister,
  auth,
  changePassword,
  updateCurrentUser,
  handleEjectUser,
  handleSendResetPasswordEmail,
  handleResetPassword,
  handleVerifyEmail,
  handleResendVerificationEmail,
  deleteSelfAccount
}
