import { CONFERENCE } from '@/constants'

const createRoomOptions = (values, conferenceType) => {
  let roomName
  let roomType
  const studyId = values?.studyId
  const randomName = Math.random().toString(36).substr(2, 20)
  if (conferenceType === CONFERENCE.TYPE.ONE_TO_ONE) {
    roomName = randomName
    roomType = CONFERENCE.ROOM_TYPE.GO
  } else if (conferenceType === CONFERENCE.TYPE.GROUP) {
    roomName = randomName
    roomType = CONFERENCE.ROOM_TYPE.GROUP
  }
  return { roomName, roomType, studyId, conferenceType }
}

export { createRoomOptions }
