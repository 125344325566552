import Immutable from 'immutable'

import { REDUCER_STATE } from '@/store/constants'
import {
  AUTH,
  AUTH_FULFILLED,
  AUTH_REJECTED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FULFILLED,
  CHANGE_PASSWORD_REJECTED,
  DELETE_SELF_ACCOUNT,
  DELETE_SELF_ACCOUNT_FULFILLED,
  DELETE_SELF_ACCOUNT_REJECTED,
  EJECT_USER,
  EJECT_USER_FULFILLED,
  EJECT_USER_REJECTED,
  LOGIN,
  LOGIN_FULFILLED,
  LOGIN_REJECTED,
  LOGOUT,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  RE_VERIFY_EMAIL,
  RE_VERIFY_EMAIL_FULFILLED,
  RE_VERIFY_EMAIL_REJECTED,
  REGISTER,
  REGISTER_FULFILLED,
  REGISTER_REJECTED,
  RESET_PASSWORD,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_REJECTED,
  SEND_PASSWORD_RESET_EMAIL,
  SEND_PASSWORD_RESET_EMAIL_FULFILLED,
  SEND_PASSWORD_RESET_EMAIL_REJECTED,
  UPDATE_CURRENT_USER,
  UPDATE_CURRENT_USER_FULFILLED,
  UPDATE_CURRENT_USER_REJECTED,
  VERIFY_EMAIL,
  VERIFY_EMAIL_FULFILLED,
  VERIFY_EMAIL_REJECTED
} from '@/store/types'

const {
  AUTH: { FIELDS }
} = REDUCER_STATE
const initialState = new Immutable.Map({
  [FIELDS.ERROR]: null,
  [FIELDS.IS_AUTHORIZED]: false,
  [FIELDS.IS_LOGGING_OUT]: false,
  [FIELDS.IS_DELETING]: false,
  [FIELDS.IS_VERIFYING]: false,
  [FIELDS.IS_VERIFYING_EMAIL]: false,
  [FIELDS.IS_RESENDING_VERIFY_EMAIL]: false,
  [FIELDS.IS_LOGGING_IN]: false,
  [FIELDS.IS_UPDATING]: false,
  [FIELDS.IS_SENDING_RESET_PASSWORD_EMAIL]: false,
  [FIELDS.IS_EJECTED_SUCCESSFULLY]: false,
  [FIELDS.USER]: null
})

export default {
  initialState,
  handlers: {
    [AUTH]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_VERIFYING]: true
      }),
    [AUTH_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.IS_AUTHORIZED]: true,
        [FIELDS.IS_VERIFYING]: false,
        [FIELDS.USER]: action.payload
      }),
    [AUTH_REJECTED]: (state) =>
      state.merge({
        [FIELDS.IS_AUTHORIZED]: false,
        [FIELDS.IS_VERIFYING]: false,
        [FIELDS.USER]: null
      }),
    [LOGIN]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_LOGGING_IN]: true
      }),
    [LOGIN_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_AUTHORIZED]: true,
        [FIELDS.IS_LOGGING_IN]: false
      }),
    [LOGIN_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_AUTHORIZED]: false,
        [FIELDS.IS_LOGGING_IN]: false
      }),
    [LOGOUT]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_LOGGING_OUT]: true
      }),
    [LOGOUT_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_AUTHORIZED]: false,
        [FIELDS.IS_LOGGING_OUT]: false,
        [FIELDS.USER]: null
      }),
    [LOGOUT_REJECTED]: (state) =>
      state.merge({
        [FIELDS.IS_AUTHORIZED]: false,
        [FIELDS.IS_LOGGING_OUT]: false
      }),
    [UPDATE_CURRENT_USER]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_UPDATING]: true
      }),
    [UPDATE_CURRENT_USER_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.IS_UPDATING]: false,
        [FIELDS.USER]: action.payload
      }),
    [UPDATE_CURRENT_USER_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_UPDATING]: false
      }),
    [CHANGE_PASSWORD]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_UPDATING]: true
      }),
    [CHANGE_PASSWORD_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_UPDATING]: false
      }),
    [CHANGE_PASSWORD_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_UPDATING]: false
      }),

    [SEND_PASSWORD_RESET_EMAIL]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_SENDING_RESET_PASSWORD_EMAIL]: true
      }),
    [SEND_PASSWORD_RESET_EMAIL_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_SENDING_RESET_PASSWORD_EMAIL]: false
      }),
    [SEND_PASSWORD_RESET_EMAIL_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_SENDING_RESET_PASSWORD_EMAIL]: false
      }),

    [RESET_PASSWORD]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_RESETTING_PASSWORD]: true
      }),
    [RESET_PASSWORD_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_RESETTING_PASSWORD]: false
      }),
    [RESET_PASSWORD_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_RESETTING_PASSWORD]: false
      }),

    [REGISTER]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_REGISTERING]: true
      }),
    [REGISTER_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_REGISTERING]: false
      }),
    [REGISTER_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_REGISTERING]: false
      }),

    [VERIFY_EMAIL]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_VERIFYING_EMAIL]: true
      }),
    [VERIFY_EMAIL_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_VERIFYING_EMAIL]: false
      }),
    [VERIFY_EMAIL_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_VERIFYING_EMAIL]: false
      }),

    [RE_VERIFY_EMAIL]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_RESENDING_VERIFY_EMAIL]: true
      }),
    [RE_VERIFY_EMAIL_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_RESENDING_VERIFY_EMAIL]: false
      }),
    [RE_VERIFY_EMAIL_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_RESENDING_VERIFY_EMAIL]: false
      }),

    [EJECT_USER]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_EJECTING_USER]: true
      }),
    [EJECT_USER_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.IS_EJECTING_USER]: false,
        [FIELDS.IS_EJECTED_SUCCESSFULLY]: !!action.payload?.result
      }),
    [EJECT_USER_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_EJECTING_USER]: false
      }),
    [DELETE_SELF_ACCOUNT]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_DELETING]: true
      }),
    [DELETE_SELF_ACCOUNT_FULFILLED]: (state) =>
      state.merge({
        [FIELDS.IS_AUTHORIZED]: false,
        [FIELDS.IS_DELETING]: false,
        [FIELDS.USER]: null
      }),
    [DELETE_SELF_ACCOUNT_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.IS_DELETING]: false,
        [FIELDS.ERROR]: action.payload
      })
  }
}
