export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_FULFILLED = 'GET_NOTIFICATIONS_FULFILLED'
export const GET_NOTIFICATIONS_REJECTED = 'GET_NOTIFICATIONS_REJECTED'

export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_NOTIFICATION_FULFILLED = 'GET_NOTIFICATION_FULFILLED'
export const GET_NOTIFICATION_REJECTED = 'GET_NOTIFICATION_REJECTED'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_NOTIFICATIONS_FULFILLED = 'SET_NOTIFICATIONS_FULFILLED'
export const SET_NOTIFICATIONS_REJECTED = 'SET_NOTIFICATIONS_REJECTED'

export const SET_TWILIO_CHAT_NOTIFICATIONS = 'SET_TWILIO_CHAT_NOTIFICATIONS'
export const SET_TWILIO_CHAT_NOTIFICATIONS_FULFILLED = 'SET_TWILIO_CHAT_NOTIFICATIONS_FULFILLED'
export const SET_TWILIO_CHAT_NOTIFICATIONS_REJECTED = 'SET_TWILIO_CHAT_NOTIFICATIONS_REJECTED'

export const UPDATE_IS_NOTIFICATION_SUPPORTED = 'UPDATE_IS_NOTIFICATION_SUPPORTED'

export const FIREBASE_INIT_FULFILLED = 'FIREBASE_INIT_FULFILLED'
export const FIREBASE_INIT_REJECTED = 'FIREBASE_INIT_REJECTED'

export const REQUEST_NOTIFICATION_PERMISSION_FULFILLED = 'REQUEST_NOTIFICATION_PERMISSION_FULFILLED'
export const REQUEST_NOTIFICATION_PERMISSION_REJECTED = 'REQUEST_NOTIFICATION_PERMISSION_REJECTED'

export const FIREBASE_MESSAGING_INIT_FULFILLED = 'FIREBASE_MESSAGING_INIT_FULFILLED'
export const FIREBASE_MESSAGING_INIT_REJECTED = 'FIREBASE_MESSAGING_INIT_REJECTED'

export const GET_NOTIFICATION_TEMPLATES = 'GET_NOTIFICATION_TEMPLATES'
export const GET_NOTIFICATION_TEMPLATES_FULFILLED = 'GET_NOTIFICATION_TEMPLATES_FULFILLED'
export const GET_NOTIFICATION_TEMPLATES_REJECTED = 'GET_NOTIFICATION_TEMPLATES_REJECTED'

export const CREATE_NOTIFICATION_TEMPLATE = 'CREATE_NOTIFICATION_TEMPLATE'
export const CREATE_NOTIFICATION_TEMPLATE_FULFILLED = 'CREATE_NOTIFICATION_TEMPLATE_FULFILLED'
export const CREATE_NOTIFICATION_TEMPLATE_REJECTED = 'CREATE_NOTIFICATION_TEMPLATE_REJECTED'

export const UPDATE_NOTIFICATION_TEMPLATE = 'UPDATE_NOTIFICATION_TEMPLATE'
export const UPDATE_NOTIFICATION_TEMPLATE_FULFILLED = 'UPDATE_NOTIFICATION_TEMPLATE_FULFILLED'
export const UPDATE_NOTIFICATION_TEMPLATE_REJECTED = 'UPDATE_NOTIFICATION_TEMPLATE_REJECTED'

export const DELETE_NOTIFICATION_TEMPLATE = 'DELETE_NOTIFICATION_TEMPLATE'
export const DELETE_NOTIFICATION_TEMPLATE_FULFILLED = 'DELETE_NOTIFICATION_TEMPLATE_FULFILLED'
export const DELETE_NOTIFICATION_TEMPLATE_REJECTED = 'DELETE_NOTIFICATION_TEMPLATE_REJECTED'

export const NEW_NOTIFICATION_INCOMING = 'NEW_NOTIFICATION_INCOMING'
export const CLEAR_NOTIFICATION_IDS = 'CLEAR_NOTIFICATION_IDS'

export const SET_IS_REGISTERING_NOTIFICATION = 'SET_IS_REGISTERING_NOTIFICATION'
export const SET_IS_SHOW_ACKNOWLEDGE_MODAL = 'SET_IS_SHOW_ACKNOWLEDGE_MODAL'
export const UPDATE_NOTIFICATION_TEMPLATE_ORDER = 'UPDATE_NOTIFICATION_TEMPLATE_ORDER'
export const UPDATE_NOTIFICATION_TEMPLATE_ORDER_FULFILLED =
  'UPDATE_NOTIFICATION_TEMPLATE_ORDER_FULFILLED'
export const UPDATE_NOTIFICATION_TEMPLATE_ORDER_REJECTED =
  'UPDATE_NOTIFICATION_TEMPLATE_ORDER_REJECTED'

export const ACKNOWLEDGE_SENDER = 'ACKNOWLEDGE_SENDER'
export const ACKNOWLEDGE_SENDER_FULFILLED = 'ACKNOWLEDGE_SENDER_FULFILLED'
export const ACKNOWLEDGE_SENDER_REJECTED = 'ACKNOWLEDGE_SENDER_REJECTED'

export const SEND_NOTIFICATION_TEMPLATE = 'SEND_NOTIFICATION_TEMPLATE'
export const SEND_NOTIFICATION_TEMPLATE_FULFILLED = 'SEND_NOTIFICATION_TEMPLATE_FULFILLED'
export const SEND_NOTIFICATION_TEMPLATE_REJECTED = 'SEND_NOTIFICATION_TEMPLATE_REJECTED'
