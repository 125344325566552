import { ReactComponent as LogoSVG } from '@/static/images/logo.svg'
import { ReactComponent as LogoStack } from '@/static/images/logo-2-stack.svg'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  display: block;
  padding: 0;
  margin: 0;
  width: auto;
  height: 100%;
  text-align: center;

  svg {
    height: 100%;
    color: ${(props) => (props.color ? props.color : '#1679b7')};
  }
`

/**
 * App Logo component
 */
const Logo = (props) => {
  return <Wrapper {...props}>{props.stack ? <LogoStack /> : <LogoSVG />}</Wrapper>
}

Logo.propTypes = {
  /** Color of the logo artwork */
  color: PropTypes.string,
  stack: PropTypes.bool
}

Wrapper.propTypes = Logo.propTypes

export default Logo
