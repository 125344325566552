import API from '../client'
import ENDPOINTS from '../endpoints'
import { errorHandler, extractResponse } from '../utils'

/**
 * Select a question set for a study
 * @param {object} options
 * @param {String} options.studyId - question set study id
 * @param {String} options.questionSetId - question set id
 * @return {Promise<*>}
 */
const selectQuestionSetForStudy = async ({ studyId, questionSetId }) => {
  try {
    const response = await API.post(ENDPOINTS.QUESTIONSET_SELECT({ studyId, id: questionSetId }))
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

export { selectQuestionSetForStudy }
