import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

/**
 * Send email for pdf report
 * @param {String} to - receiver email
 * @param {String} studyId
 * @param {String} siteId
 * @param {String} recordId
 * @returns {Promise<*>}
 */
const sendPdfReportThruEmail = async ({ to, studyId, siteId, recordId }) => {
  try {
    const body = { to, studyId, siteId, recordId }
    return extractResponse(await API.post(ENDPOINTS.EMAILS, body))
  } catch (err) {
    errorHandler(err)
  }
}

export { sendPdfReportThruEmail }
