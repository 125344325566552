// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ujLpD2tlwiHgL8VQLvd8 {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all var(--transition-delay) var(--transition-timing-function);
  z-index: 1001;
}
.ujLpD2tlwiHgL8VQLvd8.V00x3XM4RUvFMcMc7t9w {
  height: 100vh;
  position: fixed;
  width: 100vw;
}
.ujLpD2tlwiHgL8VQLvd8 .XFohIO2cP8crVXJaRD2Y {
  color: #4888d0;
}
`, "",{"version":3,"sources":["webpack://./src/components/loading/styles/index.module.less"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,0CAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,OAAA;EACA,kBAAA;EACA,QAAA;EACA,MAAA;EACA,yEAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,eAAA;EACA,YAAA;AACJ;AAlBA;EAqBI,cAAA;AAAJ","sourcesContent":[".loading__wrapper {\n  align-items: center;\n  background-color: rgba(255, 255, 255, 0.9);\n  bottom: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  transition: all var(--transition-delay) var(--transition-timing-function);\n  z-index: 1001;\n\n  &.fullscreen {\n    height: 100vh;\n    position: fixed;\n    width: 100vw;\n  }\n\n  .tip {\n    color: @primary-color;\n  }\n}\n@primary-color: #4888d0;@primary-color-light: #40a9ff;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading__wrapper": `ujLpD2tlwiHgL8VQLvd8`,
	"fullscreen": `V00x3XM4RUvFMcMc7t9w`,
	"tip": `XFohIO2cP8crVXJaRD2Y`
};
export default ___CSS_LOADER_EXPORT___;
