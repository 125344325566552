import { SHARED_FIELDS } from './shared'

const HOSPITAL_FIELDS = {
  ...SHARED_FIELDS,
  NAME: 'name',
  ADDRESS: 'address',
  CONTACT_LIST: 'contactList',
  COORDINATES: 'coordinates',
  TYPE: 'type',
  IS_AIR_ACCESSIBLE: 'isAirAccessible',
  IS_AMBULANCE: 'isAmbulance',
  IS_SIP: 'isSip'
}

const HOSPITAL_CONTACT_LIST_FIELDS = {
  ID: SHARED_FIELDS.ID,
  URI: 'uri',
  TYPE: 'type',
  FRIENDLY_NAME: 'friendlyName'
}

const HOSPITALS_ENUMS = {
  TYPE: {
    HOSPITAL: 'HOSPITAL',
    AMBULANCE: 'AMBULANCE',
    AIRPORT: 'AIRPORT',
    OTHER: 'OTHER'
  }
}

export { HOSPITAL_FIELDS, HOSPITAL_CONTACT_LIST_FIELDS, HOSPITALS_ENUMS }
