import { forEach, map } from 'lodash'

import API, { makeUrl } from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

// /**
//  * Get patients and their studies that match mrn array
//  * @param {Number} limit - limit number of resources returned
//  * @param {Number} since - since which index to get out resources
//  * @return {Promise<*>}
//  */
// const getPatients = async ({ limit, since, studyId }) => {
//   try {
//     const params = {
//       limit,
//       since,
//       expand: true
//     }
//     return extractResponse(await API.get(ENDPOINTS.DICOM_PATIENTS({ studyId }), { params }))
//   } catch (err) {
//     errorHandler(err)
//   }
// }

const getPatientSeries = async (id) => {
  try {
    const patientSeries = extractResponse(await API.get(ENDPOINTS.DICOM_PATIENT_SERIES({ id })))
    forEach(patientSeries, (series, index) => {
      series.instanceUrls = map(series.Instances, (id) =>
        makeUrl(ENDPOINTS.DICOM_INSTANCE_FILE({ id }))
      )
      patientSeries[index] = series
    })
    return patientSeries
  } catch (err) {
    errorHandler(err)
  }
}

const getPatientStudy = async ({ mrn, studyId, studyDate }) => {
  try {
    const params = {}
    if (studyDate) {
      params.studyDate = studyDate
    }
    return extractResponse(
      await API.get(ENDPOINTS.DICOM_PATIENT_STUDY({ id: mrn, studyId }), { params })
    )
  } catch (err) {
    errorHandler(err)
  }
}

// const getSeries = async (id) => {
//   try {
//     const series = extractResponse(await API.get(ENDPOINTS.DICOM_SERIES({ id })))
//     series.instanceUrls = map(series.Instances, (id) =>
//       makeUrl(ENDPOINTS.DICOM_INSTANCE_FILE({ id }))
//     )
//     return series
//   } catch (err) {
//     errorHandler(err)
//   }
// }
/**
 * Get a list of dicom image
 * @param {number} skip
 * @param {number} limit
 * @param {string} studyId
 * @param {Array} sortBy
 * @param {Array} orderBy
 * @param {string} mrn
 * @param {string} patientName
 * @param {string} studyName
 * @param {string} institutionName
 * @param {string} modality
 * @param {number} studyDateTime
 * @param {number} lastUpdate
 * @returns {Promise<*>}
 */
const getDicomStudies = async ({
  skip,
  limit,
  studyId,
  sortBy,
  orderBy,
  patientId,
  patientName,
  studyDescription,
  institutionName,
  modality,
  studyDateTime,
  lastUpdate
}) => {
  try {
    const params = {
      limit,
      skip,
      sortBy,
      orderBy,
      mrn: patientId,
      patientName,
      studyName: studyDescription,
      studyDateTime,
      institutionName,
      modality,
      lastUpdate
    }
    return extractResponse(await API.get(ENDPOINTS.DICOM_STUDIES({ studyId }), { params }))
  } catch (err) {
    errorHandler(err)
  }
}

export { getPatientSeries, getPatientStudy, getDicomStudies }
