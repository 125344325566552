import { SHARED_FIELDS } from './shared'

const STUDY_FIELDS = {
  ...SHARED_FIELDS,
  STUDY_NAME: 'studyName',
  STUDY_PREFIX: 'studyPrefix',
  SIP_ENABLED: 'sipEnabled',
  TRACKING_ENABLED: 'trackingEnabled',
  LIVE_CAMERA_ENABLED: 'liveCameraEnabled',
  CHART_ENABLED: 'chartEnabled',
  SMS_ROSTER_ENABLED: 'smsRosterEnabled',
  ANALYTIC_TEMPLATE: 'analyticTemplate',
  TRACKING_NOTIFICATION_SETTINGS: 'trackingNotificationSettings',
  CHART_URL: 'chartUrl',
  CHART_DASHBOARD_ID: 'chartDashboardId',
  CHART_SECRET: 'chartSecret',
  CHART_USER_VISIBLE_ENABLED: 'chartUserVisibleEnabled',
  SMS_ROSTER_MESSAGE: 'smsRosterMessage'
}
const STUDY_THRESHOLD_FIELDS = {
  TYPE: 'type',
  VALUE: 'value',
  COUNT: 'count'
}

const STUDY_ENUMS = {
  TRACKING_NOTIFICATION_SETTINGS: {
    BEFORE_ARRIVAL: 'BEFORE_ARRIVAL'
  }
}

export { STUDY_FIELDS, STUDY_THRESHOLD_FIELDS, STUDY_ENUMS }
