import { SHARED_FIELDS } from './shared'

const ROSTER_FIELDS = {
  ...SHARED_FIELDS,
  USER: 'user',
  DESCRIPTION: 'description',
  START_DATE_TIME: 'startDateTime',
  END_DATE_TIME: 'endDateTime'
}

export { ROSTER_FIELDS }
