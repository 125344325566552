import { REDUCER_STATE } from '@/store/constants'
import {
  CONFERENCE_GET_STUDIES,
  CONFERENCE_GET_STUDIES_FULFILLED,
  CONFERENCE_GET_STUDIES_REJECTED,
  CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_FULFILLED,
  CREATE_ROOM,
  CREATE_ROOM_FULFILLED,
  CREATE_ROOM_REJECTED,
  END_ROOM,
  END_ROOM_FULFILLED,
  END_ROOM_REJECTED,
  INVITATION_RESPONSE,
  INVITATION_RESPONSE_FULFILLED,
  INVITATION_RESPONSE_REJECTED,
  INVITE_PARTICIPANTS,
  INVITE_PARTICIPANTS_FULFILLED,
  INVITE_PARTICIPANTS_REJECTED,
  JOIN_ROOM_FULFILLED,
  JOIN_ROOM_REJECTED,
  SET_TWILIO_CONFERENCE_ROOM
} from '@/store/types'
import Immutable from 'immutable'
import { CONFERENCE_INVITATION_RESPONSE } from '@/constants'

const FIELDS = REDUCER_STATE.CONFERENCE.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.DATA]: null,
  [FIELDS.ERROR]: null,
  [FIELDS.INVITED_USERS]: [],
  [FIELDS.IS_CREATING_ROOM]: false,
  [FIELDS.IS_ENDING]: false,
  [FIELDS.IS_GETTING_STUDIES]: false,
  [FIELDS.IS_INVITING_PARTICIPANTS]: false,
  [FIELDS.IS_JOINING_ROOM]: false,
  [FIELDS.IS_RESPONSE]: false,
  [FIELDS.ONE_TO_ONE_TARGET_FROM_CONTACTS]: null,
  [FIELDS.STUDIES]: [],
  [FIELDS.TWILIO_CONFERENCE_ROOM]: null
})

const handlers = {
  [CREATE_ROOM]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING_ROOM]: true,
      [FIELDS.DATA]: null
    }),
  [CREATE_ROOM_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_CREATING_ROOM]: false,
      [FIELDS.DATA]: action.payload
    }),
  [CREATE_ROOM_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_CREATING_ROOM]: false
    }),

  [INVITE_PARTICIPANTS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_INVITING_PARTICIPANTS]: true
    }),
  [INVITE_PARTICIPANTS_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_INVITING_PARTICIPANTS]: false,
      [FIELDS.INVITED_USERS]: action.payload.participants
    }),
  [INVITE_PARTICIPANTS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_INVITING_PARTICIPANTS]: false
    }),

  [INVITATION_RESPONSE]: (state) =>
    state.merge({
      [FIELDS.IS_RESPONSE]: true
    }),
  [INVITATION_RESPONSE_FULFILLED]: (state, action) => {
    if (action.payload.response === CONFERENCE_INVITATION_RESPONSE.ACCEPT) {
      return state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_JOINING_ROOM]: true,
        [FIELDS.IS_RESPONSE]: false,
        [FIELDS.DATA]: null
      })
    }
    return state.merge({
      [FIELDS.IS_RESPONSE]: false
    })
  },
  [INVITATION_RESPONSE_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_RESPONSE]: false
    }),

  // [JOIN_ROOM]: (state) =>
  //   state.merge({
  //     [FIELDS.ERROR]: null,
  //     [FIELDS.IS_JOINING_ROOM]: true,
  //     [FIELDS.DATA]: null
  //   }),
  [JOIN_ROOM_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_JOINING_ROOM]: false,
      [FIELDS.DATA]: action.payload
    }),
  [JOIN_ROOM_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_JOINING_ROOM]: false
    }),

  [END_ROOM]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_ENDING]: true
    }),
  [END_ROOM_FULFILLED]: (state) =>
    state.merge({
      [FIELDS.DATA]: null,
      [FIELDS.ERROR]: null,
      [FIELDS.INVITED_USERS]: [],
      [FIELDS.IS_CREATING_ROOM]: false,
      [FIELDS.IS_ENDING]: false,
      [FIELDS.IS_GETTING_STUDIES]: false,
      [FIELDS.IS_INVITING_PARTICIPANTS]: false,
      [FIELDS.IS_JOINING_ROOM]: false,
      [FIELDS.IS_RESPONSE]: false,
      [FIELDS.ONE_TO_ONE_TARGET_FROM_CONTACTS]: null,
      [FIELDS.STUDIES]: [],
      [FIELDS.TWILIO_CONFERENCE_ROOM]: null
    }),
  [END_ROOM_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_ENDING]: false
    }),

  [CONFERENCE_GET_STUDIES]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_STUDIES]: true,
      [FIELDS.STUDIES]: []
    }),
  [CONFERENCE_GET_STUDIES_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_STUDIES]: false,
      [FIELDS.STUDIES]: action.payload
    }),
  [CONFERENCE_GET_STUDIES_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_STUDIES]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [CONFERENCE_UPDATE_INVITED_USER_INVITATION_STATUS_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.INVITED_USERS]: action.payload.invitedUsers
    }),

  [SET_TWILIO_CONFERENCE_ROOM]: (state, action) =>
    state.merge({
      [FIELDS.TWILIO_CONFERENCE_ROOM]: action.payload
    })
}

export default {
  initialState,
  handlers
}
