import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

/**
 * Get question sets
 * @param {*} studyId
 */
const getQuestionSets = async (studyId) => {
  try {
    return extractResponse(await API.get(ENDPOINTS.QUESTIONSETS({ studyId })))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get options from server
 * @param {String} studyId
 * @return {Promise<*>}
 */
const getOptions = async (studyId) => {
  try {
    return extractResponse(await API.get(ENDPOINTS.OPTIONS({ studyId })))
  } catch (err) {
    errorHandler(err)
  }
}

const getQuestionSetsAndOptions = async (studyId) => {
  try {
    const promises = []
    promises.push(getQuestionSets(studyId))
    promises.push(getOptions(studyId))
    const [questionSets, options] = await Promise.all(promises)
    return {
      questionSets,
      options
    }
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Create a new record at specific site
 * @param {Object} answer
 * @param {String} mrn           - record mrn
 * @param {String} questionSetId - the id of the question set
 * @param {String} siteId        - site id
 * @param {String} studyId       - study id
 * @param {Number} progressPercentage       - progressPercentage
 * @return {Promise<*>}
 */
const createRecord = async ({
  answer,
  mrn,
  questionSetId,
  siteId,
  studyId,
  progressPercentage
}) => {
  const body = { answer, mrn, questionSet: questionSetId, site: siteId, progressPercentage }
  try {
    return extractResponse(await API.post(ENDPOINTS.RECORDS({ studyId }), body))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get record for specific id in specific site
 * @param {String} id       - record id
 * @param {String} studyId  - study id
 * @param {boolean} populateUser
 * @returns {Promise<*>}
 */
const getRecord = async ({ id, studyId, populateUser }) => {
  try {
    const promises = []
    const params = {}
    if (populateUser) {
      params.populates = ['questionSet', 'user', 'site']
    } else {
      params.populates = ['questionSet', 'site']
    }
    promises.push(API.get(ENDPOINTS.RECORD({ recordId: id, studyId }), { params }))
    promises.push(getOptions(studyId))

    let [response, options] = await Promise.all(promises)
    response = extractResponse(response)
    response.options = options
    return response
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get record list
 * TODO: if admin user want to search by site, should be added here
 * @param {string} studyId
 * @param {string|null} siteId
 * @param {string|null} questionSetId
 * @param {number} limit
 * @param {number} skip
 * @param {boolean} excludesEmptyMRN - whether exclude record with empty mrn
 * @param {Array} sortBy - sort by which fields
 * @param {String} zeusId - zeus id
 * @param {Array} completed
 * @param {Array} onsetDateTime
 * @param {String} patientFirstName
 * @param {String} patientLastName
 * @param {Array} orderBy
 * @param {Array} mrn
 * @param {Array} creator - get records by list of author
 * @param {Array} siteName
 * @param {Array} createdAt
 * @param {Array} updatedAt
 * @param {Array} allocatedQuestionSet
 * @returns {Promise<*>}
 */
const getRecords = async ({
  studyId,
  siteId = null,
  zeusId,
  questionSetId = null,
  onsetDateTime,
  patientFirstName,
  patientLastName,
  completed,
  limit,
  skip,
  excludesEmptyMRN,
  sortBy,
  orderBy,
  mrn,
  creator,
  siteName,
  createdAt,
  updatedAt,
  allocatedQuestionSet
}) => {
  try {
    const params = {
      populates: ['user', 'site', 'questionSet'],
      excludes: 'answer',
      limit,
      skip,
      siteId,
      questionSetId,
      zeusId,
      onsetDateTime,
      patientFirstName,
      patientLastName,
      excludesEmptyMRN: !!excludesEmptyMRN,
      completed,
      sortBy,
      orderBy,
      mrn,
      creator,
      siteName,
      createdAt,
      updatedAt,
      allocatedQuestionSet
    }
    const response = extractResponse(await API.get(ENDPOINTS.RECORDS({ studyId }), { params }))
    return response
  } catch (err) {
    errorHandler(err)
  }
}

const lockRecord = async ({ id, studyId }) => {
  try {
    const record = extractResponse(await API.post(ENDPOINTS.LOCK_RECORD({ recordId: id, studyId })))
    return record
  } catch (err) {
    errorHandler(err)
  }
}

const unlockRecord = async ({ id, studyId }) => {
  try {
    const record = extractResponse(
      await API.post(ENDPOINTS.UNLOCK_RECORD({ recordId: id, studyId }))
    )
    return record
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Handle upload record
 * @param {Object} answer - answer
 * @param {String} mrn - mrn
 * @param {String} id - record id
 * @param {String} questionSetId
 * @param {String} siteId
 * @param {String} studyId
 * @param {Number} progressPercentage
 * @return {Promise<*>}
 */
const updateRecord = async ({
  answer,
  mrn,
  id,
  questionSetId,
  siteId,
  studyId,
  progressPercentage
}) => {
  try {
    const body = {
      answer,
      mrn,
      questionSet: questionSetId,
      site: siteId,
      progressPercentage
    }
    const response = await API.put(ENDPOINTS.RECORD({ recordId: id, studyId }), body)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update and get latest record
 * @param {Object} answer
 * @param {String} mrn
 * @param {String} id
 * @param {String} questionSetId
 * @param {String} siteId
 * @param {String} studyId
 * @param {Boolean} populateUser
 * @param {Number} progressPercentage
 * @return {Promise<*>}
 */
const updateRecordAndGetRecord = async ({
  answer,
  mrn,
  id,
  questionSetId,
  siteId,
  studyId,
  populateUser,
  progressPercentage
}) => {
  try {
    await updateRecord({ answer, mrn, id, questionSetId, siteId, studyId, progressPercentage })
    return await getRecord({ id, studyId, populateUser })
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Delete record for specific id in specific site
 * @param {String} recordId       - record id
 * @param {String} studyId  - study id
 * @return {Promise<*>}
 */
const deleteRecord = async ({ recordId, studyId }) => {
  try {
    await API.delete(ENDPOINTS.RECORD({ recordId, studyId }))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get last created at record.
 * @param {String} studyId  - study id
 * @returns {Promise<*>}
 */
const getLastCreatedRecord = async ({ studyId }) => {
  try {
    const response = await API.get(ENDPOINTS.RECORD_LAST_CREATED_AT({ studyId }))
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

export {
  createRecord,
  getQuestionSetsAndOptions,
  updateRecordAndGetRecord,
  getRecord,
  getRecords,
  lockRecord,
  unlockRecord,
  updateRecord,
  deleteRecord,
  getLastCreatedRecord
}
