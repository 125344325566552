export const GET_ROLE_QSS = 'GET_ROLE_QSS'
export const GET_ROLE_QSS_FULFILLED = 'GET_ROLE_QSS_FULFILLED'
export const GET_ROLE_QSS_REJECTED = 'GET_ROLE_QSS_REJECTED'

export const GET_ROLE_QS = 'GET_ROLE_QS'
export const GET_ROLE_QS_FULFILLED = 'GET_ROLE_QS_FULFILLED'
export const GET_ROLE_QS_REJECTED = 'GET_ROLE_QS_REJECTED'

export const GET_ALLOCATED_ROLE = 'GET_ALLOCATED_ROLE'
export const GET_ALLOCATED_ROLE_FULFILLED = 'GET_ALLOCATED_ROLE_FULFILLED'
export const GET_ALLOCATED_ROLE_REJECTED = 'GET_ALLOCATED_ROLE_REJECTED'

export const CREATE_ROLE_QS = 'CREATE_ROLE_QS'
export const CREATE_ROLE_QS_FULFILLED = 'CREATE_ROLE_QS_FULFILLED'
export const CREATE_ROLE_QS_REJECTED = 'CREATE_ROLE_QS_REJECTED'

export const UPDATE_ROLE_QS = 'UPDATE_ROLE_QS'
export const UPDATE_ROLE_QS_FULFILLED = 'UPDATE_ROLE_QS_FULFILLED'
export const UPDATE_ROLE_QS_REJECTED = 'UPDATE_ROLE_QS_REJECTED'

export const DELETE_ROLE_QS = 'DELETE_ROLE_QS'
export const DELETE_ROLE_QS_FULFILLED = 'DELETE_ROLE_QS_FULFILLED'
export const DELETE_ROLE_QS_REJECTED = 'DELETE_ROLE_QS_REJECTED'
