import { catchError, map, mergeMap } from 'rxjs/operators'
import { from, of } from 'rxjs'
import { ofType } from 'redux-observable'

import {
  GET_API_VERSION,
  GET_API_VERSION_FULFILLED,
  GET_API_VERSION_REJECTED,
  GET_GENERAL_INFO,
  GET_GENERAL_INFO_FULFILLED,
  GET_GENERAL_INFO_REJECTED,
  GET_MAPBOX_TOKEN,
  GET_MAPBOX_TOKEN_FULFILLED,
  GET_MAPBOX_TOKEN_REJECTED,
  GET_REGISTER_INFO,
  GET_REGISTER_INFO_FULFILLED,
  GET_REGISTER_INFO_REJECTED
} from '@/store/types'
import {
  getApiVersion,
  getGeneralInfo,
  getMapboxToken,
  handleGetRegistrationInfo
} from '@/services/api'
import { setGeneralInfo } from '@/services/localstorage'

const generalEpics = {
  getGeneralInfo: () => ({
    type: GET_GENERAL_INFO
  }),

  getGeneralInfoFulfilled: (payload) => ({
    type: GET_GENERAL_INFO_FULFILLED,
    payload
  }),

  getGeneralInfoError: (error) => ({
    type: GET_GENERAL_INFO_REJECTED,
    payload: error
  }),

  getGeneralInfoEpic: (action$) =>
    action$.pipe(
      ofType(GET_GENERAL_INFO),
      mergeMap(() =>
        from(getGeneralInfo()).pipe(
          mergeMap((data) => setGeneralInfo(data)),
          map(generalEpics.getGeneralInfoFulfilled),
          catchError((error) => of(generalEpics.getGeneralInfoError(error)))
        )
      )
    ),

  getApiVersion: () => ({
    type: GET_API_VERSION
  }),

  getApiVersionFulfilled: (payload) => ({
    type: GET_API_VERSION_FULFILLED,
    payload
  }),

  getApiVersionError: (error) => ({
    type: GET_API_VERSION_REJECTED,
    payload: error
  }),

  getApiVersionEpic: (action$) =>
    action$.pipe(
      ofType(GET_API_VERSION),
      mergeMap(() =>
        from(getApiVersion()).pipe(
          map(generalEpics.getApiVersionFulfilled),
          catchError((error) => of(generalEpics.getApiVersionError(error)))
        )
      )
    ),

  getRegistrationInfo: () => ({
    type: GET_REGISTER_INFO
  }),

  getRegistrationInfoFulfilled: (payload) => ({
    type: GET_REGISTER_INFO_FULFILLED,
    payload
  }),

  getRegistrationInfoError: (error) => ({
    type: GET_REGISTER_INFO_REJECTED,
    payload: error
  }),

  getRegistrationInfoEpic: (action$) =>
    action$.pipe(
      ofType(GET_REGISTER_INFO),
      mergeMap(() =>
        from(handleGetRegistrationInfo()).pipe(
          map(generalEpics.getRegistrationInfoFulfilled),
          catchError((error) => of(generalEpics.getRegistrationInfoError(error)))
        )
      )
    ),

  getMapboxToken: () => ({
    type: GET_MAPBOX_TOKEN,
    payload: {}
  }),
  getMapboxTokenFulfilled: (payload) => ({
    type: GET_MAPBOX_TOKEN_FULFILLED,
    payload
  }),
  getMapboxTokenError: (error) => ({
    type: GET_MAPBOX_TOKEN_REJECTED,
    payload: error
  }),
  getMapboxTokenEpic: (action$) =>
    action$.pipe(
      ofType(GET_MAPBOX_TOKEN),
      mergeMap(() =>
        from(getMapboxToken()).pipe(
          map((response) => generalEpics.getMapboxTokenFulfilled(response)),
          catchError((err) => of(generalEpics.getMapboxTokenError(err)))
        )
      )
    )
}

export default generalEpics
