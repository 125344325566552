import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import generalEpics from '@/store/epics/general'
import { REDUCER_STATE } from '@/store/constants'
import { Wrapper, Version } from './styles'

const Versions = () => {
  const dispatch = useDispatch()
  const apiVersion = useSelector((state) =>
    state.getIn([REDUCER_STATE.GENERAL_INFO.NAME, REDUCER_STATE.GENERAL_INFO.FIELDS.API_VERSION])
  )

  useEffect(() => {
    dispatch(generalEpics.getApiVersion())
  }, [])
  return (
    <Wrapper>
      {apiVersion && <Version>api version: {apiVersion.version}</Version>}
      {process.env.UI_VERSION && <Version>ui version: {process.env.UI_VERSION}</Version>}
    </Wrapper>
  )
}

export default Versions
