import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Version = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
`

export { Wrapper, Version }
