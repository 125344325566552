import API from '../client'
import ENDPOINTS from '../endpoints'
import { errorHandler, extractResponse } from '../utils'

/**
 * Create a study
 * @param {object} options
 * @param {String} options.studyName - study name
 * @return {Promise<*>}
 */
const createStudy = async ({
  studyName,
  studyPrefix,
  sipEnabled,
  trackingEnabled,
  analyticTemplate,
  chartEnabled,
  chartUrl,
  chartDashboardId,
  chartSecret,
  chartUserVisibleEnabled,
  smsRosterEnabled,
  smsRosterMessage
}) => {
  const body = {
    studyName,
    studyPrefix,
    sipEnabled,
    trackingEnabled,
    analyticTemplate,
    chartEnabled,
    chartUrl,
    chartDashboardId,
    chartSecret,
    chartUserVisibleEnabled,
    smsRosterEnabled,
    smsRosterMessage
  }
  try {
    const response = await API.post(ENDPOINTS.STUDIES, body)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Delete a study
 * @param {object} options
 * @param {String} options.id - study id
 * @return {Promise<void>}
 */
const deleteStudy = async (id) => {
  try {
    await API.delete(ENDPOINTS.STUDY({ id }))
    return id
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get studies
 * @param {boolean} populate - whether get sites in studies
 * @return {Promise<*>}
 */
const getStudies = async (populate) => {
  try {
    const params = {}
    if (populate) {
      params.populates = 'sites'
    }
    const response = await API.get(ENDPOINTS.STUDIES, { params })
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get a study
 * @param {*} id        - study id
 * @param {*} populate  - check if get sites in the study
 */
const getStudy = async (id, populate) => {
  try {
    const params = {}
    if (populate) {
      params.populates = 'sites'
    }
    const response = await API.get(ENDPOINTS.STUDY({ id }), { params })
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update a study
 * @param {object}  options
 * @param {string}  options.id        - study id
 * @param {object}  options.body      - the part that study will be updated
 * @param {boolean} options.populate  - check if return updated study with populated sites
 */
const updateStudy = async ({ id, body, populate }) => {
  try {
    let response = extractResponse(await API.put(ENDPOINTS.STUDY({ id }), body))
    if (populate) {
      response = await getStudy(id, populate)
    }
    return response
  } catch (err) {
    errorHandler(err)
  }
}

export { createStudy, deleteStudy, getStudies, getStudy, updateStudy }
