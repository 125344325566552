import { SHARED_FIELDS } from './shared'

const SITE_FIELDS = {
  ...SHARED_FIELDS,
  SITE_NAME: 'siteName',
  SITE_TYPE: 'siteType',
  SITE_PREFIX: 'sitePrefix',
  SITE_COUNTER: 'siteCounter'
}

const SITE_ENUMS = {
  SITE_TYPE: {
    HUB: 'HUB',
    SPOKE: 'SPOKE'
  }
}

export { SITE_FIELDS, SITE_ENUMS }
