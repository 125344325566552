import React from 'react'
import PropTypes from 'prop-types'

import { getAvatarUrl } from '@/libs/functions/avatar'
import { SimpleAvatarWrapper, AvatarImage } from './style'

const SimpleAvatar = (props) => {
  const name = props.user?.name || ''
  const role = props.user?.role || ''
  const src = getAvatarUrl(props.user.avatar)

  return (
    <SimpleAvatarWrapper title={name + role?.length ? ` (${role})` : ''}>
      <AvatarImage url={src} />
    </SimpleAvatarWrapper>
  )
}

SimpleAvatar.propTypes = {
  user: PropTypes.object
}

export default SimpleAvatar
