import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

const uploadFile = async ({ file }) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    return extractResponse(
      await API.post(ENDPOINTS.FILES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    )
  } catch (err) {
    errorHandler(err)
  }
}

const updateFile = async ({ id, file }) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    await API.post(ENDPOINTS.FILE({ id }), formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (err) {
    errorHandler(err)
  }
}

export { updateFile, uploadFile }
