import styled from 'styled-components'

const FOOTER_HEIGHT = '60px'
const HEADER_WIDTH = '70px'

// Whole layout
const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
`

const LayoutBodyWrapper = styled.div`
  height: ${(props) => (props.ishidefooter ? `100%` : `calc(100% - ${FOOTER_HEIGHT})`)};
`

const LayoutFooterWrapper = styled.div`
  display: ${(props) => (props.ishidefooter ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 15px;
  padding: 10px;
  background-color: ${(props) => (props.isbackgroundgrey ? 'transparent' : 'white')};
  height: ${FOOTER_HEIGHT};
  width: calc(100% - ${HEADER_WIDTH});
`

// Auth
/**
 * Auth router outside container
 */
const AuthLayoutWrapper = styled.div`
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: #333333;

  > div {
    //display: block;
    padding: 0;
    margin: 0;
  }

  .with-shadow {
    box-shadow: 0 0 7px rgba(100, 100, 100, 0.2);
  }

  .when {
    color: #94935c;
    font-weight: 500;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 6px 10px;
    height: 54px;
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    font-size: 14px;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
  }
`

/**
 * Auth layout wrapper for the content below header
 */
const AuthLayoutLowerWrapper = styled.div`
  background-color: white;
  // box-shadow: 3px 0 6px rgba(100, 100, 100, 0.2);
  //z-index: 100;
  width: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 66px;
  bottom: 0;
  min-height: 400px;
  overflow: hidden;
  padding-top: 10px;

  &.grey {
    background-color: #f0f0f0;
  }
`

/**
 * Content under auth layout's lower wrapper
 */
const AuthLayoutContentWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.isHideNavigation ? '100%' : '95%')};
  position: relative;
  padding: 10px 10px 10px 10px;
`

// Public
/**
 * The outer layout for public routers
 */
const PublicLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(36deg, var(--c-zeus-blue-light), var(--c-zeus-blue-lighter));
`

export {
  AuthLayoutWrapper,
  AuthLayoutLowerWrapper,
  AuthLayoutContentWrapper,
  PublicLayoutWrapper,
  LayoutWrapper,
  LayoutBodyWrapper,
  LayoutFooterWrapper
}
