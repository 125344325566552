import moment from 'moment'
import { TABLE_SORT_ORDER } from '@/constants'
import Qs from 'qs'
import { QUESTION_SET_FIELDS, QUESTION_SET_QUESTION_FIELDS } from '@/constants/api-data'
import { find, isEmpty } from 'lodash'
import downloadjs from 'downloadjs'

const ttl = (strings, ...keys) => {
  return function (collection) {
    let result = [strings[0]]
    collection = collection || {}
    keys.forEach(function (key, i) {
      result.push(collection[key], strings[i + 1])
    })
    return result.join('')
  }
}

const getDuration = (time) => moment.duration(time - Date.now()).humanize(true)

/**
 * Merge multi pictures into a long picture
 * @param {Array} imageList - base64 of url of image
 * @return {Promise<String>}
 */
const mergeImages = (imageList = []) => {
  const canvas = window.document.createElement('canvas')
  const Image = window.Image
  // Get canvas context
  const ctx = canvas.getContext('2d')
  return new Promise((resolve) => {
    // Load sources
    const images = imageList.map(
      (source) =>
        new Promise((resolve, reject) => {
          // Convert sources to objects
          if (source.constructor.name !== 'Object') {
            source = { src: source }
          }

          // Resolve source and img when loaded
          const img = new Image()
          img.onerror = () => reject(new Error('Could not load image'))
          img.onload = () => resolve(Object.assign({}, source, { img }))
          img.src = source.src
        })
    )

    // When sources have loaded
    resolve(
      Promise.all(images).then((images) => {
        // Set canvas dimensions
        const getMaxSize = (dim) => Math.max(...images.map((image) => image.img[dim]))
        const getSize = (image, dim) => image.img[dim]
        let previousDy = 0
        let canvasHeight = 0
        // Draw images to canvas
        images.forEach((image) => {
          const imageHeight = getSize(image, 'height')
          canvasHeight += imageHeight + 10
        })
        canvas.width = getMaxSize('width')
        canvas.height = canvasHeight
        // draw images on canvas
        images.forEach((image) => {
          const imageHeight = getSize(image, 'height')
          ctx.drawImage(image.img, 0, previousDy)
          previousDy += imageHeight + 5
        })

        if (canvas && canvas.format === 'image/jpeg') {
          return new Promise((resolve, reject) => {
            canvas.toDataURL(
              'image/jpeg',
              {
                quality: 0.92,
                progressive: false
              },
              (err, jpeg) => {
                if (err) {
                  reject(err)
                  return
                }
                resolve(jpeg)
              }
            )
          })
        }
        return canvas.toDataURL('image/png', 0.92)
      })
    )
  })
}

/**
 * Covert a data uri to a blob
 * @param {String} dataURI
 * @return {Blob}
 */
const dataURItoBlob = (dataURI) => {
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], {
    type: mimeString
  })
}

const getColorFromStr = (str) => {
  if (!str || !str.length) {
    return '#A0A0A0'
  }
  let hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase()

  return '#' + '00000'.substring(0, 6 - c.length) + c
}

/**
 * Convert antd table sorter option to number
 * @param {Object} sorter
 * @returns {{orderBy: number | false, sortBy: string}}
 */
const convertTableSorterValue = (sorter) => {
  // Convert special sorter fields
  switch (sorter?.order) {
    case TABLE_SORT_ORDER.ASCEND: {
      return {
        sortBy: sorter.field,
        orderBy: 1
      }
    }
    case TABLE_SORT_ORDER.DESCEND: {
      return {
        sortBy: sorter.field,
        orderBy: -1
      }
    }
    default: {
      return {
        sortBy: sorter.field,
        orderBy: false
      }
    }
  }
}

/**
 * Format query string into object
 * @param {string} queryString - ?xx=xx
 * @return {any}
 */
const extractQueryStringFromUrl = (queryString) => {
  return Qs.parse(queryString, { ignoreQueryPrefix: true })
}
/**
 * Scroll to an element by element id
 * @param {string} id
 */
const scrollToElementById = (id) => {
  try {
    const element = document.getElementById(id)
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      })
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
    return
  }
}

/**
 * Get question object by using path in question set
 * @param {Object} questionSet
 * @param {Array} path
 * @returns {*|{}}
 */
const getQuestionWithPath = ({ questionSet, path }) => {
  const questionsInFirstLevel = questionSet?.[QUESTION_SET_FIELDS.QUESTIONS]
  let result = path?.reduce((acc, cur) => {
    // Search question set
    let tempResult = find(acc, { [QUESTION_SET_FIELDS.NAME]: cur })
    // Search questions under question set
    if (isEmpty(tempResult)) {
      // Search question
      tempResult = find(acc?.[QUESTION_SET_FIELDS.QUESTIONS], {
        [QUESTION_SET_QUESTION_FIELDS.VARIABLE]: cur
      })
      // Search question set
      if (isEmpty(tempResult)) {
        tempResult = find(acc?.[QUESTION_SET_FIELDS.QUESTIONS], {
          [QUESTION_SET_FIELDS.NAME]: cur
        })
      }
    }

    return tempResult
  }, questionsInFirstLevel)

  return result || {}
}

/**
 * Download or view pdf file
 * @param {String} filename
 * @param {Blob} blob - pdf data
 * @param {Boolean} isDownload - whether download or just view
 * @returns {Promise<void>}
 */
const downloadOrViewPdf = ({ filename, blob, isDownload = false }) => {
  if (isDownload) {
    downloadjs(blob, filename, 'application/pdf')
  } else {
    const file = new Blob([blob], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL)
  }
}

export {
  ttl,
  getDuration,
  mergeImages,
  dataURItoBlob,
  getColorFromStr,
  convertTableSorterValue,
  extractQueryStringFromUrl,
  scrollToElementById,
  getQuestionWithPath,
  downloadOrViewPdf
}
