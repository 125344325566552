import React, { useEffect, useState } from 'react'
// import { Breadcrumb } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { matchPath } from 'react-router'
import { useSelector } from 'react-redux'
import { ADMIN_ROUTES, FORM_ROUTES, IMAGE_ROUTES, ROOT_ROUTES } from '@/routes/route-path'
import { REDUCER_STATE } from '@/store/constants'
// import { matchPath, generatePath } from 'react-router'
// import { ROOT_ROUTES, IMAGE_ROUTES, FORM_ROUTES, ADMIN_ROUTES } from '@/routes/route-path'
import {
  AdminTabContent,
  FormTabContent,
  HomeTabContent,
  ImageSpecificTabContent,
  ImageTabContent,
  PatientTrackingTabContent,
  ChartsTabContent
} from '@/components/header'
import { Wrapper } from './style'
import { Breadcrumb } from 'antd'
import PropTypes from 'prop-types'

const BREADCRUMBS = {
  HOME: {
    TITLE: HomeTabContent,
    URL: ROOT_ROUTES.HOME
  },
  FORM: {
    TITLE: FormTabContent,
    URL: ROOT_ROUTES.FORM
  },
  FORM_LIST: {
    TITLE: 'Studies'
  },
  IMAGE: {
    TITLE: ImageTabContent,
    URL: ROOT_ROUTES.IMAGE
  },
  IMAGE_SPECIFIC: {
    TITLE: <ImageSpecificTabContent />,
    URL: ROOT_ROUTES.IMAGE
  },
  PATIENT_TRACKING: {
    TITLE: PatientTrackingTabContent,
    URL: ROOT_ROUTES.PATIENT_TRACKING
  },
  CHARTS: {
    TITLE: ChartsTabContent,
    URL: ROOT_ROUTES.CHARTS
  },
  ADMIN: {
    TITLE: AdminTabContent,
    URL: ROOT_ROUTES.ADMIN
  },
  ADMIN_STUDIES: {
    TITLE: 'Studies',
    URL: ROOT_ROUTES.ADMIN
  },
  NEW_RECORD: {
    TITLE: 'New Record'
  }
}

const useBreadcrumbs = () => {
  const location = useLocation()
  const adminSites = useSelector((state) =>
    state.getIn([REDUCER_STATE.ADMIN.NAME, REDUCER_STATE.ADMIN.FIELDS.SITES.NAME])
  )
  const recordStudy = useSelector((state) =>
    state.getIn([REDUCER_STATE.RECORD.NAME, REDUCER_STATE.RECORD.FIELDS.CURRENT_STUDY])
  )
  const recordSite = useSelector((state) =>
    state.getIn([REDUCER_STATE.RECORD.NAME, REDUCER_STATE.RECORD.FIELDS.CURRENT_SITE])
  )
  const currentRecord = useSelector((state) =>
    state.getIn([REDUCER_STATE.RECORD.NAME, REDUCER_STATE.RECORD.FIELDS.CURRENT_RECORD])
  )

  const { pathname } = location
  const [breadcrumbs, setBreadCrumbs] = useState([])

  useEffect(() => {
    const breadcrumbs = [BREADCRUMBS.HOME]
    if (matchPath(pathname, { path: ROOT_ROUTES.FORM })) {
      breadcrumbs.push(BREADCRUMBS.FORM)
      const matchNewRecord = matchPath(pathname, { path: FORM_ROUTES.NEW_RECORD })
      const matchNewRecordWithStudy = matchPath(pathname, {
        path: FORM_ROUTES.NEW_RECORD_WITH_STUDY_SITE,
        exact: true,
        sensitive: true
      })
      const matchExistRecord = matchPath(pathname, {
        path: FORM_ROUTES.EXIST_RECORD_WITH_STUDY_SITE,
        exact: true,
        sensitive: true
      })
      if (matchNewRecordWithStudy) {
        if (recordStudy) {
          breadcrumbs.push(BREADCRUMBS.FORM_LIST)
          breadcrumbs.push({
            TITLE: recordStudy.studyName
          })
          if (recordSite) {
            breadcrumbs.push({
              TITLE: 'Sites'
            })
            breadcrumbs.push({
              TITLE: recordSite.siteName
            })
          }
        }
        breadcrumbs.push(BREADCRUMBS.NEW_RECORD)
      } else if (matchNewRecord) {
        breadcrumbs.push(BREADCRUMBS.NEW_RECORD)
      } else if (matchExistRecord && currentRecord) {
        if (recordStudy) {
          breadcrumbs.push(BREADCRUMBS.FORM_LIST)
          breadcrumbs.push({
            TITLE: recordStudy.studyName
          })
          if (recordSite) {
            breadcrumbs.push({
              TITLE: 'Sites'
            })
            breadcrumbs.push({
              TITLE: recordSite.siteName
            })
          }
        }
        breadcrumbs.push({
          TITLE: currentRecord.mrn
        })
      }
    } else if (matchPath(pathname, { path: ROOT_ROUTES.IMAGE, exact: true, strict: true })) {
      breadcrumbs.push(BREADCRUMBS.IMAGE)
    } else if (matchPath(pathname, { path: IMAGE_ROUTES.IMAGE, exact: true, strict: true })) {
      breadcrumbs.push(BREADCRUMBS.IMAGE_SPECIFIC)
    } else if (matchPath(pathname, { path: ROOT_ROUTES.PATIENT_TRACKING })) {
      breadcrumbs.push(BREADCRUMBS.PATIENT_TRACKING)
    } else if (matchPath(pathname, { path: ROOT_ROUTES.CHARTS })) {
      breadcrumbs.push(BREADCRUMBS.CHARTS)
    } else if (matchPath(pathname, { path: ROOT_ROUTES.ADMIN })) {
      breadcrumbs.push(BREADCRUMBS.ADMIN)
      const matchStudies = matchPath(pathname, {
        path: ADMIN_ROUTES.STUDIES,
        exact: true,
        sensitive: true
      })
      const matchSitesInStudy = matchPath(pathname, {
        path: ADMIN_ROUTES.SITES_IN_STUDY,
        exact: true,
        sensitive: true
      })
      if (matchStudies) {
        breadcrumbs.push(BREADCRUMBS.ADMIN_STUDIES)
      } else if (matchSitesInStudy && adminSites) {
        if (adminSites.currentStudy) {
          breadcrumbs.push(BREADCRUMBS.ADMIN_STUDIES)
          breadcrumbs.push({ TITLE: adminSites.currentStudy.studyName })
          breadcrumbs.push({ TITLE: 'Sites' })
        }
      }
    }
    setBreadCrumbs(breadcrumbs)
  }, [pathname, adminSites, recordStudy, recordSite, currentRecord])
  return breadcrumbs
}
const Navigation = ({ isVisible = true }) => {
  const breadcrumbs = useBreadcrumbs()
  if (isVisible) {
    return (
      <Wrapper>
        <Breadcrumb>
          {breadcrumbs.map((breadcrumb, idx) => (
            <Breadcrumb.Item key={idx} className='breadcrumb-item'>
              {breadcrumb.URL ? (
                <Link to={breadcrumb.URL}>{breadcrumb.TITLE}</Link>
              ) : (
                breadcrumb.TITLE
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </Wrapper>
    )
  } else {
    return null
  }
}

Navigation.propTypes = {
  isVisible: PropTypes.bool
}

export default Navigation
