import { SHARED_FIELDS } from './shared'

const USER_FIELDS = {
  ...SHARED_FIELDS,
  AVATAR: 'avatar',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ROLE: 'role',
  SITE: 'site',
  STATUS: 'status',
  STATE: 'state',
  ON_CALL: 'onCall',
  STUDY: 'study',
  USERNAME: 'username',
  EMAIL: 'email',
  PASSWORD: 'password',
  MOBILE_PHONE: 'mobilePhone',
  LAST_LOGIN_DATE_TIME: 'lastLoginDateTime',
  NOTIFICATION_SETTINGS: 'notificationSettings',
  DELETED: 'deleted',
  IS_CALLABLE: 'isCallable',
  SIGNATURES: 'signatures'
}

const USER_ENUMS = {
  ROLE: {
    SUPER_ADMIN: 'SUPER_ADMIN',
    STUDY_ADMIN: 'STUDY_ADMIN',
    NEUROLOGIST: 'NEUROLOGIST',
    PARAMEDIC: 'PARAMEDIC',
    RADIOLOGIST: 'RADIOLOGIST',
    SENIOR_NURSE: 'SENIOR_NURSE',
    EMERGENCY_PHYSICIAN: 'EMERGENCY_PHYSICIAN',
    AT_NEUROLOGY: 'AT_NEUROLOGY',
    SCIENTIST: 'SCIENTIST'
  },
  STATUS: { ON_DUTY: 'onDuty', OFF_DUTY: 'offDuty' },
  STATE: {
    PENDING: 'pending',
    VERIFIED: 'verified',
    ENABLED: 'enabled',
    DISABLED: 'disabled',
    PROVISIONAL: 'provisional'
  },
  NOTIFICATION_SETTINGS: {
    CHAT: 'chat',
    HOME: 'home',
    FORM_CREATED: 'formCreated',
    FORM_UPDATED: 'formUpdated',
    IMAGE: 'image',
    CONFERENCE: 'conference',
    TRACKING: 'tracking'
  }
}

export { USER_FIELDS, USER_ENUMS }
