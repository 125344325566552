import Immutable from 'immutable'

import { REDUCER_STATE } from '@/store/constants'
import {
  GET_API_VERSION,
  GET_API_VERSION_FULFILLED,
  GET_API_VERSION_REJECTED,
  GET_GENERAL_INFO,
  GET_GENERAL_INFO_FULFILLED,
  GET_GENERAL_INFO_REJECTED,
  GET_MAPBOX_TOKEN,
  GET_MAPBOX_TOKEN_FULFILLED,
  GET_MAPBOX_TOKEN_REJECTED,
  GET_REGISTER_INFO,
  GET_REGISTER_INFO_FULFILLED,
  GET_REGISTER_INFO_REJECTED
} from '@/store/types'

const {
  GENERAL_INFO: { FIELDS }
} = REDUCER_STATE

const defaultState = {
  [FIELDS.ERROR]: null,
  [FIELDS.IS_PENDING]: false,
  [FIELDS.DATA]: null,
  [FIELDS.IS_GETTING_API_VERSION]: false,
  [FIELDS.API_VERSION]: null,
  [FIELDS.REGISTRATION_INFO]: null,
  [FIELDS.MAPBOX_TOKEN]: null
}
const initialState = new Immutable.Map(defaultState)

export default {
  initialState,
  handlers: {
    [GET_GENERAL_INFO]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_PENDING]: true
      }),
    [GET_GENERAL_INFO_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_PENDING]: false
      }),
    [GET_GENERAL_INFO_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.DATA]: action.payload,
        [FIELDS.IS_PENDING]: false
      }),
    [GET_API_VERSION]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_GETTING_API_VERSION]: true
      }),
    [GET_API_VERSION_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.API_VERSION]: action.payload,
        [FIELDS.IS_GETTING_API_VERSION]: false
      }),
    [GET_API_VERSION_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_GETTING_API_VERSION]: false
      }),

    [GET_REGISTER_INFO]: (state) =>
      state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_PENDING]: true
      }),
    [GET_REGISTER_INFO_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.REGISTRATION_INFO]: action.payload,
        [FIELDS.IS_PENDING]: false
      }),
    [GET_REGISTER_INFO_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.ERROR]: action.payload,
        [FIELDS.IS_PENDING]: false
      }),

    [GET_MAPBOX_TOKEN]: (state) =>
      state.merge({
        [FIELDS.MAPBOX_TOKEN]: null,
        [FIELDS.IS_GETTING_MAPBOX_TOKEN]: true
      }),
    [GET_MAPBOX_TOKEN_FULFILLED]: (state, action) =>
      state.merge({
        [FIELDS.IS_GETTING_MAPBOX_TOKEN]: false,
        [FIELDS.MAPBOX_TOKEN]: action.payload?.mapboxToken || null
      }),
    [GET_MAPBOX_TOKEN_REJECTED]: (state, action) =>
      state.merge({
        [FIELDS.IS_GETTING_MAPBOX_TOKEN]: false,
        [FIELDS.ERROR]: action.payload
      })
  }
}
