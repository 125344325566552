export const GET_CONVERSATION_ACCESS_TOKEN = 'GET_CONVERSATION_ACCESS_TOKEN'
export const GET_CONVERSATION_ACCESS_TOKEN_FULFILLED = 'GET_CONVERSATION_ACCESS_TOKEN_FULFILLED'
export const GET_CONVERSATION_ACCESS_TOKEN_REJECTED = 'GET_CONVERSATION_ACCESS_TOKEN_REJECTED'
export const CLEAR_CONVERSATION_DATA = 'CLEAR_CONVERSATION_DATA'
export const CREATE_GROUP_CONVERSATION = 'CREATE_GROUP_CONVERSATION'
export const CREATE_GROUP_CONVERSATION_FULFILLED = 'CREATE_GROUP_CONVERSATION_FULFILLED'
export const CREATE_GROUP_CONVERSATION_REJECTED = 'CREATE_GROUP_CONVERSATION_REJECTED'
export const CREATE_PRIVATE_MESSAGE = 'CREATE_PRIVATE_MESSAGE'
export const CREATE_PRIVATE_MESSAGE_FULFILLED = 'CREATE_PRIVATE_MESSAGE_FULFILLED'
export const CREATE_PRIVATE_MESSAGE_REJECTED = 'CREATE_PRIVATE_MESSAGE_REJECTED'
export const INVITE_USERS = 'INVITE_USERS'
export const INVITE_USERS_FULFILLED = 'INVITE_USERS_FULFILLED'
export const INVITE_USERS_REJECTED = 'INVITE_USERS_REJECTED'
export const GET_CONVERSATION_USERS = 'GET_CONVERSATION_USERS'
export const GET_CONVERSATION_USERS_FULFILLED = 'GET_CONVERSATION_USERS_FULFILLED'
export const GET_CONVERSATION_USERS_REJECTED = 'GET_CONVERSATION_USERS_REJECTED'
export const SET_IS_CONVERSATION_READY = 'SET_IS_CONVERSATION_READY'
export const GET_PARTICIPANT_RESOURCE = 'GET_PARTICIPANT_RESOURCE'
export const GET_PARTICIPANT_RESOURCE_FULFILLED = 'GET_PARTICIPANT_RESOURCE_FULFILLED'
export const GET_PARTICIPANT_RESOURCE_REJECTED = 'GET_PARTICIPANT_RESOURCE_REJECTED'

export const LEAVE_CONVERSATION = 'LEAVE_CONVERSATION'
export const LEAVE_CONVERSATION_FULFILLED = 'LEAVE_CONVERSATION_FULFILLED'
export const LEAVE_CONVERSATION_REJECTED = 'LEAVE_CONVERSATION_REJECTED'

export const SEND_MESSAGE = 'SEND_MESSAGE'
export const SEND_MESSAGE_FULFILLED = 'SEND_MESSAGE_FULFILLED'
export const SEND_MESSAGE_REJECTED = 'SEND_MESSAGE_REJECTED'

export const SET_PRIVATE_MESSAGE_TARGET_FROM_CONTACTS = 'SET_PRIVATE_MESSAGE_TARGET_FROM_CONTACTS'

export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION'
export const UPDATE_CONVERSATION_FULFILLED = 'UPDATE_CONVERSATION_FULFILLED'
export const UPDATE_CONVERSATION_REJECTED = 'UPDATE_CONVERSATION_REJECTED'

export const UPDATE_PARTICIPANT_RESOURCE = 'UPDATE_PARTICIPANT_RESOURCE'
export const UPDATE_PARTICIPANT_RESOURCE_FULFILLED = 'UPDATE_PARTICIPANT_RESOURCE_FULFILLED'
export const UPDATE_PARTICIPANT_RESOURCE_REJECTED = 'UPDATE_PARTICIPANT_RESOURCE_REJECTED'

// zeus
export const GET_ZEUS_STUDIES = 'GET_ZEUS_STUDIES'
export const GET_ZEUS_STUDIES_FULFILLED = 'GET_ZEUS_STUDIES_FULFILLED'
export const GET_ZEUS_STUDIES_REJECTED = 'GET_ZEUS_STUDIES_REJECTED'

export const GET_ZEUS_SITES = 'GET_ZEUS_SITES'
export const GET_ZEUS_SITES_FULFILLED = 'GET_ZEUS_SITES_FULFILLED'
export const GET_ZEUS_SITES_REJECTED = 'GET_ZEUS_SITES_REJECTED'

export const GET_ZEUS_USERS = 'GET_ZEUS_USERS'
export const GET_ZEUS_USERS_FULFILLED = 'GET_ZEUS_USERS_FULFILLED'
export const GET_ZEUS_USERS_REJECTED = 'GET_ZEUS_USERS_REJECTED'
