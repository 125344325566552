import store, { history } from '@/store'
import authenticateEpics from '@/store/epics/auth'
import { ROOT_ROUTES } from '@/routes/route-path'

const unauthorizedInterceptor = (instance) =>
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorCode = error?.response?.status
      if (errorCode === 401 && history.location.pathname !== ROOT_ROUTES.LOGIN) {
        store.dispatch(authenticateEpics.authError(new Error('Session expired.')))
      }
      return Promise.reject(error)
    }
  )
const extractResponse = (res) => res.data.data

const errorHandler = (err) => {
  if (err.response) {
    const headers = err.response?.headers
    const msg = err.response.data?.error || err.response.data
    const status = err?.response?.status
    const redirect = err?.response?.data?.redirect || null
    const newError = new Error(msg)
    newError.status = status
    newError.redirect = redirect
    if (
      Object.prototype.hasOwnProperty.call(headers, 'x-ratelimit-limit') &&
      Object.prototype.hasOwnProperty.call(headers, 'x-ratelimit-remaining') &&
      Object.prototype.hasOwnProperty.call(headers, 'x-ratelimit-reset')
    ) {
      newError.rateLimiter = {
        limit: headers['x-ratelimit-limit'],
        remaining: headers['x-ratelimit-remaining'],
        resetAt: headers['x-ratelimit-reset']
      }
    }

    throw newError
  } else {
    throw err
  }
}

export { errorHandler, extractResponse, unauthorizedInterceptor }
