// twilio Convo
import {
  CLEAR_CONVERSATION_DATA,
  CREATE_GROUP_CONVERSATION,
  CREATE_GROUP_CONVERSATION_FULFILLED,
  CREATE_GROUP_CONVERSATION_REJECTED,
  CREATE_PRIVATE_MESSAGE,
  CREATE_PRIVATE_MESSAGE_FULFILLED,
  CREATE_PRIVATE_MESSAGE_REJECTED,
  GET_CONVERSATION_ACCESS_TOKEN,
  GET_CONVERSATION_ACCESS_TOKEN_FULFILLED,
  GET_CONVERSATION_ACCESS_TOKEN_REJECTED,
  GET_PARTICIPANT_RESOURCE,
  GET_PARTICIPANT_RESOURCE_FULFILLED,
  GET_PARTICIPANT_RESOURCE_REJECTED,
  GET_ZEUS_SITES,
  GET_ZEUS_SITES_FULFILLED,
  GET_ZEUS_SITES_REJECTED,
  GET_ZEUS_STUDIES,
  GET_ZEUS_STUDIES_FULFILLED,
  GET_ZEUS_STUDIES_REJECTED,
  GET_ZEUS_USERS,
  GET_ZEUS_USERS_FULFILLED,
  GET_ZEUS_USERS_REJECTED,
  INVITE_USERS,
  INVITE_USERS_FULFILLED,
  INVITE_USERS_REJECTED,
  LEAVE_CONVERSATION,
  LEAVE_CONVERSATION_FULFILLED,
  LEAVE_CONVERSATION_REJECTED,
  LOGOUT_FULFILLED,
  SEND_MESSAGE,
  SEND_MESSAGE_FULFILLED,
  SEND_MESSAGE_REJECTED,
  SET_IS_CONVERSATION_READY,
  SET_PRIVATE_MESSAGE_TARGET_FROM_CONTACTS,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_FULFILLED,
  UPDATE_CONVERSATION_REJECTED,
  UPDATE_PARTICIPANT_RESOURCE,
  UPDATE_PARTICIPANT_RESOURCE_FULFILLED,
  UPDATE_PARTICIPANT_RESOURCE_REJECTED
} from '@/store/types'
import { ofType } from 'redux-observable'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { from, iif, of } from 'rxjs'
import {
  createGroupConversation,
  createPrivateConversation,
  getConvoAccessToken,
  getParticipantResource,
  getSites,
  getStudies,
  getStudy,
  getUsers,
  inviteConvoParticipants,
  leaveConversation,
  sendMessage,
  updateConversation,
  updateParticipantResource
} from '@/services/api'

const convoEpics = {
  // get zeus data
  getZeusStudies: (payload) => ({
    type: GET_ZEUS_STUDIES,
    payload
  }),

  getZeusStudiesFulfilled: (payload) => ({
    type: GET_ZEUS_STUDIES_FULFILLED,
    payload
  }),

  getZeusStudiesError: (error) => ({
    type: GET_ZEUS_STUDIES_REJECTED,
    payload: error
  }),

  getZeusStudiesEpic: (action$) =>
    action$.pipe(
      ofType(GET_ZEUS_STUDIES),
      mergeMap((action) =>
        iif(
          () => action.payload.canManageStudiesUsers,
          of(action.payload.populate).pipe(
            mergeMap((payload) =>
              from(getStudies(payload)).pipe(
                map(convoEpics.getZeusStudiesFulfilled),
                catchError((error) => of(convoEpics.getZeusStudiesError(error)))
              )
            )
          ),
          of(action.payload).pipe(
            mergeMap((payload) =>
              from(getStudy(payload.id, payload.populate)).pipe(
                map((payload) => convoEpics.getZeusStudiesFulfilled([payload])),
                catchError((error) => of(convoEpics.getZeusStudiesError(error)))
              )
            )
          )
        )
      )
    ),

  getZeusSites: (payload) => ({
    type: GET_ZEUS_SITES,
    payload
  }),

  getZeusSitesFulfilled: (payload) => ({
    type: GET_ZEUS_SITES_FULFILLED,
    payload
  }),

  getZeusSitesError: (error) => ({
    type: GET_ZEUS_SITES_REJECTED,
    payload: error
  }),

  getZeusSitesEpic: (action$) =>
    action$.pipe(
      ofType(GET_ZEUS_SITES),
      mergeMap((action) =>
        from(getSites(action.payload)).pipe(
          map(convoEpics.getZeusSitesFulfilled),
          catchError((error) => of(convoEpics.getZeusSitesError(error)))
        )
      )
    ),

  getZeusUsers: (query) => ({
    type: GET_ZEUS_USERS,
    payload: query
  }),

  getZeusUsersFulfilled: (payload) => ({
    type: GET_ZEUS_USERS_FULFILLED,
    payload
  }),

  getZeusUsersError: (error) => ({
    type: GET_ZEUS_USERS_REJECTED,
    payload: error
  }),

  getZeusUsersEpic: (action$) =>
    action$.pipe(
      ofType(GET_ZEUS_USERS),
      mergeMap((action) =>
        from(getUsers(action.payload)).pipe(
          map((user) => convoEpics.getZeusUsersFulfilled(user)),
          catchError((error) => of(convoEpics.getZeusUsersError(error)))
        )
      )
    ),

  // Twilio Conversation
  clearConvoData: () => ({
    type: CLEAR_CONVERSATION_DATA
  }),

  clearConvoDataEpic: (action$) =>
    action$.pipe(ofType(LOGOUT_FULFILLED), map(convoEpics.clearConvoData)),

  createGroupConversation: ({ friendlyName }) => ({
    type: CREATE_GROUP_CONVERSATION,
    payload: { friendlyName }
  }),

  createGroupConversationFulfilled: (payload) => ({
    type: CREATE_GROUP_CONVERSATION_FULFILLED,
    payload
  }),

  createGroupConversationError: (error) => ({
    type: CREATE_GROUP_CONVERSATION_REJECTED,
    payload: error
  }),

  createGroupConversationEpic: (action$) =>
    action$.pipe(
      ofType(CREATE_GROUP_CONVERSATION),
      mergeMap((action) =>
        from(createGroupConversation(action.payload)).pipe(
          map((payload) => convoEpics.createGroupConversationFulfilled(payload)),
          catchError((error) => of(convoEpics.createGroupConversationError(error)))
        )
      )
    ),

  createPrivateMessage: (payload) => ({
    type: CREATE_PRIVATE_MESSAGE,
    payload
  }),

  createPrivateMessageFulfilled: (payload) => ({
    type: CREATE_PRIVATE_MESSAGE_FULFILLED,
    payload
  }),

  createPrivateMessageError: (error) => ({
    type: CREATE_PRIVATE_MESSAGE_REJECTED,
    payload: error
  }),

  createPrivateMessageEpic: (action$) =>
    action$.pipe(
      ofType(CREATE_PRIVATE_MESSAGE),
      mergeMap((action) =>
        from(createPrivateConversation(action.payload)).pipe(
          map(convoEpics.createPrivateMessageFulfilled),
          catchError((error) => of(convoEpics.createPrivateMessageError(error)))
        )
      )
    ),

  leaveConversation: ({ conversationSid, participantSid }) => ({
    type: LEAVE_CONVERSATION,
    payload: { conversationSid, participantSid }
  }),

  leaveConversationFulfilled: (payload) => ({
    type: LEAVE_CONVERSATION_FULFILLED,
    payload
  }),

  leaveConversationError: (error) => ({
    type: LEAVE_CONVERSATION_REJECTED,
    payload: error
  }),

  leaveConversationEpic: (action$) =>
    action$.pipe(
      ofType(LEAVE_CONVERSATION),
      mergeMap((action) =>
        from(leaveConversation(action.payload)).pipe(
          map(convoEpics.leaveConversationFulfilled),
          catchError((error) => of(convoEpics.leaveConversationError(error)))
        )
      )
    ),

  getConvoAccessToken: (payload) => ({
    type: GET_CONVERSATION_ACCESS_TOKEN,
    payload
  }),

  getConvoAccessTokenFulfilled: (payload) => ({
    type: GET_CONVERSATION_ACCESS_TOKEN_FULFILLED,
    payload
  }),

  getConvoAccessTokenError: (error) => ({
    type: GET_CONVERSATION_ACCESS_TOKEN_REJECTED,
    payload: error
  }),

  getConvoAccessTokenEpic: (action$) =>
    action$.pipe(
      ofType(GET_CONVERSATION_ACCESS_TOKEN),
      mergeMap((action) =>
        from(getConvoAccessToken()).pipe(
          map((payload) =>
            convoEpics.getConvoAccessTokenFulfilled({ payload, isUpdateToken: action.payload })
          ),
          catchError((error) => of(convoEpics.getConvoAccessTokenError(error)))
        )
      )
    ),

  inviteUsers: ({ convoSid, inviteUsers }) => ({
    type: INVITE_USERS,
    payload: { convoSid, inviteUsers }
  }),

  inviteUsersFulfilled: (payload) => ({
    type: INVITE_USERS_FULFILLED,
    payload
  }),

  inviteUsersError: (error) => ({
    type: INVITE_USERS_REJECTED,
    payload: error
  }),

  inviteUsersEpic: (action$) =>
    action$.pipe(
      ofType(INVITE_USERS),
      mergeMap((action) =>
        from(inviteConvoParticipants(action.payload)).pipe(
          map((user) => convoEpics.inviteUsersFulfilled(user)),
          catchError((error) => of(convoEpics.inviteUsersError(error)))
        )
      )
    ),

  getParticipantResource: ({ conversationSid, participantSid }) => ({
    type: GET_PARTICIPANT_RESOURCE,
    payload: { conversationSid, participantSid }
  }),

  getParticipantResourceFulfilled: (payload) => ({
    type: GET_PARTICIPANT_RESOURCE_FULFILLED,
    payload
  }),

  getParticipantResourceError: (error) => ({
    type: GET_PARTICIPANT_RESOURCE_REJECTED,
    payload: error
  }),

  getParticipantResourceEpic: (action$) =>
    action$.pipe(
      ofType(GET_PARTICIPANT_RESOURCE),
      mergeMap((action) =>
        from(getParticipantResource(action.payload)).pipe(
          map((user) => convoEpics.getParticipantResourceFulfilled(user)),
          catchError((error) => of(convoEpics.getParticipantResourceError(error)))
        )
      )
    ),

  setPrivateMessageTargetFromContacts: (payload) => ({
    type: SET_PRIVATE_MESSAGE_TARGET_FROM_CONTACTS,
    payload
  }),

  // send message
  sendMessage: ({ conversationSid, message }) => ({
    type: SEND_MESSAGE,
    payload: { conversationSid, message }
  }),

  sendMessageFulfilled: (payload) => ({
    type: SEND_MESSAGE_FULFILLED,
    payload
  }),

  sendMessageError: (error) => ({
    type: SEND_MESSAGE_REJECTED,
    payload: error
  }),

  sendMessageEpic: (action$) =>
    action$.pipe(
      ofType(SEND_MESSAGE),
      mergeMap((action) =>
        from(sendMessage(action.payload)).pipe(
          map((user) => convoEpics.sendMessageFulfilled(user)),
          catchError((error) => of(convoEpics.sendMessageError(error)))
        )
      )
    ),

  setIsConversationReady: (payload) => ({
    type: SET_IS_CONVERSATION_READY,
    payload
  }),

  updateParticipantResource: ({
    conversationSid,
    participantIdentity,
    lastReadMessageIndex,
    lastReadTimestamp
  }) => ({
    type: UPDATE_PARTICIPANT_RESOURCE,
    payload: { conversationSid, participantIdentity, lastReadMessageIndex, lastReadTimestamp }
  }),

  updateParticipantResourceFulfilled: (payload) => ({
    type: UPDATE_PARTICIPANT_RESOURCE_FULFILLED,
    payload
  }),

  updateParticipantResourceError: (error) => ({
    type: UPDATE_PARTICIPANT_RESOURCE_REJECTED,
    payload: error
  }),

  updateParticipantResourceEpic: (action$) =>
    action$.pipe(
      ofType(UPDATE_PARTICIPANT_RESOURCE),
      mergeMap((action) =>
        from(updateParticipantResource(action.payload)).pipe(
          map((user) => convoEpics.updateParticipantResourceFulfilled(user)),
          catchError((error) => of(convoEpics.updateParticipantResourceError(error)))
        )
      )
    ),

  updateConversation: ({ conversationSid, friendlyName }) => ({
    type: UPDATE_CONVERSATION,
    payload: { conversationSid, friendlyName }
  }),

  updateConversationFulfilled: (payload) => ({
    type: UPDATE_CONVERSATION_FULFILLED,
    payload
  }),

  updateConversationError: (error) => ({
    type: UPDATE_CONVERSATION_REJECTED,
    payload: error
  }),

  updateConversationEpic: (action$) =>
    action$.pipe(
      ofType(UPDATE_CONVERSATION),
      mergeMap((action) =>
        from(updateConversation(action.payload)).pipe(
          map((user) => convoEpics.updateConversationFulfilled(user)),
          catchError((error) => of(convoEpics.updateConversationError(error)))
        )
      )
    )
}

export default convoEpics
