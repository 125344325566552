import { ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { getContacts } from '@/services/api'
import {
  GET_CONTACTS,
  GET_CONTACTS_FULFILLED,
  GET_CONTACTS_REJECTED,
  UPDATE_CONTACTS
} from '@/store/types'

const contactEpics = {
  getContacts: ({ studyAdminRole, superAdminRole, currentUserId }) => ({
    type: GET_CONTACTS,
    payload: { studyAdminRole, superAdminRole, currentUserId }
  }),

  getContactsFulfilled: (payload) => ({
    type: GET_CONTACTS_FULFILLED,
    payload
  }),

  getContactsError: (error) => ({
    type: GET_CONTACTS_REJECTED,
    payload: error
  }),

  getContactsEpic: (action$) =>
    action$.pipe(
      ofType(GET_CONTACTS),
      mergeMap((action) =>
        from(getContacts(action.payload)).pipe(
          map((user) => contactEpics.getContactsFulfilled(user)),
          catchError((error) => of(contactEpics.getContactsError(error)))
        )
      )
    ),

  updateContacts: (user) => ({
    type: UPDATE_CONTACTS,
    payload: user
  })
}

export default contactEpics
