import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'

const SimpleAvatarWrapper = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #e0e0e0;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: ${(props) => (props.isOnCall ? '3px solid #00aaff' : '3px solid white')};
  }
`

const AvatarWrapper = styled.div`
  display: block;
  padding: 0;
  margin: 5px 0;
  position: relative;
  width: 80px;
`
const AvatarBody = styled.div`
  display: block;
  padding: 0;
  position: relative;
  width: 80px;
  height: 80px;
  cursor: pointer;
  border-width: 6px;
  box-sizing: border-box;
  border-color: ${(props) => (props.isOnCall ? '#00aaff' : 'white')};
  border-radius: 50%;
  border-style: solid;
`

const AvatarImage = styled.div`
  background-image: url('${(props) => props.url}');
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: transparent;
`

const SimpleAction = styled.div`
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: none; // 2px solid #c0c0c0;
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.4);
  background-color: rgba(255, 255, 255, 0.9);

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 8px rgba(100, 100, 100, 0.8);
  }

  svg {
    position: absolute;
    width: 70%;
    height: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const SimpleStatusAction = styled(SimpleAction)`
  left: -3px;
  bottom: -3px;
`

const Action = styled.div`
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none; // 2px solid #c0c0c0;
  box-shadow: 0 0 4px rgba(100, 100, 100, 0.4);
  background-color: rgba(255, 255, 255, 0.9);

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 8px rgba(100, 100, 100, 0.8);
  }

  svg {
    position: absolute;
    width: 70%;
    height: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const TextAction = styled(Action)`
  right: -15px;
  top: 10px;
`

const PhoneCallAction = styled(Action)`
  right: 18px;
  top: -15px;
`

const VideoAction = styled(Action)`
  left: -15px;
  top: 10px;
`

const StatusAction = styled(Action)`
  left: -5px;
  bottom: -5px;
`

const OnCallAction = styled(Action)`
  right: -5px;
  bottom: -5px;
`

const Name = styled.div`
  width: 100%;
  font-weight: 700;
  margin-top: 2px;
  text-align: center;
  color: #666666;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Role = styled.div`
  width: 100%;
  font-weight: 400;
  text-align: center;
  color: #999999;
  line-height: 12px;
  font-size: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const LoadingIcon = styled(LoadingOutlined)`
  width: 100%;
  height: 100%;
`
export {
  Action,
  SimpleAction,
  AvatarWrapper,
  AvatarBody,
  AvatarImage,
  LoadingIcon,
  Name,
  Role,
  SimpleAvatarWrapper,
  StatusAction,
  SimpleStatusAction,
  TextAction,
  VideoAction,
  OnCallAction,
  PhoneCallAction
}
