import { REDUCER_STATE } from '@/store/constants'
import {
  GET_TWILIO_VOICE_ACCESS_TOKEN,
  GET_TWILIO_VOICE_ACCESS_TOKEN_FULFILLED,
  GET_TWILIO_VOICE_ACCESS_TOKEN_REJECTED,
  SET_TWILIO_PHONE_CALL_USER
} from '@/store/types'
import Immutable from 'immutable'

const FIELDS = REDUCER_STATE.VOICE.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.ACCESS_TOKEN]: null,
  [FIELDS.IS_GETTING_ACCESS_TOKEN]: false,
  [FIELDS.ERROR]: null,
  [FIELDS.USER]: null
})

const handlers = {
  [GET_TWILIO_VOICE_ACCESS_TOKEN]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ACCESS_TOKEN]: true,
      [FIELDS.ACCESS_TOKEN]: null
    }),
  [GET_TWILIO_VOICE_ACCESS_TOKEN_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ACCESS_TOKEN]: false,
      [FIELDS.ACCESS_TOKEN]: action.payload
    }),
  [GET_TWILIO_VOICE_ACCESS_TOKEN_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ACCESS_TOKEN]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [SET_TWILIO_PHONE_CALL_USER]: (state, action) =>
    state.merge({
      [FIELDS.USER]: action.payload
    })
}

export default {
  initialState,
  handlers
}
