import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { Badge, Popover } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { FieldTimeOutlined, InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons'
import { ChartAreaLine, Home, Maximize, Notes, Refresh, Users } from 'tabler-icons-react'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '@/components/logo'
import authEpics from '@/store/epics/auth'
import notificationEpics from '@/store/epics/notification'
import styles from './style/index.module.less'
import { Action, Actions, HeaderWrapper, TabHeader, Tabs } from './style'
import Versions from '@/components/versions'
import { NOTIFICATION_TYPES } from '@/services/constants'
import { REDUCER_STATE } from '@/store/constants'
import { ROOT_ROUTES } from '@/routes/route-path'
import { epic$ } from '@/store/epics'
import { getUserPermission } from '@/libs/functions/auth'
import { AUTHORIZATIONS, PERMISSION_KEYS } from '@/constants/authorization'
import { STUDY_FIELDS, USER_FIELDS } from '@/constants/api-data'

export const MENU = {
  HOME: 'HOME',
  FORM: 'FORM',
  IMAGE: 'IMAGE',
  ADMIN: 'ADMIN',
  PATIENT_TRACKING: 'PATIENT_TRACKING',
  CHARTS: 'CHARTS'
}

const TAB_DISPLAY_NAME = {
  HOME: 'Home',
  FORM: 'Form',
  IMAGE: 'Images',
  PATIENT_TRACKING: 'Tracking',
  CHARTS: 'Charts',
  ADMIN: 'Admin'
}

export const HomeTabContent = (
  <>
    <Home />
    <span className='label'>{TAB_DISPLAY_NAME.HOME}</span>
  </>
)

export const FormTabContent = (
  <>
    <Notes />
    <span className='label'>{TAB_DISPLAY_NAME.FORM}</span>
  </>
)
export const ImageTabContent = (
  <>
    <Maximize />
    <span className='label'>{TAB_DISPLAY_NAME.IMAGE}</span>
  </>
)
export const ImageSpecificTabContent = () => {
  const history = useHistory()
  /**
   * Handle refresh
   */
  const handleClickRefresh = () => {
    history.go(0)
  }

  return (
    <>
      <Maximize />
      <span className='label'>
        {TAB_DISPLAY_NAME.IMAGE}{' '}
        <span onClick={handleClickRefresh} style={{ marginLeft: '15px' }}>
          <Refresh color={'#70939f'} />
        </span>
      </span>
    </>
  )
}
export const AdminTabContent = (
  <>
    <Users />
    <span className='label'>{TAB_DISPLAY_NAME.ADMIN}</span>
  </>
)

export const ChartsTabContent = (
  <>
    <ChartAreaLine />
    <span className='label'>{TAB_DISPLAY_NAME.CHARTS}</span>
  </>
)

export const PatientTrackingTabContent = (
  <>
    <FieldTimeOutlined />
    <span className='label'>{TAB_DISPLAY_NAME.PATIENT_TRACKING}</span>
  </>
)

// Add active class to the current tab header
const className = (active, tabName) => (active === tabName ? 'active' : '')

const BANDAGE_STYLE = {
  OFF_SET: [-25, 5],
  STYLE: { zIndex: 201 }
}

/**
 * Common header component
 */
const Header = (props) => {
  const { active } = props
  const dispatch = useDispatch()

  const currentUser = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.USER])
  )
  const roles = useSelector((state) =>
    state.getIn([REDUCER_STATE.GENERAL_INFO.NAME, REDUCER_STATE.GENERAL_INFO.FIELDS.DATA])
  )?.roles
  const newNotificationIds = useSelector((state) =>
    state.getIn([
      REDUCER_STATE.NOTIFICATION.NAME,
      REDUCER_STATE.NOTIFICATION.FIELDS.NEW_NOTIFICATION_IDS
    ])
  )
  const newRecordNotificationCounts = get(
    newNotificationIds,
    [NOTIFICATION_TYPES.RECORD_CREATED],
    []
  )?.length
  const newImageNotificationCounts = get(
    newNotificationIds,
    [NOTIFICATION_TYPES.IMAGE_CREATED],
    []
  )?.length
  const newIncidentNotificationCounts = get(
    newNotificationIds,
    [NOTIFICATION_TYPES.MANUAL_TRIGGERED],
    []
  )?.length
  const newArrivedTrackingNotificationCounts = get(
    newNotificationIds,
    [NOTIFICATION_TYPES.TRACK_ARRIVED],
    []
  )?.length

  const trackingEnabled = !!currentUser?.[USER_FIELDS.STUDY]?.[STUDY_FIELDS.TRACKING_ENABLED]
  const mongoChartEnable = !!currentUser?.[USER_FIELDS.STUDY]?.[STUDY_FIELDS.CHART_ENABLED]
  const mongoChartVisible =
    !!currentUser?.[USER_FIELDS.STUDY]?.[STUDY_FIELDS.CHART_USER_VISIBLE_ENABLED]
  const userPermission = getUserPermission(currentUser, roles)
  const chartTabEnable = false

  /**
   * Register reducer
   */
  useEffect(() => {
    epic$.next(authEpics.logoutEpic)
  }, [])

  /**
   * Only allow logout after chat notification binding resource deleted
   * @type {(function(): Promise<void>)|*}
   */
  const onLogout = useCallback(async () => {
    dispatch(authEpics.logout())
  }, [dispatch, authEpics])

  return (
    <HeaderWrapper>
      <Link to={ROOT_ROUTES.HOME}>
        <Logo className='logo' color={'white'} stack={true} />
      </Link>
      <Tabs>
        <Badge
          count={newIncidentNotificationCounts}
          offset={BANDAGE_STYLE.OFF_SET}
          style={BANDAGE_STYLE.STYLE}
        >
          <TabHeader
            className={`${className(active, MENU.HOME)} home`}
            onClick={() =>
              dispatch(
                notificationEpics.clearNewNotificationIds(NOTIFICATION_TYPES.MANUAL_TRIGGERED)
              )
            }
          >
            <Link to={ROOT_ROUTES.HOME}>{HomeTabContent}</Link>
          </TabHeader>
        </Badge>
        {userPermission.CAN_VIEW_FORM && (
          <Badge
            count={newRecordNotificationCounts}
            offset={BANDAGE_STYLE.OFF_SET}
            style={BANDAGE_STYLE.STYLE}
          >
            <TabHeader
              className={className(active, MENU.FORM)}
              onClick={() =>
                dispatch(
                  notificationEpics.clearNewNotificationIds(NOTIFICATION_TYPES.RECORD_CREATED)
                )
              }
            >
              <Link to={ROOT_ROUTES.FORM}>{FormTabContent}</Link>
            </TabHeader>
          </Badge>
        )}
        <Badge
          count={newImageNotificationCounts}
          offset={BANDAGE_STYLE.OFF_SET}
          style={BANDAGE_STYLE.STYLE}
        >
          <TabHeader
            className={className(active, MENU.IMAGE)}
            onClick={() =>
              dispatch(notificationEpics.clearNewNotificationIds(NOTIFICATION_TYPES.IMAGE_CREATED))
            }
          >
            <Link to={ROOT_ROUTES.IMAGE}>{ImageTabContent}</Link>
          </TabHeader>
        </Badge>
        {userPermission?.[PERMISSION_KEYS.CAN_TRACK_PATIENT] &&
          (trackingEnabled || userPermission === AUTHORIZATIONS.SUPER_ADMIN) && (
            <Badge
              count={newArrivedTrackingNotificationCounts}
              offset={BANDAGE_STYLE.OFF_SET}
              style={BANDAGE_STYLE.STYLE}
            >
              <TabHeader
                className={`${className(active, MENU.PATIENT_TRACKING)}`}
                onClick={() => {
                  dispatch(
                    notificationEpics.clearNewNotificationIds(NOTIFICATION_TYPES.TRACK_ARRIVED)
                  )
                }}
              >
                <Link to={ROOT_ROUTES.PATIENT_TRACKING}>{PatientTrackingTabContent}</Link>
              </TabHeader>
            </Badge>
          )}
        {chartTabEnable && mongoChartEnable && mongoChartVisible && (
          <TabHeader className={`${className(active, MENU.CHARTS)}`}>
            <Link to={ROOT_ROUTES.CHARTS}>{ChartsTabContent}</Link>
          </TabHeader>
        )}
        {userPermission?.[PERMISSION_KEYS.CAN_MANAGE_USERS] && (
          <TabHeader className={`${className(active, MENU.ADMIN)} admin`}>
            <Link to={ROOT_ROUTES.ADMIN}>{AdminTabContent}</Link>
          </TabHeader>
        )}
      </Tabs>
      <Actions direction='vertical'>
        <Action>
          <Popover
            content={<Versions />}
            placement='left'
            overlayClassName={styles['header__popover']}
          >
            <InfoCircleOutlined />
          </Popover>
        </Action>
        <Action>
          <Popover content='Logout' placement='left' overlayClassName={styles['header__popover']}>
            <LogoutOutlined onClick={onLogout} />
          </Popover>
        </Action>
      </Actions>
    </HeaderWrapper>
  )
}

Header.propTypes = {
  active: PropTypes.string
}

export default Header
