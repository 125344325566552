const COMMON_FIELDS = {
  SID: 'sid',
  ATTRIBUTES: 'attributes'
}

const CONVERSATION_SDK_FIELDS = {
  ...COMMON_FIELDS,
  CONVERSATION: 'conversation',
  LAST_MESSAGE: 'lastMessage',
  STATUS: 'status',
  LAST_CONSUMED_MESSAGE_INDEX: 'lastConsumedMessageIndex',
  IDENTITY: 'identity',
  MESSAGES_ENTITY: '_messagesEntity',
  NOTIFICATION_LEVEL: 'notificationLevel',
  FRIENDLY_NAME: 'friendlyName',
  UNIQUE_NAME: 'uniqueName'
}

const CONVERSATION_SDK_ENUMS = {
  STATUS: {
    JOINED: 'joined'
  },
  NOTIFICATION_LEVEL: {
    DEFAULT: 'default',
    MUTED: 'muted'
  }
}

const CONVERSATION_SDK_ATTRIBUTES_FIELDS = {
  CONVERSATION_MODE: 'conversationMode',
  CREATED_BY: 'createdBy'
}

const CONVERSATION_SDK_MESSAGE_FIELDS = {
  ...COMMON_FIELDS,
  INDEX: 'index',
  AUTHOR: 'author',
  CONVERSATION: 'conversation',
  TYPE: 'type',
  MEDIA: 'media'
}

const CONVERSATION_SDK_MESSAGE_ATTRIBUTES_FIELDS = {
  TEXT_BODY: 'textBody'
}

const CONVERSATION_SDK_MESSAGE_ENUMS = {
  AUTHOR: {
    SYSTEM: 'ZEUS_CHAT_SYSTEM'
  },
  TYPE: {
    MEDIA: 'media',
    TEXT: 'text'
  }
}

const CONVERSATION_SDK_MEMBER_FIELDS = {
  ...COMMON_FIELDS,
  IDENTITY: 'identity',
  CONVERSATION: 'conversation'
}

const CONVERSATION_SDK_PARTICIPANT_FIELDS = {
  ...COMMON_FIELDS,
  LAST_READ_MESSAGE_INDEX: 'lastReadMessageIndex'
}

export {
  CONVERSATION_SDK_FIELDS,
  CONVERSATION_SDK_ENUMS,
  CONVERSATION_SDK_ATTRIBUTES_FIELDS,
  CONVERSATION_SDK_MESSAGE_FIELDS,
  CONVERSATION_SDK_MESSAGE_ENUMS,
  CONVERSATION_SDK_MEMBER_FIELDS,
  CONVERSATION_SDK_PARTICIPANT_FIELDS,
  CONVERSATION_SDK_MESSAGE_ATTRIBUTES_FIELDS
}
