import API from '../client'
import ENDPOINTS from '../endpoints'
import { errorHandler, extractResponse } from '../utils'

/**
 * Create user
 * @param {object} options
 * @param {String} options.username
 * @param {String} options.email
 * @param {String} options.lastName
 * @param {String} options.firstName
 * @param {String} options.mobilePhone
 * @param {String} options.role    - role string
 * @param {String} options.site    - site id
 * @param {String} options.study   - study id
 * @return {Promise<*>}
 */
const createUser = async ({
  email,
  firstName,
  lastName,
  mobilePhone,
  role,
  site,
  study,
  username
}) => {
  const body = {
    email,
    firstName,
    lastName,
    mobilePhone,
    role,
    site,
    study,
    username
  }
  try {
    const response = await API.post(ENDPOINTS.USERS, body)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Create user
 * @param {Array} users - list of user objects
 * @return {Promise<*>}
 */
const createUsers = async (users) => {
  const data = { users }
  const params = {
    bulk: true
  }
  try {
    return extractResponse(
      await API.post(ENDPOINTS.USERS, data, {
        params
      })
    )
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Delete user from server
 * @param {String} id - user id needs to be deleted
 * @return {Promise<void>}
 */
const deleteUser = async (id) => {
  try {
    await API.delete(ENDPOINTS.USER({ id }))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get users from server
 * @param {object} [options]
 * @param {string} [options.siteId]    - site id
 * @param {string} [options.studyId]   - study id
 * @param {string} [options.skip]    - pagination skip
 * @param {string} [options.limit]   - pagination limit
 * @param {string} [options.state]   - user state pending|verified|enabled|disabled
 * @return {Promise<*>}
 */
const getUsers = async (options) => {
  try {
    const params = {}
    if (options) {
      if (options.siteId) {
        params.site = options.siteId
      }
      if (options.studyId) {
        params.study = options.studyId
      }
      if (options.skip !== undefined) {
        params.skip = options.skip
      }
      if (options.limit) {
        params.limit = options.limit
      }
      if (options.sortBy) {
        params.sortBy = options.sortBy
      }
      if (options.orderBy) {
        params.orderBy = options.orderBy
      }
      if (options.state) {
        params.state = options.state
      }
    }
    const response = await API.get(ENDPOINTS.USERS, { params })
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update user
 * @param {Object} data - user data that needs to be updated
 * @return {Promise<*>}
 */
const updateUser = async (data) => {
  const { id } = data
  delete data.id
  try {
    const response = await API.put(ENDPOINTS.USER({ id }), data)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Handle enable or disable user
 * @param {string} id - user id
 * @param {Boolean} isEnabled - enable or disable
 * @return {Promise<*>}
 */
const handleEnableOrDisableUser = async ({ id, isEnabled }) => {
  try {
    const response = await API.put(ENDPOINTS.ENABLE_OR_DISABLE_USER({ id }), { isEnabled })
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Handle reset user login limiter
 * @param {string} id - user id
 * @return {Promise<*>}
 */
const handleResetUserLoginLimiter = async (id) => {
  try {
    const response = await API.put(ENDPOINTS.RESET_USER_LIMITER({ id }))
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

export {
  createUser,
  createUsers,
  deleteUser,
  getUsers,
  updateUser,
  handleEnableOrDisableUser,
  handleResetUserLoginLimiter
}
