// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fqjNJsf2SyTn6NjC0dYc {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  padding: 0;
  margin-bottom: 12px;
  font-size: 16px;
}
.fqjNJsf2SyTn6NjC0dYc .HpQPzdtdSfAoWTnbEu9e {
  flex: 0 0 auto;
  width: 42px;
  height: 42px;
  margin-right: 12px;
}
.fqjNJsf2SyTn6NjC0dYc .E5LbmFjw2HhSBtqvzUXV {
  flex: 1 1 auto;
}
.fqjNJsf2SyTn6NjC0dYc .E5LbmFjw2HhSBtqvzUXV .SFk22DMn2ip6F1SWvedf {
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/components/event/style/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,UAAA;EACA,mBAAA;EACA,eAAA;AACF;AARA;EAUI,cAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;AAdA;EAiBI,cAAA;AAAJ;AAjBA;EAoBM,gBAAA;AAAN","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n  align-content: stretch;\n  padding: 0;\n  margin-bottom: 12px;\n  font-size: 16px;\n\n  .avatar {\n    flex: 0 0 auto;\n    width: 42px;\n    height: 42px;\n    margin-right: 12px;\n  }\n\n  .detail {\n    flex: 1 1 auto;\n\n    .description {\n      font-weight: 400;\n    }\n  }\n}\n@primary-color: #4888d0;@primary-color-light: #40a9ff;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `fqjNJsf2SyTn6NjC0dYc`,
	"avatar": `HpQPzdtdSfAoWTnbEu9e`,
	"detail": `E5LbmFjw2HhSBtqvzUXV`,
	"description": `SFk22DMn2ip6F1SWvedf`
};
export default ___CSS_LOADER_EXPORT___;
