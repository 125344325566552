import { REDUCER_STATE } from '@/store/constants'
import {
  CAMERA_GOTO_HOME_POSITION,
  CAMERA_GOTO_HOME_POSITION_FULFILLED,
  CAMERA_GOTO_HOME_POSITION_REJECTED,
  CAMERA_SET_HOME_POSITION,
  CAMERA_SET_HOME_POSITION_FULFILLED,
  CAMERA_SET_HOME_POSITION_REJECTED,
  GET_CAMERA_STATUS,
  GET_CAMERA_STATUS_FULFILLED,
  GET_CAMERA_STATUS_REJECTED,
  GET_LIVE_CAMERA_STUDIES,
  GET_LIVE_CAMERA_STUDIES_FULFILLED,
  GET_LIVE_CAMERA_STUDIES_REJECTED,
  OPERATE_CAMERA,
  OPERATE_CAMERA_FULFILLED,
  OPERATE_CAMERA_REJECTED
} from '@/store/types'
import Immutable from 'immutable'

const FIELDS = REDUCER_STATE.LIVE_CAMERA.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.STUDIES]: null,
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING_STUDIES]: false,
  [FIELDS.IS_OPERATING_CAMERA]: false,
  [FIELDS.IS_GETTING_CAMERA]: false,
  [FIELDS.CURRENT_STATUS]: null
})

const handlers = {
  [GET_LIVE_CAMERA_STUDIES]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_STUDIES]: true,
      [FIELDS.STUDIES]: []
    }),
  [GET_LIVE_CAMERA_STUDIES_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_STUDIES]: false,
      [FIELDS.STUDIES]: action.payload
    }),
  [GET_LIVE_CAMERA_STUDIES_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_STUDIES]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [GET_CAMERA_STATUS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_CAMERA]: true,
      [FIELDS.CURRENT_STATUS]: null
    }),
  [GET_CAMERA_STATUS_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_CAMERA]: false,
      [FIELDS.CURRENT_STATUS]: action.payload
    }),
  [GET_CAMERA_STATUS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_CAMERA]: false
    }),

  [OPERATE_CAMERA]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_OPERATING_CAMERA]: true,
      [FIELDS.CURRENT_STATUS]: null
    }),
  [OPERATE_CAMERA_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_OPERATING_CAMERA]: false,
      [FIELDS.CURRENT_STATUS]: action.payload
    }),
  [OPERATE_CAMERA_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_OPERATING_CAMERA]: false
    }),

  [CAMERA_SET_HOME_POSITION]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_OPERATING_CAMERA]: true
    }),
  [CAMERA_SET_HOME_POSITION_FULFILLED]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_OPERATING_CAMERA]: false
    }),
  [CAMERA_SET_HOME_POSITION_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_OPERATING_CAMERA]: false
    }),

  [CAMERA_GOTO_HOME_POSITION]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_OPERATING_CAMERA]: true
    }),
  [CAMERA_GOTO_HOME_POSITION_FULFILLED]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_OPERATING_CAMERA]: false
    }),
  [CAMERA_GOTO_HOME_POSITION_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_OPERATING_CAMERA]: false
    })
}

export default {
  initialState,
  handlers
}
