import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { PublicLayoutWrapper } from '@/styles/layout'

const PublicRoute = (props) => {
  const { component: Component, ...otherProps } = props

  return (
    <PublicLayoutWrapper>
      <Route {...otherProps} render={(props) => <Component {...props} />} />
    </PublicLayoutWrapper>
  )
}

PublicRoute.propTypes = {
  component: PropTypes.object.isRequired
}

export default PublicRoute
