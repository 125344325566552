import { PAUSE_AUDIO, PLAY_AUDIO } from '@/store/types/audio'

const audioEpics = {
  playAudio: (payload) => ({
    type: PLAY_AUDIO,
    payload
  }),

  pauseAudio: () => ({
    type: PAUSE_AUDIO
  })
}

export default audioEpics
