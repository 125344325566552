import React, { useEffect, useRef } from 'react'
import { getApps, initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { notification as NotificationComponent } from 'antd'
import { generatePath } from 'react-router'
import PropTypes from 'prop-types'
import { Provider, useSelector } from 'react-redux'
import ConferenceComingSound from '@/static/sound/conferenceComing.mp3'
import STRINGS from '@/constants/strings'
import conferenceEpics from '@/store/epics/conference'
import trackingEpics from '@/store/epics/track'
import trackEpics from '@/store/epics/track'
import notificationEpics from '@/store/epics/notification'
import store, { history } from '@/store'
import { Action } from '@/styles'
import _ from 'lodash'
import NewNotificationSound from '@/static/sound/new-notification-sound.wav'
import {
  BOOLEAN,
  CONFERENCE_INVITATION_RESPONSE,
  ENV,
  NOTIFICATION_RESPONSES,
  TWILIO_CONVO,
  USER_STATUS
} from '@/constants'
import { FORM_ROUTES, IMAGE_ROUTES, PATIENT_TRACKING_ROUTES } from '@/routes/route-path'
import { NOTIFICATION_PERMISSION, NOTIFICATION_TYPES } from '@/services/constants'
import { REDUCER_STATE } from '@/store/constants'
import { eventConf } from '@/components/event'
import { registerBindingResource } from '@/services/api'
import contactEpics from '@/store/epics/contacts'
import { ROUTES } from '@/routes/root'
import {
  isNotificationMuted,
  isNotificationStale,
  normalizeSystemNotificationData
} from '@/services/firebase/notification'
import { SYSTEM_NOTIFICATION_FIELDS } from '@/constants/api-data/rawNotification'
import { USER_FIELDS } from '@/constants/api-data'
import { CheckCircleTwoTone } from '@ant-design/icons'
import audioEpics from '@/store/epics/audio'
import CallBackButton from '@/components/notifications/call-back-button'

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_SENDER_ID,
  REACT_APP_FIREBASE_WEB_PUSH_VAPID_KEY
} = process.env

const initFirebase = async () => {
  try {
    if (
      REACT_APP_FIREBASE_API_KEY &&
      REACT_APP_FIREBASE_SENDER_ID &&
      REACT_APP_FIREBASE_PROJECT_ID
    ) {
      const config = {
        apiKey: REACT_APP_FIREBASE_API_KEY,
        appId: REACT_APP_FIREBASE_APP_ID,
        messagingSenderId: REACT_APP_FIREBASE_SENDER_ID,
        projectId: REACT_APP_FIREBASE_PROJECT_ID
      }
      if (getApps().length <= 0) {
        initializeApp(config)
      }
    } else {
      return Promise.reject(null)
    }
  } catch (err) {
    throw new Error(err.browserErrorMessage || err.message)
  }
}

/**
 * Request notification permission
 * @returns {Promise} - resolve when notification permission granted
 *                    - reject when notification permission denied or browser does not support notification
 */
const requestPermission = async () => {
  // request permission if app runs in production or env REACT_APP_ENABLE_SERVICE_WORKER_LOCAL is true
  if (
    process.env.NODE_ENV?.toLowerCase() === ENV.PRODUCTION.toLowerCase() ||
    process.env.REACT_APP_ENABLE_SERVICE_WORKER_LOCAL?.toLowerCase() === BOOLEAN.TRUE.toLowerCase()
  ) {
    // if browser does not support Notification, reject request
    const isNotificationSupported = 'Notification' in window
    store.dispatch(notificationEpics.updateIsNotificationSupported(isNotificationSupported))
    if (!isNotificationSupported) {
      return Promise.reject(null)
    }
    if (Notification.permission === NOTIFICATION_PERMISSION.GRANTED) {
      return Promise.resolve()
    }
    if (Notification.permission === NOTIFICATION_PERMISSION.DENIED) {
      return Promise.reject(null)
    }
    // request permission when notification is not granted and denied
    const permission = await Notification.requestPermission()
    if (permission === NOTIFICATION_PERMISSION.GRANTED) {
      return Promise.resolve()
    } else {
      return Promise.reject(null)
    }
  } else {
    return Promise.reject(null)
  }
}

/**
 * Init Firebase messaging service
 * @returns {Promise} - resolve
 *                    - reject when VAPID key is not available
 */
const initMessaging = async () => {
  try {
    const state = store.getState()
    const isBindingCreated = state.getIn([
      REDUCER_STATE.NOTIFICATION.NAME,
      REDUCER_STATE.NOTIFICATION.FIELDS.IS_BINDING_CREATED
    ])
    const isSupported = state.getIn([
      REDUCER_STATE.NOTIFICATION.NAME,
      REDUCER_STATE.NOTIFICATION.FIELDS.IS_NOTIFICATION_SUPPORTED
    ])
    const isRegisteringNotification = state.getIn([
      REDUCER_STATE.NOTIFICATION.NAME,
      REDUCER_STATE.NOTIFICATION.FIELDS.IS_REGISTERING_NOTIFICATION
    ])
    const isPermissionGranted = state.getIn([
      REDUCER_STATE.NOTIFICATION.NAME,
      REDUCER_STATE.NOTIFICATION.FIELDS.IS_PERMISSION_GRANTED
    ])
    const currentAddress = state.getIn([
      REDUCER_STATE.NOTIFICATION.NAME,
      REDUCER_STATE.NOTIFICATION.FIELDS.FCM_TOKEN
    ])
    const app = getApps()[0]
    if (isRegisteringNotification) {
      return Promise.resolve(null)
    } else if (isPermissionGranted && isBindingCreated && isSupported) {
      return Promise.resolve(currentAddress)
    } else if (!isPermissionGranted || !isSupported) {
      return Promise.reject(null)
    } else if (!app) {
      return Promise.resolve(null)
    }
    const messaging = getMessaging(app)
    if (REACT_APP_FIREBASE_WEB_PUSH_VAPID_KEY) {
      const registration = await navigator.serviceWorker.getRegistration()
      const address = await getToken(messaging, {
        vapidKey: REACT_APP_FIREBASE_WEB_PUSH_VAPID_KEY,
        serviceWorkerRegistration: registration
      })
      onMessage(messaging, listenOnMessaging)
      store.dispatch(notificationEpics.setIsRegisteringNotification(true))
      await registerBindingResource({ address })
      store.dispatch(notificationEpics.setIsRegisteringNotification(false))
      return Promise.resolve(address)
    } else {
      return Promise.reject(null)
    }
  } catch (err) {
    throw new Error(err.browserErrorMessage || err.message)
  }
}

/**
 * Generate all notification component options
 * @param {Object} notification - notification object
 * @param {Boolean} isEvent - is event or not (show in history or background notification)
 * @return {null|{description: *, notificationType, message: *}}
 */
const generateNotificationOptions = (notification, isEvent = false) => {
  let notificationOptions = {
    message: notification[SYSTEM_NOTIFICATION_FIELDS.TITLE.name],
    description: notification[SYSTEM_NOTIFICATION_FIELDS.BODY.name],
    key: `${notification?.[SYSTEM_NOTIFICATION_FIELDS.TYPE.name]}-${
      notification[SYSTEM_NOTIFICATION_FIELDS.ID.name]
    }`,
    // Setup default notification sound
    playAudioLoop: false,
    sound: NewNotificationSound
  }
  if (!isEvent) {
    notificationOptions.duration = notification[SYSTEM_NOTIFICATION_FIELDS.DURATION.name] / 1000
  }
  let url
  // Define action button style
  const actions = eventConf[notification.type]?.actions || []
  // Define specific options for different type of notification, if you don't define, it will use default settings
  switch (notification.type) {
    case NOTIFICATION_TYPES.RECORD_CREATED:
      url = generatePath(FORM_ROUTES.EXIST_RECORD_WITH_STUDY_SITE, {
        id: notification.data._id,
        studyId: notification.data.studyId,
        siteId: notification.data.siteId
      })
      notificationOptions = {
        ...notificationOptions,
        ...generateRecordNotificationOptions(url, notification._id, actions)
      }
      break
    case NOTIFICATION_TYPES.RECORD_UPDATED:
      url = generatePath(FORM_ROUTES.EXIST_RECORD_WITH_STUDY_SITE, {
        id: notification.data._id,
        studyId: notification.data.studyId,
        siteId: notification.data.siteId
      })
      notificationOptions = notificationOptions = {
        ...notificationOptions,
        ...generateRecordNotificationOptions(url, notification._id, actions)
      }
      break
    case NOTIFICATION_TYPES.CONFERENCE_INVITATION:
      notificationOptions = {
        ...notificationOptions,
        sound: ConferenceComingSound,
        playAudioLoop: true,
        ...generateConferenceInvitationNotificationOptions(
          notification[SYSTEM_NOTIFICATION_FIELDS.ID.name],
          actions,
          notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceName,
          notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceStudyId,
          notificationOptions.duration,
          isEvent
        )
      }
      break
    case NOTIFICATION_TYPES.CONFERENCE_INVITATION_RESPONSE:
      notificationOptions = {
        ...notificationOptions,
        ...generateConferenceResponseNotificationOptions(
          notification?.[SYSTEM_NOTIFICATION_FIELDS.ID.name],
          actions
        )
      }
      break
    case NOTIFICATION_TYPES.CONFERENCE_ROOM_END:
      notificationOptions = {
        ...notificationOptions,
        ...generateConferenceEndNotificationOptions({
          messageId: notification[SYSTEM_NOTIFICATION_FIELDS.ID.name],
          actions,
          isEvent,
          senderId: notification[SYSTEM_NOTIFICATION_FIELDS.SENDER.name]
        })
      }
      break
    case NOTIFICATION_TYPES.MANUAL_TRIGGERED:
      notificationOptions = {
        ...notificationOptions,
        ...generateManualTriggeredNotificationOptions({
          notification,
          actions,
          messageId: notification?.[SYSTEM_NOTIFICATION_FIELDS.ID.name],
          isEvent
        })
      }
      break
    case NOTIFICATION_TYPES.CONFERENCE_MISS_CALL:
      notificationOptions = {
        ...notificationOptions,
        ...generateConferenceMissCallNotificationOptions(notification)
      }
      break
    case NOTIFICATION_TYPES.USER_ON_CALL_STATUS_UPDATED:
      notificationOptions = {
        ...notificationOptions,
        ...generateUserOnCallUpdatedNotificationOptions(notification)
      }
      break
    case NOTIFICATION_TYPES.USER_ON_DUTY_STATUS_UPDATED:
      notificationOptions = {
        ...notificationOptions,
        ...generateUserOnDutyUpdatedNotificationOptions(notification)
      }
      break
    case NOTIFICATION_TYPES.IMAGE_CREATED:
      url = generatePath(IMAGE_ROUTES.IMAGE, {
        studyInstanceUID: notification[SYSTEM_NOTIFICATION_FIELDS.DATA.name].studyInstanceUID,
        studyId: notification[SYSTEM_NOTIFICATION_FIELDS.DATA.name].studyId
      })
      notificationOptions = {
        ...notificationOptions,
        ...generateImageNotificationOptions(
          url,
          notification[SYSTEM_NOTIFICATION_FIELDS.ID.name],
          actions
        )
      }
      break
    // Generate view track button
    case NOTIFICATION_TYPES.TRACK_CREATED:
    case NOTIFICATION_TYPES.TRACK_BEFORE_ARRIVAL: {
      const notifyData = notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]
      const trackId = notifyData?.trackId
      const studyId = notifyData?.studyId
      notificationOptions = {
        ...notificationOptions,
        ...generatePatientTrackingBeforeArriveNotificationOptions({ id: trackId, studyId, actions })
      }
      break
    }
    default:
      break
  }

  return {
    ...notificationOptions,
    notificationType: notification[SYSTEM_NOTIFICATION_FIELDS.TYPE.name]
  }
}

/**
 * Handle FCM at foreground
 * @param {object}   payload       - Firebase messaging payload
 */
const listenOnMessaging = async (payload) => {
  const {
    data: { data, type, twi_message_type }
  } = payload
  const state = store.getState()
  // Perform actions or update redux store for specific notification types
  if (twi_message_type) {
    if (Object.values(TWILIO_CONVO.NOTIFICATION_TYPES).includes(twi_message_type)) {
      await store.dispatch(notificationEpics.setTwilioChatNotifications(payload))
    }
  } else {
    if (
      type === NOTIFICATION_TYPES.USER_ON_CALL_STATUS_UPDATED ||
      type === NOTIFICATION_TYPES.USER_ON_DUTY_STATUS_UPDATED
    ) {
      // If user status updated, then refresh the contact list if user is in home page (because if user not in home page now, they will get updated data once they enter it)
      if (history.location.pathname === ROUTES.HOME) {
        const user = data ? JSON.parse(data) : {}
        await store.dispatch(contactEpics.updateContacts(user))
      }
    } else if ([NOTIFICATION_TYPES.CONFERENCE_RESPONDED].includes(type)) {
      store.dispatch(audioEpics.pauseAudio())
    } else if (
      [
        NOTIFICATION_TYPES.TRACK_CREATED,
        NOTIFICATION_TYPES.TRACK_BEFORE_ARRIVAL,
        NOTIFICATION_TYPES.TRACK_ARRIVED,
        NOTIFICATION_TYPES.TRACK_ARRIVED_SELF,
        NOTIFICATION_TYPES.TRACK_CREATED_SELF,
        NOTIFICATION_TYPES.TRACK_COMPLETED
      ].includes(type)
    ) {
      if (history.location.pathname === ROUTES.PATIENT_TRACKING) {
        const notifyData = data ? JSON.parse(data) : {}
        store.dispatch(
          trackEpics.getAndUpdateTrackFromNotify({
            studyId: notifyData?.studyId,
            id: notifyData?.trackId,
            notificationType: type
          })
        )
      }
    }
    // Setup antd notification for popup window
    const notification = normalizeSystemNotificationData(payload.data)
    const notificationOptions = generateNotificationOptions(notification)
    // Update new notification in redux
    store.dispatch(notificationEpics.newNotificationIncoming(notification))
    const currentUserNotificationSettings = state.getIn([
      REDUCER_STATE.AUTH.NAME,
      REDUCER_STATE.AUTH.FIELDS.USER,
      USER_FIELDS.NOTIFICATION_SETTINGS
    ])
    const currentUser = state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.USER])
    const currentUserOnCallStatus = currentUser?.[USER_FIELDS.ON_CALL]
    // Don't show ant notification for stale ones, and don't show muted ones
    if (
      !isNotificationStale(notification) &&
      notificationOptions &&
      !isNotificationMuted({ type, notifySettings: currentUserNotificationSettings })
    ) {
      const antdNotificationOptions = {
        ...notificationOptions
      }
      const ActionBtn = antdNotificationOptions.btn
      const actionBtnProps = {} // used to control the display of action button in notification popup
      if (
        [NOTIFICATION_TYPES.CONFERENCE_INVITATION, NOTIFICATION_TYPES.CONFERENCE_ROOM_END].includes(
          type
        )
      ) {
        store.dispatch(audioEpics.pauseAudio())
        // If current user already in a conference, then disable the response buttons
        const disableConferenceResponse = state.getIn([
          REDUCER_STATE.CONFERENCE.NAME,
          REDUCER_STATE.CONFERENCE.FIELDS.DATA
        ])
        actionBtnProps.isDisableActionBtn = !_.isEmpty(disableConferenceResponse)
      } else if (type === NOTIFICATION_TYPES.MANUAL_TRIGGERED) {
        const receiverNotifications =
          notification?.[SYSTEM_NOTIFICATION_FIELDS.NOTIFICATION_RECEIVERS.name]
        const requireAcknowledge =
          notification?.[SYSTEM_NOTIFICATION_FIELDS.REQUIRE_ACKNOWLEDGE.name]
        actionBtnProps.isShowViewAcknowledgeButton =
          requireAcknowledge && !_.isEmpty(receiverNotifications)
        actionBtnProps.isDisableActionBtn =
          requireAcknowledge && _.isEmpty(receiverNotifications) && notification.acknowledge
        actionBtnProps.isShowAcknowledgeButton =
          requireAcknowledge &&
          !actionBtnProps.isShowViewAcknowledgeButton &&
          currentUserOnCallStatus
      }
      if (ActionBtn) {
        antdNotificationOptions.btn = (
          <Provider store={store}>
            <ActionBtn {...actionBtnProps} />
          </Provider>
        )
      }
      // Show ant notification
      NotificationComponent.info({
        ...antdNotificationOptions,
        onClose: () => {
          store.dispatch(audioEpics.pauseAudio())
        }
      })
      // If notification contains sound option, then play the sound
      if (antdNotificationOptions?.sound) {
        // Stop current audio if exists
        store.dispatch(audioEpics.pauseAudio())
        store.dispatch(
          audioEpics.playAudio({
            url: antdNotificationOptions?.sound,
            loop: antdNotificationOptions?.playAudioLoop
          })
        )
      }
    }
  }
}

/**
 * Generate record notification options
 * @param {string} url
 * @param {string} id
 * @param {Array} actions
 * @returns {{btn: (function(): *), key}}
 */
const generateRecordNotificationOptions = (url, id, actions) => {
  const key = id
  const onClick = () => {
    NotificationComponent.close(key)
    store.dispatch(notificationEpics.clearNewNotificationIds(NOTIFICATION_TYPES.RECORD_CREATED))
    history.push(url)
  }
  const btn = () =>
    actions.map((action, index) => {
      const { title, icon: Icon } = action
      if (url) {
        return (
          <Action key={index} icon={<Icon size={16} />} onClick={onClick}>
            {title}
          </Action>
        )
      } else {
        return (
          <Action key={index} icon={<Icon size={16} />}>
            {title}
          </Action>
        )
      }
    })

  const notificationOptions = {
    key,
    btn
  }
  return notificationOptions
}

/**
 * Image related notification options
 * @param {string} url - image url
 * @param {string} id - image id
 * @param {Array} actions
 * @returns {{btn: (function(): *), key}}
 */
const generateImageNotificationOptions = (url, id, actions) => {
  const key = id
  const onClick = () => {
    NotificationComponent.close(key)
    store.dispatch(notificationEpics.clearNewNotificationIds(NOTIFICATION_TYPES.IMAGE_CREATED))
    history.push(url)
  }
  const btn = () =>
    actions.map((action, index) => {
      const { title, icon: Icon } = action
      if (url) {
        return (
          <Action key={index} icon={<Icon size={16} />} onClick={onClick}>
            {title}
          </Action>
        )
      } else {
        return (
          <Action key={index} icon={<Icon size={16} />}>
            {title}
          </Action>
        )
      }
    })

  const notificationOptions = {
    key,
    btn
  }
  return notificationOptions
}

/**
 * Patient tracking before arrival notification options
 * @param {string} id - track id
 * @param {string} studyId - study id
 * @param {Array} actions
 * @returns {{btn: (function(): *), key}}
 */
const generatePatientTrackingBeforeArriveNotificationOptions = ({ id, studyId, actions }) => {
  const key = id
  /**
   * Click from notification popup
   */
  const onClick = () => {
    NotificationComponent.close(key)
    store.dispatch(notificationEpics.clearNewNotificationIds(NOTIFICATION_TYPES.TRACK_ARRIVED))
    history.push({
      pathname: PATIENT_TRACKING_ROUTES.PATIENT_TRACKING_LIST,
      state: {
        trackIdFromNotify: id
      }
    })
  }

  /**
   * Click from history panel
   */
  const onClickFromHistory = () => {
    store.dispatch(trackingEpics.setClickedStudyIdAndTrackId({ id, studyId }))
  }

  /**
   * Notification button click event
   * @param {boolean} clickedFromHistory - set this as true by default will make every click popup a window
   * @returns {unknown[]}
   */
  const btn = ({ clickedFromHistory = true }) =>
    actions.map((action, index) => {
      const { title, icon: Icon } = action
      return (
        <Action
          key={index}
          icon={<Icon size={16} />}
          onClick={clickedFromHistory ? onClickFromHistory : onClick}
        >
          {title}
        </Action>
      )
    })

  const notificationOptions = {
    key,
    btn
  }
  return notificationOptions
}

/**
 * Generate conference end options
 * @param messageId
 * @param actions
 * @param senderId - target user
 * @param conferenceStudyId
 * @param isEvent
 * @return {{duration: number, closeIcon: null, btn: ((function(*): (*))|*), key}}
 */
const generateConferenceEndNotificationOptions = ({ messageId, senderId, actions, isEvent }) => {
  const key = messageId

  return {
    duration: 5, // To prevent auto close, set to 0
    key,
    btn: (props) => (
      <CallBackButton
        buttonActions={actions}
        notificationSender={senderId}
        isEventNotification={isEvent}
        notificationKey={key}
        {...props}
      />
    )
  }
}

/**
 * Generate conference call invitation notification
 * @param messageId
 * @param actions
 * @param conferenceName
 * @param conferenceStudyId
 * @param duration
 * @param isEvent
 * @returns {{duration: number, closeIcon: null, btn: (function(*): *), key}}
 */
const generateConferenceInvitationNotificationOptions = (
  messageId,
  actions,
  conferenceName,
  conferenceStudyId,
  duration,
  isEvent
) => {
  const key = messageId

  let Btn = (props) => {
    const isConferenceRespondedOrEnd = useSelector((state) => {
      const notification = state.getIn([
        REDUCER_STATE.NOTIFICATION.NAME,
        REDUCER_STATE.NOTIFICATION.FIELDS.INCOMING_NOTIFICATION
      ])
      const isConferenceResponded =
        notification?.[SYSTEM_NOTIFICATION_FIELDS.TYPE.name] ===
          NOTIFICATION_TYPES.CONFERENCE_RESPONDED &&
        notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceName === conferenceName

      const isConferenceEnded =
        notification?.[SYSTEM_NOTIFICATION_FIELDS.TYPE.name] ===
          NOTIFICATION_TYPES.CONFERENCE_ROOM_END &&
        notification?.[SYSTEM_NOTIFICATION_FIELDS.DATA.name]?.conferenceName === conferenceName
      return isConferenceResponded || isConferenceEnded
    })
    const isConferenceRespondedOrEndRef = useRef(isConferenceRespondedOrEnd)

    /**
     * Stop ring and send response
     * @param action
     */
    const onActionClick = (action) => {
      isConferenceRespondedOrEndRef.current = true
      NotificationComponent.close(key)
      store.dispatch(audioEpics.pauseAudio())
      store.dispatch(
        conferenceEpics.responseInvitation({
          studyId: conferenceStudyId,
          roomName: conferenceName,
          response: action
        })
      )
    }

    /**
     * Close conference invitation notification if it has responded on other devices
     */
    useEffect(() => {
      if (isConferenceRespondedOrEnd) {
        NotificationComponent.close(key)
      }
      isConferenceRespondedOrEndRef.current = isConferenceRespondedOrEnd
    }, [isConferenceRespondedOrEnd])

    /**
     * Send no response after timeout if conference has not been responded
     */
    useEffect(() => {
      let timeout
      if (duration && !isConferenceRespondedOrEndRef.current) {
        timeout = setTimeout(() => {
          onActionClick(CONFERENCE_INVITATION_RESPONSE.NO_RESPONSE)
          isConferenceRespondedOrEndRef.current = true
        }, duration * 1000)
      }
      return () => {
        clearTimeout(timeout)
      }
    }, [duration])

    /**
     * When we close the webpage, send no response directly
     */
    useEffect(() => {
      const handleBeforeUnload = () => {
        // Customize the confirmation message
        if (!isConferenceRespondedOrEndRef.current) {
          onActionClick(CONFERENCE_INVITATION_RESPONSE.NO_RESPONSE)
        }
      }

      window.addEventListener('beforeunload', handleBeforeUnload)

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }, [])

    if (isEvent && props?.isDisableActionBtn) {
      return (
        <div style={{ color: 'green' }}>
          <CheckCircleTwoTone twoToneColor='#52c41a' /> Already in a conference
        </div>
      )
    } else {
      return actions.map((action, index) => {
        const { title, icon: Icon, color } = action
        if (title === CONFERENCE_INVITATION_RESPONSE.ACCEPT) {
          return (
            <Action
              key={index}
              icon={<Icon size={16} color={color} />}
              onClick={() => onActionClick(CONFERENCE_INVITATION_RESPONSE.ACCEPT)}
              disabled={props?.isDisableActionBtn}
            >
              {title}
            </Action>
          )
        }
        if (title === CONFERENCE_INVITATION_RESPONSE.REJECT) {
          return (
            <Action
              key={index}
              icon={<Icon size={16} color={color} />}
              onClick={() => onActionClick(CONFERENCE_INVITATION_RESPONSE.REJECT)}
              disabled={props?.isDisableActionBtn}
            >
              {title}
            </Action>
          )
        }
      })
    }
  }

  Btn.propTypes = {
    isDisableActionBtn: PropTypes.bool,
    clickedFromHistory: PropTypes.bool
  }

  return {
    duration: 0, // prevent auto close
    closeIcon: null,
    key,
    btn: Btn
  }
}

/**
 * Generate conference response notification
 * @param {String} messageId
 * @param {Array} actions
 * @returns {{btn: ((function(*): (*))|*), key}}
 */
const generateConferenceResponseNotificationOptions = (messageId, actions) => {
  const key = messageId
  const onClick = () => {
    NotificationComponent.close(key)
  }
  const btn = (props) => {
    if (props?.isDisableActionBtn) {
      return (
        <div style={{ color: 'green' }}>
          <CheckCircleTwoTone twoToneColor='#52c41a' /> Already in a conference
        </div>
      )
    } else {
      return actions.map((action, index) => {
        const { title, icon: Icon, color } = action
        return (
          <Action
            key={index}
            icon={<Icon size={16} color={color} />}
            onClick={onClick}
            disabled={props?.isDisableActionBtn}
          >
            {title}
          </Action>
        )
      })
    }
  }

  const notificationOptions = {
    key,
    btn
  }
  return notificationOptions
}

/**
 * Generate manually triggered notification
 * @param notification
 * @param actions
 * @param messageId
 * @param isEvent
 * @return {{description, message, btn: ((function(*): (*))|*), key: *}}
 */
const generateManualTriggeredNotificationOptions = ({
  notification,
  actions,
  messageId,
  isEvent
}) => {
  const handleAcknowledgeSender = () => {
    NotificationComponent.close(messageId)
    store.dispatch(notificationEpics.acknowledgeSender({ notificationId: messageId }))
  }

  // Button in history section
  let Btn = (props) => {
    if (props?.isDisableActionBtn) {
      return (
        <div style={{ color: 'green' }}>
          <CheckCircleTwoTone twoToneColor='#52c41a' /> Acknowledged
        </div>
      )
    } else {
      return actions.map((action, index) => {
        const { title, icon: Icon } = action
        if (
          props.isShowViewAcknowledgeButton &&
          title === NOTIFICATION_RESPONSES.MANUAL_TRIGGERED.VIEW_ACKNOWLEDGEMENT
        ) {
          return (
            <div key={index}>
              <Action
                icon={<Icon size={16} />}
                onClick={() => {
                  store.dispatch(notificationEpics.getNotification({ notificationId: messageId }))
                  store.dispatch(notificationEpics.setIsShowAcknowledgeStatusModel(true))
                }}
              >
                {title}
              </Action>
            </div>
          )
        } else if (
          props.isShowAcknowledgeButton &&
          title === NOTIFICATION_RESPONSES.MANUAL_TRIGGERED.ACKNOWLEDGE
        ) {
          return (
            <Action key={index} icon={<Icon size={16} />} onClick={handleAcknowledgeSender}>
              {title}
            </Action>
          )
        } else {
          return null
        }
      })
    }
  }

  if (!isEvent) {
    Btn = (props) => {
      return actions.map((action, index) => {
        const { title, icon: Icon } = action
        if (
          props.isShowAcknowledgeButton &&
          title === NOTIFICATION_RESPONSES.MANUAL_TRIGGERED.ACKNOWLEDGE
        ) {
          return (
            <Action key={index} icon={<Icon size={16} />} onClick={handleAcknowledgeSender}>
              {title}
            </Action>
          )
        } else {
          return null
        }
      })
    }
  }

  Btn.propTypes = {
    isDisableActionBtn: PropTypes.bool,
    isShowViewAcknowledgeButton: PropTypes.bool,
    isShowAcknowledgeButton: PropTypes.bool
  }

  return {
    message: notification.title,
    description: notification.body,
    key: notification._id,
    btn: Btn
  }
}

/**
 * Generate miss call notification options
 * @param {object} notification
 * @returns {object}
 */
const generateConferenceMissCallNotificationOptions = (notification) => {
  const sender = notification[SYSTEM_NOTIFICATION_FIELDS.SENDER.name]
  const name = `${sender.firstName} ${sender.lastName}`
  const notificationOptions = {
    message: notification.title,
    description: STRINGS.NOTIFICATION_CONFERENCE_MISS_CALL({ name }),
    key: notification[SYSTEM_NOTIFICATION_FIELDS.TWI_MESSAGE_ID.name]
  }
  return notificationOptions
}

/**
 * Generate user onCall updated notification options
 * @param {object} notification
 * @returns {object}
 */
const generateUserOnCallUpdatedNotificationOptions = (notification) => {
  const user = notification[SYSTEM_NOTIFICATION_FIELDS.DATA.name].user
  const name = `${user.firstName} ${user.lastName}`
  const description = user.onCall
    ? STRINGS.NOTIFICATION_USER_ON_CALL_UPDATED_ON_CALL({ name })
    : STRINGS.NOTIFICATION_USER_ON_CALL_UPDATED_OFF_CALL({ name })
  const notificationOptions = {
    message: notification.title,
    description,
    key: notification[SYSTEM_NOTIFICATION_FIELDS.TWI_MESSAGE_ID.name]
  }
  return notificationOptions
}

/**
 * Generate user status updated notification options
 * @param {object} notification
 * @returns {object}
 */
const generateUserOnDutyUpdatedNotificationOptions = (notification) => {
  const user = notification[SYSTEM_NOTIFICATION_FIELDS.DATA.name].user
  const name = `${user.firstName} ${user.lastName}`
  const { status } = user
  let description
  switch (status) {
    case USER_STATUS.OFF_DUTY.value:
      description = STRINGS.NOTIFICATION_USER_ON_DUTY_UPDATED_OFF_DUTY({ name })
      break
    case USER_STATUS.ON_DUTY.value:
      description = STRINGS.NOTIFICATION_USER_ON_DUTY_UPDATED_ON_DUTY({ name })
      break
    default:
      break
  }
  const notificationOptions = {
    message: notification.title,
    description,
    key: notification[SYSTEM_NOTIFICATION_FIELDS.TWI_MESSAGE_ID.name]
  }
  return notificationOptions
}

export {
  generateNotificationOptions,
  initFirebase,
  initMessaging,
  listenOnMessaging,
  requestPermission
}
