import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'
import { isEmpty } from 'lodash'
import { TRACKING_FIELDS } from '@/constants/api-data'

/**
 * Get all tracks
 * @param {string} studyId
 * @param {string} status
 * @param {Array} populates
 * @param {Array} orderBy
 * @param {Array} sortBy
 * @param {Number} limit
 * @return {Promise<*>}
 */
const getTracks = async ({
  studyId,
  status,
  mrn,
  patientName,
  site,
  populates = [TRACKING_FIELDS.IMAGE_ID],
  orderBy = [],
  sortBy = [],
  limit
}) => {
  try {
    const params = {}
    if (status) params.status = status
    if (mrn) params.mrn = mrn
    if (patientName) params.patientName = patientName
    if (site) params.site = site
    if (limit) params.limit = limit
    if (!isEmpty(populates)) params.populates = populates
    if (!isEmpty(orderBy)) params.orderBy = orderBy
    if (!isEmpty(sortBy)) params.sortBy = sortBy
    return extractResponse(await API.get(ENDPOINTS.TRACKS({ studyId }), { params }))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get one track
 * @param {string} studyId
 * @param {string} id
 * @param {Array} populates
 * @return {Promise<*>}
 */
const getTrack = async ({ studyId, id, populates = [TRACKING_FIELDS.IMAGE_ID] }) => {
  try {
    const params = {}
    if (!isEmpty(populates)) params.populates = populates
    return extractResponse(await API.get(ENDPOINTS.TRACK({ studyId, id }), { params }))
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Check if flight exists or not
 * @param {String} studyId
 * @param {String} flightId
 * @return {Promise<*>}
 */
const prefetchTrack = async ({ studyId, flightId }) => {
  try {
    const res = extractResponse(await API.post(ENDPOINTS.TRACK_PREFETCH({ studyId, flightId }), ''))
    return res
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Create new tracking case
 * @param {string} studyId
 * @param {Object} payload
 * @return {Promise<*>}
 */
const createTrack = async ({ studyId, payload }) => {
  try {
    const res = extractResponse(await API.post(ENDPOINTS.TRACKS({ studyId }), payload))
    return res
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update current location
 * @param {string} studyId
 * @param {string} trackId
 * @param {Object} payload
 * @return {Promise<*>}
 */
const updateTrack = async ({ studyId, trackId, payload }) => {
  try {
    const response = extractResponse(
      await API.put(ENDPOINTS.TRACK({ studyId, id: trackId }), payload)
    )
    return response
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Link track with record
 * @param {string} studyId
 * @param {string} formId
 * @param {string} imageId
 * @param {string} trackId
 * @return {Promise<*>}
 */
const linkTrackWithRecord = async ({ studyId, trackId, formId, imageId }) => {
  try {
    const response = extractResponse(
      await API.put(ENDPOINTS.TRACK_LINK({ studyId, id: trackId }), { formId, imageId })
    )
    return response
  } catch (err) {
    errorHandler(err)
  }
}

export { getTrack, getTracks, createTrack, updateTrack, linkTrackWithRecord, prefetchTrack }
