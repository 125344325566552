// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pr39bKMTPvfvX3cuaQGK {
  font-size: 12px;
}
.pr39bKMTPvfvX3cuaQGK .ant-popover-inner-content {
  padding: 6px 8px;
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/header/style/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAFA;EAGI,gBAAA;EACA,kBAAA;AAEJ","sourcesContent":[".header__popover {\n  font-size: 12px;\n  :global(.ant-popover-inner-content){\n    padding: 6px 8px;\n    border-radius: 3px;\n  }\n}\n@primary-color: #4888d0;@primary-color-light: #40a9ff;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header__popover": `pr39bKMTPvfvX3cuaQGK`
};
export default ___CSS_LOADER_EXPORT___;
