import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles/index.module.less'

const defaultTip = 'Loading'

const Loading = (props) => {
  const { fullscreen } = props
  const className = classNames({
    [styles.loading__wrapper]: true,
    [styles.fullscreen]: fullscreen
  })

  useEffect(() => {
    if (fullscreen) {
      const target = document.createElement('div')
      target.className = className
      document.body.appendChild(target)
      if (target) {
        const LoadingComponent = (
          <>
            <Spin size='large' />
            <span className={styles.tip}>{props.tipComponent || props.tip || defaultTip}</span>
          </>
        )
        ReactDOM.render(LoadingComponent, target)
        return () => {
          ReactDOM.unmountComponentAtNode(target)
          document.body.removeChild(target)
        }
      }
    }
  }, [])

  if (!fullscreen) {
    return (
      <div className={className}>
        <Spin size='large' />
        <span className={styles.tip}>{props.tipComponent || props.tip || defaultTip}</span>
      </div>
    )
  } else {
    return null
  }
}

Loading.propTypes = {
  fullscreen: PropTypes.bool,
  tip: PropTypes.string,
  tipComponent: PropTypes.node
}

export default Loading
