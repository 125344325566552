import API from '../client'
import ENDPOINTS from '../endpoints'
import { errorHandler, extractResponse } from '../utils'

/**
 * Create a site
 * @param {String} studyName - study name
 * @return {Promise<*>}
 */
const createSite = async ({ siteName, siteType, sitePrefix, studyId }) => {
  const body = { siteName, siteType, sitePrefix }
  try {
    const response = await API.post(ENDPOINTS.SITES({ id: studyId }), body)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Delete a site
 * @param {object} options
 * @param {String} options.studyId - study id
 * @param {String} options.siteId  - site id
 * @return {Promise<void>}
 */
const deleteSite = async ({ studyId, siteId }) => {
  try {
    await API.delete(ENDPOINTS.SITE({ studyId, siteId }))
    return siteId
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get sites
 * @param {object} options
 * @param {String} options.studyId - study id
 * @return {Promise<*>}
 */
const getSites = async ({ studyId }) => {
  try {
    const response = await API.get(ENDPOINTS.SITES({ id: studyId }))
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Update a site
 * @param {object}  options
 * @param {string}  options.studyId  - study Id
 * @param {string}  options.siteId   - site Id
 * @param {object}  options.body     - the part that site will be updated
 */
const updateSite = async ({ studyId, siteId, body }) => {
  try {
    const response = await API.put(ENDPOINTS.SITE({ studyId, siteId }), body)
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

/**
 * Get a site
 * @param {object}  options
 * @param {string}  options.studyId  - study Id
 * @param {string}  options.siteId   - site Id
 */
const getSite = async ({ studyId, siteId }) => {
  try {
    const response = await API.get(ENDPOINTS.SITE({ studyId, siteId }))
    return extractResponse(response)
  } catch (err) {
    errorHandler(err)
  }
}

export { createSite, deleteSite, getSites, updateSite, getSite }
