import { ttl } from '@/libs/utils'

const ENDPOINTS = {
  AUTH: '/auth',
  API_VERSION: '/version',
  CHANGE_PASSWORD: '/changePassword',
  // General
  MAPBOX_TOKEN: '/mapbox-token',

  // Conversation
  CONVERSATION: ttl`/conversations/${'conversationSid'}`,
  CONVERSATIONS: '/conversations',
  GROUP_CONVERSATIONS: '/conversations/group',
  PRIVATE_CONVERSATIONS: '/conversations/private',
  CONVERSATION_ACCESS_TOKEN: '/conversations/access-token',
  CONVERSATION_MESSAGES: ttl`/conversations/${'conversationSid'}/messages`,
  CONVERSATION_PARTICIPANT: ttl`/conversations/${'conversationSid'}/participants/${'participantSid'}`,
  CONVERSATION_PARTICIPANTS: ttl`/conversations/${'conversationSid'}/participants`,
  CONVERSATION_NOTIFICATION: ttl`/conversations/${'conversationSid'}/participants/self/notification`,
  // Voice
  VOICE_ACCESS_TOKEN: '/voice/access-token',
  VOICE_MAKE_CALL: '/voice/make-call',
  VOICE_END_CALL: 'voice/end-call',
  VOICE_OUTGOING_TWIML_APP: 'voice/twiml-outgoing',
  // Conference
  CONFERENCE_ROOMS: ttl`/studies/${'studyId'}/conference/rooms`,
  CONFERENCE_FINISH: ttl`/studies/${'studyId'}/conference/rooms/${'roomName'}/end-conference`,
  CONFERENCE_INVITE: ttl`/studies/${'studyId'}/conference/rooms/${'roomName'}/invite`,
  CONFERENCE_INVITATION_RESPONSE: ttl`/studies/${'studyId'}/conference/rooms/${'roomName'}/invitation-response`,
  CONFERENCE_JOIN: ttl`/studies/${'studyId'}/conference/rooms/${'roomName'}/join`,
  // Dicom
  DICOM_INSTANCE_FILE: ttl`/dicom/instances/${'id'}/file`,
  DICOM_PATIENTS: ttl`/dicom/${'studyId'}/patients`,
  DICOM_PATIENT_SERIES: ttl`/dicom/patients/${'id'}`,
  DICOM_PATIENT_STUDY: ttl`/dicom/${'studyId'}/patients/${'id'}`,
  DICOM_SERIES: ttl`/dicom/series/${'id'}`,
  DICOM_STUDIES: ttl`/images/${'studyId'}/studies`,
  // User profile
  FILE: ttl`/files/${'id'}`,
  FILES: '/files',
  GENERAL_APP_INFO: '/general-app-info',
  REGISTRATION_INFO: '/register-general-info',
  EJECT_USER: ttl`/eject/${'userId'}`,
  LOCK_RECORD: ttl`/studies/${'studyId'}/records/${'recordId'}/lock`,
  LOGIN: '/login',
  LOGOUT: '/logout',
  SEND_RESET_PASSWORD: '/send-reset-password',
  RESET_PASSWORD: '/reset-password',
  REGISTER: '/register',
  VERIFY_EMAIL: '/verifyEmail',
  RESEND_VERIFICATION_EMAIL: '/resendVerificationEmail',
  NOTIFICATIONS: '/notifications',
  NOTIFICATION: ttl`/notifications/${'id'}`,
  NOTIFICATION_REGISTER: '/notifications/register',
  NOTIFICATION_ACKNOWLEDGEMENT: '/notifications/acknowledge',
  NOTIFICATION_TEMPLATE: ttl`/studies/${'studyId'}/notificationTemplates/${'templateId'}`,
  NOTIFICATION_TEMPLATES: ttl`/studies/${'studyId'}/notificationTemplates`,
  NOTIFICATION_TEMPLATES_SORT_ORDER: ttl`/studies/${'studyId'}/notificationTemplates/sort-order`,
  OPTIONS: ttl`/studies/${'studyId'}/options`,
  QUESTIONSET: ttl`/studies/${'studyId'}/questionSets/${'id'}`,
  QUESTIONSETS: ttl`/studies/${'studyId'}/questionSets`,
  QUESTIONSET_SELECT: ttl`/studies/${'studyId'}/questionSets/${'id'}/select`,
  RECORD: ttl`/studies/${'studyId'}/records/${'recordId'}`,
  RECORDS: ttl`/studies/${'studyId'}/records`,
  RECORD_LAST_CREATED_AT: ttl`/studies/${'studyId'}/records/lastCreatedAt`,
  RECORD_PDF: ttl`/download-report/studies/${'studyId'}/sites/${'siteId'}/records/${'recordId'}/pdf`,
  ANALYTIC_REPORT_PDF: ttl`/download-report/studies/${'studyId'}/analytic-report/pdf`,
  ANALYTIC_REPORT_HTML: ttl`/download-report/studies/${'studyId'}/analytic-report/html`,
  SITE: ttl`/studies/${'studyId'}/sites/${'siteId'}`,
  SITES: ttl`/studies/${'id'}/sites`,
  STUDIES: '/studies',
  STUDY: ttl`/studies/${'id'}`,
  STUDY_EXCEL_REPORT: ttl`/download-report/studies/${'studyId'}`,
  UNLOCK_RECORD: ttl`/studies/${'studyId'}/records/${'recordId'}/unlock`,
  USER: ttl`/users/${'id'}`,
  USER_DELETION_SELF: '/users/delete/self',
  ENABLE_OR_DISABLE_USER: ttl`/users/${'id'}/state`,
  RESET_USER_LIMITER: ttl`/reset-rate-limiter/${'id'}`,
  USERS: '/users',
  EMAILS: '/emails/report',
  HOSPITALS: ttl`/studies/${'studyId'}/hospitals`,
  HOSPITAL: ttl`/studies/${'studyId'}/hospitals/${'id'}`,
  HOSPITALS_SORT_ORDER: ttl`/studies/${'studyId'}/hospitals/sort-order`,
  // ROLE_QS
  ROLE_QSS: ttl`/studies/${'studyId'}/role-QSs`,
  ROLE_QS: ttl`/studies/${'studyId'}/role-QSs/${'id'}`,
  ALLOCATED_ROLE: ttl`/studies/${'studyId'}/role-QSs/allocated-question-sets`,
  // Track
  TRACK_PREFETCH: ttl`/studies/${'studyId'}/trackings/prefetch/${'flightId'}`,
  TRACKS: ttl`/studies/${'studyId'}/trackings`,
  TRACK: ttl`/studies/${'studyId'}/trackings/${'id'}`,
  TRACK_LINK: ttl`/studies/${'studyId'}/trackings/${'id'}/link`,
  // Live camera
  LIVE_CAMERA: ttl`/studies/${'studyId'}/live-cameras`,
  LIVE_CAMERA_OPERATION: ttl`/studies/${'studyId'}/live-cameras/operation`,
  LIVE_CAMERA_HOME: ttl`/studies/${'studyId'}/live-cameras/home`,
  // Mongo Chart
  MONGO_CHART: ttl`/studies/${'id'}/mongoChart`,
  // Rosters
  ROSTERS: ttl`/studies/${'studyId'}/rosters`,
  ROSTER: ttl`/studies/${'studyId'}/rosters/${'id'}`
}

export default ENDPOINTS
