import { ofType } from 'redux-observable'
import { from, iif, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { getStudies, getStudy } from '@/services/api'
import {
  CAMERA_GOTO_HOME_POSITION,
  CAMERA_GOTO_HOME_POSITION_FULFILLED,
  CAMERA_GOTO_HOME_POSITION_REJECTED,
  CAMERA_SET_HOME_POSITION,
  CAMERA_SET_HOME_POSITION_FULFILLED,
  CAMERA_SET_HOME_POSITION_REJECTED,
  GET_CAMERA_STATUS,
  GET_CAMERA_STATUS_FULFILLED,
  GET_CAMERA_STATUS_REJECTED,
  GET_LIVE_CAMERA_STUDIES,
  GET_LIVE_CAMERA_STUDIES_FULFILLED,
  GET_LIVE_CAMERA_STUDIES_REJECTED,
  OPERATE_CAMERA,
  OPERATE_CAMERA_FULFILLED,
  OPERATE_CAMERA_REJECTED
} from '@/store/types'
import {
  getCameraStatus,
  gotoHomePosition,
  operateCamera,
  setHomePosition
} from '@/services/api/live-camera'

const liveCameraEpics = {
  // Get studies
  getLiveCameraStudies: (payload) => ({
    type: GET_LIVE_CAMERA_STUDIES,
    payload
  }),

  getLiveCameraStudiesFulfilled: (payload) => ({
    type: GET_LIVE_CAMERA_STUDIES_FULFILLED,
    payload
  }),

  getLiveCameraStudiesError: (error) => ({
    type: GET_LIVE_CAMERA_STUDIES_REJECTED,
    payload: error
  }),

  getLiveCameraStudiesEpic: (action$) =>
    action$.pipe(
      ofType(GET_LIVE_CAMERA_STUDIES),
      mergeMap((action) =>
        iif(
          () => action.payload.canManageStudiesUsers,
          of(action.payload.populate).pipe(
            mergeMap((payload) =>
              from(getStudies(payload)).pipe(
                map(liveCameraEpics.getLiveCameraStudiesFulfilled),
                catchError((error) => of(liveCameraEpics.getLiveCameraStudiesError(error)))
              )
            )
          ),
          of(action.payload).pipe(
            mergeMap((payload) =>
              from(getStudy(payload.id, payload.populate)).pipe(
                map((payload) => liveCameraEpics.getLiveCameraStudiesFulfilled([payload])),
                catchError((error) => of(liveCameraEpics.getLiveCameraStudiesError(error)))
              )
            )
          )
        )
      )
    ),

  // Get camera status
  getCameraStatus: (payload) => ({
    type: GET_CAMERA_STATUS,
    payload
  }),

  getCameraStatusFulfilled: (payload) => ({
    type: GET_CAMERA_STATUS_FULFILLED,
    payload
  }),

  getCameraStatusError: (error) => ({
    type: GET_CAMERA_STATUS_REJECTED,
    payload: error
  }),

  getCameraStatusEpics: (action$) =>
    action$.pipe(
      ofType(GET_CAMERA_STATUS),
      mergeMap((action) =>
        from(getCameraStatus(action.payload)).pipe(
          map((payload) => liveCameraEpics.getCameraStatusFulfilled(payload)),
          catchError((error) => of(liveCameraEpics.getCameraStatusError(error)))
        )
      )
    ),

  // Operate camera
  operateCamera: (payload) => ({
    type: OPERATE_CAMERA,
    payload
  }),

  operateCameraFulfilled: (payload) => ({
    type: OPERATE_CAMERA_FULFILLED,
    payload
  }),

  operateCameraError: (error) => ({
    type: OPERATE_CAMERA_REJECTED,
    payload: error
  }),

  operateCameraEpics: (action$) =>
    action$.pipe(
      ofType(OPERATE_CAMERA),
      mergeMap((action) =>
        from(operateCamera(action.payload)).pipe(
          map((payload) => liveCameraEpics.operateCameraFulfilled(payload)),
          catchError((error) => of(liveCameraEpics.operateCameraError(error)))
        )
      )
    ),
  // Move to home position
  goToHomePosition: (payload) => ({
    type: CAMERA_GOTO_HOME_POSITION,
    payload
  }),

  goToHomePositionFulfilled: (payload) => ({
    type: CAMERA_GOTO_HOME_POSITION_FULFILLED,
    payload
  }),

  goToHomePositionError: (error) => ({
    type: CAMERA_GOTO_HOME_POSITION_REJECTED,
    payload: error
  }),

  goToHomePositionEpics: (action$) =>
    action$.pipe(
      ofType(CAMERA_GOTO_HOME_POSITION),
      mergeMap((action) =>
        from(gotoHomePosition(action.payload)).pipe(
          map((payload) => liveCameraEpics.goToHomePositionFulfilled(payload)),
          catchError((error) => of(liveCameraEpics.goToHomePositionError(error)))
        )
      )
    ),

  // Set home position
  setHomePosition: (payload) => ({
    type: CAMERA_SET_HOME_POSITION,
    payload
  }),

  setHomePositionFulfilled: (payload) => ({
    type: CAMERA_SET_HOME_POSITION_FULFILLED,
    payload
  }),

  setHomePositionError: (error) => ({
    type: CAMERA_SET_HOME_POSITION_REJECTED,
    payload: error
  }),

  setHomePositionEpics: (action$) =>
    action$.pipe(
      ofType(CAMERA_SET_HOME_POSITION),
      mergeMap((action) =>
        from(setHomePosition(action.payload)).pipe(
          map((payload) => liveCameraEpics.setHomePositionFulfilled(payload)),
          catchError((error) => of(liveCameraEpics.setHomePositionError(error)))
        )
      )
    )
}

export default liveCameraEpics
