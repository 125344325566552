export const GET_LIVE_CAMERA_STUDIES = 'GET_LIVE_CAMERA_STUDIES'
export const GET_LIVE_CAMERA_STUDIES_FULFILLED = 'GET_LIVE_CAMERA_STUDIES_FULFILLED'
export const GET_LIVE_CAMERA_STUDIES_REJECTED = 'GET_LIVE_CAMERA_STUDIES_REJECTED'

export const GET_CAMERA_STATUS = 'GET_CAMERA_STATUS'
export const GET_CAMERA_STATUS_FULFILLED = 'GET_CAMERA_STATUS_FULFILLED'
export const GET_CAMERA_STATUS_REJECTED = 'GET_CAMERA_STATUS_REJECTED'

export const OPERATE_CAMERA = 'OPERATE_CAMERA'
export const OPERATE_CAMERA_FULFILLED = 'OPERATE_CAMERA_FULFILLED'
export const OPERATE_CAMERA_REJECTED = 'OPERATE_CAMERA_REJECTED'

export const CAMERA_GOTO_HOME_POSITION = 'CAMERA_GOTO_HOME_POSITION'
export const CAMERA_GOTO_HOME_POSITION_FULFILLED = 'CAMERA_GOTO_HOME_POSITION_FULFILLED'
export const CAMERA_GOTO_HOME_POSITION_REJECTED = 'CAMERA_GOTO_HOME_POSITION_REJECTED'

export const CAMERA_SET_HOME_POSITION = 'CAMERA_SET_HOME_POSITION'
export const CAMERA_SET_HOME_POSITION_FULFILLED = 'CAMERA_SET_HOME_POSITION_FULFILLED'
export const CAMERA_SET_HOME_POSITION_REJECTED = 'CAMERA_SET_HOME_POSITION_REJECTED'
