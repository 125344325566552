import { REDUCER_STATE } from '@/store/constants'
import {
  GET_ANALYTIC_REPORT_HTML,
  GET_ANALYTIC_REPORT_HTML_FULFILLED,
  GET_ANALYTIC_REPORT_HTML_REJECTED,
  GET_ANALYTIC_REPORT_PDF,
  GET_ANALYTIC_REPORT_PDF_FULFILLED,
  GET_ANALYTIC_REPORT_PDF_REJECTED,
  GET_PDF_REPORT,
  GET_PDF_REPORT_FULFILLED,
  GET_PDF_REPORT_REJECTED,
  GET_STUDY_EXCEL_REPORT_DATA,
  GET_STUDY_EXCEL_REPORT_DATA_FULFILLED,
  GET_STUDY_EXCEL_REPORT_DATA_REJECTED
} from '@/store/types'
import Immutable from 'immutable'

const FIELDS = REDUCER_STATE.REPORT.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.ERROR]: null,
  [FIELDS.IS_GETTING_STUDY_EXCEL_DATA]: false,
  [FIELDS.IS_GETTING_PDF]: false,
  [FIELDS.DATA]: null,
  [FIELDS.ANALYTIC_REPORT_DATA]: null
})

const handlers = {
  // Get Excel data for study
  [GET_STUDY_EXCEL_REPORT_DATA]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_STUDY_EXCEL_DATA]: true,
      [FIELDS.DATA]: null
    }),
  [GET_STUDY_EXCEL_REPORT_DATA_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_STUDY_EXCEL_DATA]: false,
      [FIELDS.DATA]: action.payload
    }),
  [GET_STUDY_EXCEL_REPORT_DATA_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_STUDY_EXCEL_DATA]: false
    }),
  // Get PDF for individual record
  [GET_PDF_REPORT]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_PDF]: true,
      [FIELDS.DATA]: null
    }),
  [GET_PDF_REPORT_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_PDF]: false,
      [FIELDS.DATA]: action.payload
    }),
  [GET_PDF_REPORT_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_PDF]: false
    }),
  // Get Analytic report as pdf for individual study
  [GET_ANALYTIC_REPORT_PDF]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ANALYTIC_REPORT_PDF]: true,
      [FIELDS.ANALYTIC_REPORT_DATA]: null
    }),
  [GET_ANALYTIC_REPORT_PDF_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ANALYTIC_REPORT_PDF]: false,
      [FIELDS.ANALYTIC_REPORT_DATA]: action.payload
    }),
  [GET_ANALYTIC_REPORT_PDF_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_ANALYTIC_REPORT_PDF]: false
    }),
  // Get Analytic report as html for individual study
  [GET_ANALYTIC_REPORT_HTML]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ANALYTIC_REPORT_HTML]: true,
      [FIELDS.ANALYTIC_REPORT_DATA]: null
    }),
  [GET_ANALYTIC_REPORT_HTML_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ANALYTIC_REPORT_HTML]: false,
      [FIELDS.ANALYTIC_REPORT_DATA]: action.payload
    }),
  [GET_ANALYTIC_REPORT_HTML_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_ANALYTIC_REPORT_HTML]: false
    })
}

export default {
  initialState,
  handlers
}
