import { SHARED_FIELDS } from './shared'

const RECORD_FIELDS = {
  ...SHARED_FIELDS,
  ONSET_DATE_TIME: 'onsetDateTime',
  ANSWER: 'answer',
  LOCKED_BY: 'lockedBy',
  LOCKING_EXPIRES_AT: 'lockingExpiresAt',
  MRN: 'mrn',
  ZEUS_ID: 'zeusId',
  PATIENT_FIRST_NAME: 'patientFirstName',
  PATIENT_LAST_NAME: 'patientLastName',
  QUESTION_SET: 'questionSet',
  SITE_NAME: 'siteName',
  USER: 'user',
  SITE: 'site',
  COMPLETED: 'completed',
  PROGRESS_PERCENTAGE: 'progressPercentage',
  QUESTION: 'question'
}

export { RECORD_FIELDS }
