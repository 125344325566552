import API from './client'
import ENDPOINTS from './endpoints'
import { errorHandler, extractResponse } from './utils'

/**
 * Get current users contacts (excludes super admin, study admin, self and deleted users)
 * @return {Promise<*>}
 */
const getContacts = async ({ studyAdminRole, superAdminRole, currentUserId }) => {
  try {
    const response = await API.get(ENDPOINTS.USERS)
    const users = extractResponse(response).users
    return users.filter(
      (user) =>
        ![studyAdminRole, superAdminRole].includes(user.role) &&
        user._id !== currentUserId &&
        !user.deleted
    )
  } catch (err) {
    errorHandler(err)
  }
}

export { getContacts }
