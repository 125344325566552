import { REDUCER_STATE } from '@/store/constants'
import {
  CLEAR_CONVERSATION_DATA,
  CREATE_GROUP_CONVERSATION,
  CREATE_GROUP_CONVERSATION_FULFILLED,
  CREATE_GROUP_CONVERSATION_REJECTED,
  CREATE_PRIVATE_MESSAGE,
  CREATE_PRIVATE_MESSAGE_FULFILLED,
  CREATE_PRIVATE_MESSAGE_REJECTED,
  GET_CONVERSATION_ACCESS_TOKEN,
  GET_CONVERSATION_ACCESS_TOKEN_FULFILLED,
  GET_CONVERSATION_ACCESS_TOKEN_REJECTED,
  GET_CONVERSATION_USERS,
  GET_CONVERSATION_USERS_FULFILLED,
  GET_CONVERSATION_USERS_REJECTED,
  GET_PARTICIPANT_RESOURCE,
  GET_PARTICIPANT_RESOURCE_FULFILLED,
  GET_PARTICIPANT_RESOURCE_REJECTED,
  GET_ZEUS_SITES,
  GET_ZEUS_SITES_FULFILLED,
  GET_ZEUS_SITES_REJECTED,
  GET_ZEUS_STUDIES,
  GET_ZEUS_STUDIES_FULFILLED,
  GET_ZEUS_STUDIES_REJECTED,
  GET_ZEUS_USERS,
  GET_ZEUS_USERS_FULFILLED,
  GET_ZEUS_USERS_REJECTED,
  INVITE_USERS,
  INVITE_USERS_FULFILLED,
  INVITE_USERS_REJECTED,
  LEAVE_CONVERSATION,
  LEAVE_CONVERSATION_FULFILLED,
  LEAVE_CONVERSATION_REJECTED,
  SEND_MESSAGE,
  SEND_MESSAGE_FULFILLED,
  SEND_MESSAGE_REJECTED,
  SET_IS_CONVERSATION_READY,
  SET_PRIVATE_MESSAGE_TARGET_FROM_CONTACTS,
  UPDATE_CONVERSATION,
  UPDATE_CONVERSATION_FULFILLED,
  UPDATE_CONVERSATION_REJECTED,
  UPDATE_PARTICIPANT_RESOURCE,
  UPDATE_PARTICIPANT_RESOURCE_FULFILLED,
  UPDATE_PARTICIPANT_RESOURCE_REJECTED
} from '@/store/types'
import Immutable from 'immutable'

const FIELDS = REDUCER_STATE.CONVERSATION.FIELDS

const initialState = new Immutable.Map({
  [FIELDS.DATA]: null,
  [FIELDS.PRIVATE_MESSAGE_TARGET_FROM_CONTACTS]: null,
  [FIELDS.ERROR]: null,
  [FIELDS.IS_CONVERSATION_READY]: false,
  [FIELDS.IS_CREATING_CONVERSATION]: false,
  [FIELDS.IS_CREATING_PRIVATE_MESSAGE]: false,
  [FIELDS.IS_LEAVING_CONVERSATION]: false,
  [FIELDS.IS_DELETING_CONVERSATION]: false,
  [FIELDS.IS_GETTING_CONVERSATION]: false,
  [FIELDS.IS_GETTING_CONVERSATION_USERS]: false,
  [FIELDS.IS_GETTING_PARTICIPANT_RESOURCE]: false,
  [FIELDS.IS_GETTING_ZEUS_SITE]: false,
  [FIELDS.IS_GETTING_ZEUS_STUDY]: false,
  [FIELDS.IS_GETTING_ZEUS_USER]: false,
  [FIELDS.IS_GETTING_ACCESS_TOKEN]: false,
  [FIELDS.IS_INVITING_USERS]: false,
  [FIELDS.IS_SENDING_MESSAGE]: false,
  [FIELDS.IS_UPDATING_CONVERSATION]: false,
  [FIELDS.IS_UPDATING_CONVERSATION_USER]: false,
  [FIELDS.IS_UPDATING_PARTICIPANT_RESOURCE]: false,
  [FIELDS.SITE_LIST]: [],
  [FIELDS.STUDY_LIST]: [],
  [FIELDS.USER_LIST]: []
})

const handlers = {
  [GET_ZEUS_STUDIES]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ZEUS_STUDY]: true,
      [FIELDS.STUDY_LIST]: []
    }),
  [GET_ZEUS_STUDIES_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ZEUS_STUDY]: false,
      [FIELDS.STUDY_LIST]: action.payload
    }),
  [GET_ZEUS_STUDIES_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ZEUS_STUDY]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [GET_ZEUS_SITES]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ZEUS_SITE]: true,
      [FIELDS.SITE_LIST]: []
    }),
  [GET_ZEUS_SITES_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ZEUS_SITE]: false,
      [FIELDS.SITE_LIST]: action.payload
    }),
  [GET_ZEUS_SITES_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ZEUS_SITE]: false,
      [FIELDS.ERROR]: action.payload
    }),

  [GET_ZEUS_USERS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ZEUS_USER]: true,
      [FIELDS.USER_LIST]: []
    }),
  [GET_ZEUS_USERS_FULFILLED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ZEUS_USER]: false,
      [FIELDS.USER_LIST]: action.payload
    }),
  [GET_ZEUS_USERS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.IS_GETTING_ZEUS_USER]: false,
      [FIELDS.ERROR]: action.payload
    }),

  // twilio conversation
  [GET_CONVERSATION_ACCESS_TOKEN]: (state, action) => {
    if (action.payload) {
      return state.merge({
        [FIELDS.ERROR]: null,
        [FIELDS.IS_GETTING_ACCESS_TOKEN]: true
      })
    }
    return state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_ACCESS_TOKEN]: true,
      [FIELDS.DATA]: null
    })
  },
  [GET_CONVERSATION_ACCESS_TOKEN_FULFILLED]: (state, action) => {
    if (action.payload.isUpdateToken) {
      const currentData = state.getIn([FIELDS.DATA])

      return state.merge({
        [FIELDS.IS_GETTING_ACCESS_TOKEN]: false,
        [FIELDS.DATA]: {
          ...currentData,
          ...action.payload.payload
        }
      })
    }
    return state.merge({
      [FIELDS.IS_GETTING_ACCESS_TOKEN]: false,
      [FIELDS.DATA]: action.payload.payload
    })
  },
  [GET_CONVERSATION_ACCESS_TOKEN_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_ACCESS_TOKEN]: false
    }),

  [GET_CONVERSATION_USERS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_CONVERSATION_USERS]: true
    }),
  [GET_CONVERSATION_USERS_FULFILLED]: (state, action) => {
    const currentData = state.getIn([FIELDS.DATA])
    return state.merge({
      [FIELDS.IS_GETTING_CONVERSATION_USERS]: false,
      [FIELDS.DATA]: { ...currentData, ...action.payload }
    })
  },
  [GET_CONVERSATION_USERS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_CONVERSATION_USERS]: false
    }),

  [CREATE_GROUP_CONVERSATION]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING_CONVERSATION]: true
    }),
  [CREATE_GROUP_CONVERSATION_FULFILLED]: (state, action) => {
    const currentData = state.getIn([FIELDS.DATA])
    return state.merge({
      [FIELDS.IS_CREATING_CONVERSATION]: false,
      [FIELDS.DATA]: { ...currentData, ...action.payload }
    })
  },
  [CREATE_GROUP_CONVERSATION_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_CREATING_CONVERSATION]: false
    }),

  [UPDATE_CONVERSATION]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING_CONVERSATION]: true
    }),
  [UPDATE_CONVERSATION_FULFILLED]: (state, action) => {
    const currentData = state.getIn([FIELDS.DATA])
    return state.merge({
      [FIELDS.IS_UPDATING_CONVERSATION]: false,
      [FIELDS.DATA]: { ...currentData, ...action.payload }
    })
  },
  [UPDATE_CONVERSATION_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_UPDATING_CONVERSATION]: false
    }),
  // invite user to CONVERSATION
  [INVITE_USERS]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_INVITING_USERS]: true
    }),
  [INVITE_USERS_FULFILLED]: (state) => {
    return state.merge({
      [FIELDS.IS_INVITING_USERS]: false
    })
  },
  [INVITE_USERS_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_INVITING_USERS]: false
    }),

  // send message
  [SEND_MESSAGE]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_SENDING_MESSAGE]: true
    }),
  [SEND_MESSAGE_FULFILLED]: (state) => {
    return state.merge({
      [FIELDS.IS_SENDING_MESSAGE]: false
    })
  },
  [SEND_MESSAGE_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_SENDING_MESSAGE]: false
    }),

  [GET_PARTICIPANT_RESOURCE]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_GETTING_PARTICIPANT_RESOURCE]: true
    }),
  [GET_PARTICIPANT_RESOURCE_FULFILLED]: (state, action) => {
    const currentData = state.getIn([FIELDS.DATA])
    const currentParticipantResources = currentData?.currentParticipantResource
    const conversationSid = action.payload?.conversationSid
    const temp = { [conversationSid]: action.payload }

    return state.merge({
      [FIELDS.IS_GETTING_PARTICIPANT_RESOURCE]: false,
      [FIELDS.DATA]: {
        ...currentData,
        currentParticipantResource: Object.assign({}, currentParticipantResources, temp)
      }
    })
  },
  [GET_PARTICIPANT_RESOURCE_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_GETTING_PARTICIPANT_RESOURCE]: false
    }),

  [UPDATE_PARTICIPANT_RESOURCE]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_UPDATING_PARTICIPANT_RESOURCE]: true
    }),
  [UPDATE_PARTICIPANT_RESOURCE_FULFILLED]: (state) => {
    return state.merge({
      [FIELDS.IS_UPDATING_PARTICIPANT_RESOURCE]: false
    })
  },
  [UPDATE_PARTICIPANT_RESOURCE_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_UPDATING_PARTICIPANT_RESOURCE]: false
    }),

  [CLEAR_CONVERSATION_DATA]: (state) =>
    state.merge({
      [FIELDS.DATA]: null
    }),

  [SET_PRIVATE_MESSAGE_TARGET_FROM_CONTACTS]: (state, action) =>
    state.merge({
      [FIELDS.PRIVATE_MESSAGE_TARGET_FROM_CONTACTS]: action.payload
    }),

  [CREATE_PRIVATE_MESSAGE]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_CREATING_PRIVATE_MESSAGE]: true
    }),
  [CREATE_PRIVATE_MESSAGE_FULFILLED]: (state, action) => {
    const currentData = state.getIn([FIELDS.DATA])
    return state.merge({
      [FIELDS.IS_CREATING_PRIVATE_MESSAGE]: false,
      [FIELDS.DATA]: { ...currentData, ...action.payload }
    })
  },
  [CREATE_PRIVATE_MESSAGE_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_CREATING_PRIVATE_MESSAGE]: false
    }),

  [LEAVE_CONVERSATION]: (state) =>
    state.merge({
      [FIELDS.ERROR]: null,
      [FIELDS.IS_LEAVING_CONVERSATION]: true
    }),
  [LEAVE_CONVERSATION_FULFILLED]: (state) => {
    return state.merge({
      [FIELDS.IS_LEAVING_CONVERSATION]: false
    })
  },
  [LEAVE_CONVERSATION_REJECTED]: (state, action) =>
    state.merge({
      [FIELDS.ERROR]: action.payload,
      [FIELDS.IS_LEAVING_CONVERSATION]: false
    }),

  [SET_IS_CONVERSATION_READY]: (state, action) =>
    state.merge({
      [FIELDS.IS_CONVERSATION_READY]: action.payload
    })
}

export default {
  initialState,
  handlers
}
