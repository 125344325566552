import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '@/components/loading'
import authEpics from '@/store/epics/auth'
import { REDUCER_STATE } from '@/store/constants'
import { ROOT_ROUTES } from '@/routes/route-path'
import Header from '@/components/header'
import {
  AuthLayoutContentWrapper,
  AuthLayoutLowerWrapper,
  AuthLayoutWrapper
} from '@/styles/layout'
import Navigation from '@/components/navigation'
import classNames from 'classnames'

/**
 * Check current user authorization before enter the view
 */
const AuthRoute = (props) => {
  const {
    component: Component,
    noAuthReq,
    activeTab,
    isBackgroundGrey = false,
    isHideNavigation = false,
    isHideHeader = false,
    ...otherProps
  } = props
  const { path } = otherProps
  const dispatch = useDispatch()
  const isAuthorized = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.IS_AUTHORIZED])
  )
  const isVerifying = useSelector((state) =>
    state.getIn([REDUCER_STATE.AUTH.NAME, REDUCER_STATE.AUTH.FIELDS.IS_VERIFYING])
  )
  const isGettingGeneralInfo = useSelector((state) =>
    state.getIn([REDUCER_STATE.GENERAL_INFO.NAME, REDUCER_STATE.GENERAL_INFO.FIELDS.IS_PENDING])
  )
  const [isPending, setIsPending] = useState(true)
  const prevIsVerifying = useRef(isVerifying)
  const prevIsGettingGeneralInfo = useRef(isGettingGeneralInfo)

  /**
   * Request to check if current user is authorized
   */
  useEffect(() => {
    if (!noAuthReq) {
      dispatch(authEpics.auth())
    }
  }, [path, noAuthReq])

  useEffect(() => {
    if (!noAuthReq) {
      if (!isGettingGeneralInfo && prevIsGettingGeneralInfo.current) {
        setIsPending(false)
      } else if (isVerifying && !isAuthorized) {
        setIsPending(true)
      } else if (!isVerifying && prevIsVerifying.current && !isAuthorized) {
        setIsPending(false)
      }
      prevIsGettingGeneralInfo.current = isGettingGeneralInfo
      prevIsVerifying.current = isVerifying
    }
  }, [isVerifying, isGettingGeneralInfo, isAuthorized, noAuthReq])

  if (noAuthReq) {
    return (
      <Route {...otherProps} render={(props) => (isAuthorized ? <Component {...props} /> : null)} />
    )
  }
  if (isHideHeader) {
    return (
      <Route
        {...otherProps}
        render={(props) =>
          !isPending ? (
            isAuthorized ? (
              <Component {...props} />
            ) : (
              <Redirect to={ROOT_ROUTES.LOGIN} />
            )
          ) : (
            <Loading />
          )
        }
      />
    )
  } else {
    return (
      <AuthLayoutWrapper>
        {activeTab && <Header active={activeTab} />}
        <AuthLayoutLowerWrapper className={classNames({ grey: isBackgroundGrey })}>
          <Navigation isVisible={!isHideNavigation} />
          <AuthLayoutContentWrapper isHideNavigation={isHideNavigation}>
            <Route
              {...otherProps}
              render={(props) =>
                !isPending ? (
                  isAuthorized ? (
                    <Component {...props} />
                  ) : (
                    <Redirect to={ROOT_ROUTES.LOGIN} />
                  )
                ) : (
                  <Loading />
                )
              }
            />
          </AuthLayoutContentWrapper>
        </AuthLayoutLowerWrapper>
      </AuthLayoutWrapper>
    )
  }
}

AuthRoute.propTypes = {
  component: PropTypes.object.isRequired,
  noAuthReq: PropTypes.bool,
  activeTab: PropTypes.string,
  isBackgroundGrey: PropTypes.bool,
  isHideNavigation: PropTypes.bool,
  isHideHeader: PropTypes.bool
}

export default AuthRoute
