import client, { LOCAL_STORAGE_KEYS } from './client'

/**
 * Get current user from local storage
 * @returns {Promise<object>}
 */
const getCurrentUser = () => client.getItem(LOCAL_STORAGE_KEYS.CURRENT_USER)

/**
 * Set current user to local storage
 * @param   {object}                 currentUser - current user
 * @returns {Promise}
 */
const setCurrentUser = (currentUser) => client.setItem(LOCAL_STORAGE_KEYS.CURRENT_USER, currentUser)

/**
 * Get current user from local storage
 * @returns {Promise<object>}
 */
const getCookieExpiry = () => client.getItem(LOCAL_STORAGE_KEYS.COOKIE_EXPIRY)

/**
 * Set current user to local storage
 * @param   {object}                 expiry - time to cookie expiry in ms
 * @returns {Promise}
 */
const setCookieExpiry = (expiry) => client.setItem(LOCAL_STORAGE_KEYS.COOKIE_EXPIRY, expiry)

export { getCookieExpiry, getCurrentUser, setCookieExpiry, setCurrentUser }
