import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { from, iif, of } from 'rxjs'
import { ofType } from 'redux-observable'

import {
  ACKNOWLEDGE_SENDER,
  ACKNOWLEDGE_SENDER_FULFILLED,
  ACKNOWLEDGE_SENDER_REJECTED,
  AUTH_FULFILLED,
  CLEAR_NOTIFICATION_IDS,
  CREATE_NOTIFICATION_TEMPLATE,
  CREATE_NOTIFICATION_TEMPLATE_FULFILLED,
  CREATE_NOTIFICATION_TEMPLATE_REJECTED,
  DELETE_NOTIFICATION_TEMPLATE,
  DELETE_NOTIFICATION_TEMPLATE_FULFILLED,
  DELETE_NOTIFICATION_TEMPLATE_REJECTED,
  FIREBASE_INIT_FULFILLED,
  FIREBASE_INIT_REJECTED,
  FIREBASE_MESSAGING_INIT_FULFILLED,
  FIREBASE_MESSAGING_INIT_REJECTED,
  GET_NOTIFICATION,
  GET_NOTIFICATION_FULFILLED,
  GET_NOTIFICATION_REJECTED,
  GET_NOTIFICATION_TEMPLATES,
  GET_NOTIFICATION_TEMPLATES_FULFILLED,
  GET_NOTIFICATION_TEMPLATES_REJECTED,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FULFILLED,
  GET_NOTIFICATIONS_REJECTED,
  LOGOUT_FULFILLED,
  NEW_NOTIFICATION_INCOMING,
  REQUEST_NOTIFICATION_PERMISSION_FULFILLED,
  REQUEST_NOTIFICATION_PERMISSION_REJECTED,
  SEND_NOTIFICATION_TEMPLATE,
  SEND_NOTIFICATION_TEMPLATE_FULFILLED,
  SEND_NOTIFICATION_TEMPLATE_REJECTED,
  SET_IS_REGISTERING_NOTIFICATION,
  SET_IS_SHOW_ACKNOWLEDGE_MODAL,
  SET_NOTIFICATIONS_FULFILLED,
  SET_NOTIFICATIONS_REJECTED,
  SET_TWILIO_CHAT_NOTIFICATIONS,
  SET_TWILIO_CHAT_NOTIFICATIONS_FULFILLED,
  SET_TWILIO_CHAT_NOTIFICATIONS_REJECTED,
  UPDATE_IS_NOTIFICATION_SUPPORTED,
  UPDATE_NOTIFICATION_TEMPLATE,
  UPDATE_NOTIFICATION_TEMPLATE_FULFILLED,
  UPDATE_NOTIFICATION_TEMPLATE_ORDER,
  UPDATE_NOTIFICATION_TEMPLATE_ORDER_FULFILLED,
  UPDATE_NOTIFICATION_TEMPLATE_ORDER_REJECTED,
  UPDATE_NOTIFICATION_TEMPLATE_REJECTED
} from '@/store/types'
import { REDUCER_STATE } from '@/store/constants'
import { SYSTEM_NOTIFICATION_FIELDS } from '@/constants/api-data/rawNotification'
import { initFirebase, initMessaging, requestPermission } from '@/services/firebase'
import {
  acknowledgeSender,
  createNotificationTemplate,
  deleteNotificationTemplate,
  getNotification,
  getNotifications,
  getNotificationTemplates,
  sendNotification,
  updateNotificationTemplate,
  updateNotificationTemplateOrder
} from '@/services/api'

const notificationEpics = {
  updateIsNotificationSupported: (isSupported) => ({
    type: UPDATE_IS_NOTIFICATION_SUPPORTED,
    payload: isSupported
  }),

  initFirebaseFulfilled: () => ({
    type: FIREBASE_INIT_FULFILLED
  }),

  initFirebaseError: (payload) => ({
    type: FIREBASE_INIT_REJECTED,
    payload
  }),

  /**
   * Init Firebase client after user auth succeed
   * @param {Observable<Action>} action$ - redux action observable
   */
  initFirebaseEpic: (action$) =>
    action$.pipe(
      ofType(AUTH_FULFILLED),
      mergeMap(() =>
        from(initFirebase()).pipe(
          map(notificationEpics.initFirebaseFulfilled),
          catchError((err) => of(notificationEpics.initFirebaseError(err)))
        )
      )
    ),

  requestNotificationPermissionFulfilled: () => ({
    type: REQUEST_NOTIFICATION_PERMISSION_FULFILLED
  }),

  requestNotificationPermissionError: (payload) => ({
    type: REQUEST_NOTIFICATION_PERMISSION_REJECTED,
    payload
  }),

  /**
   * Request notification after Firebase client init
   * @param {Observable<Action>} action$ - redux action observable
   */
  requestNotificationPermissionEpic: (action$) =>
    action$.pipe(
      ofType(FIREBASE_INIT_FULFILLED),
      mergeMap(() =>
        from(requestPermission()).pipe(
          map(notificationEpics.requestNotificationPermissionFulfilled),
          catchError((err) => of(notificationEpics.requestNotificationPermissionError(err)))
        )
      )
    ),

  initMessagingFulfilled: (payload) => ({
    type: FIREBASE_MESSAGING_INIT_FULFILLED,
    payload
  }),

  initMessagingError: (payload) => ({
    type: FIREBASE_MESSAGING_INIT_REJECTED,
    payload
  }),

  /**
   * Init Firebase messaging after notification permission granted
   * @param {Observable<Action>} action$ - redux action observable
   */
  initMessagingEpic: (action$) =>
    action$.pipe(
      ofType(REQUEST_NOTIFICATION_PERMISSION_FULFILLED),
      mergeMap(() =>
        from(initMessaging()).pipe(
          map(notificationEpics.initMessagingFulfilled),
          catchError((err) => of(notificationEpics.initMessagingError(err)))
        )
      )
    ),

  getNotifications: (payload) => ({
    type: GET_NOTIFICATIONS,
    payload
  }),

  getNotificationsFulfilled: (payload) => ({
    type: GET_NOTIFICATIONS_FULFILLED,
    payload
  }),

  getNotificationsError: (payload) => ({
    type: GET_NOTIFICATIONS_REJECTED,
    payload
  }),
  /**
   * Get current user's notifications
   */
  getNotificationsEpic: (action$) =>
    action$.pipe(
      ofType(GET_NOTIFICATIONS, LOGOUT_FULFILLED),
      mergeMap((action) =>
        iif(
          () => action.type === GET_NOTIFICATIONS,
          of(action.payload).pipe(
            mergeMap((payload) =>
              from(getNotifications(payload)).pipe(
                map(notificationEpics.getNotificationsFulfilled),
                catchError((err) => of(notificationEpics.getNotificationsError(err)))
              )
            )
          ),
          of(notificationEpics.getNotificationsFulfilled({ notifications: [], totalPages: 0 }))
        )
      )
    ),

  getNotification: (payload) => ({
    type: GET_NOTIFICATION,
    payload
  }),

  getNotificationFulfilled: (payload) => ({
    type: GET_NOTIFICATION_FULFILLED,
    payload
  }),

  getNotificationError: (payload) => ({
    type: GET_NOTIFICATION_REJECTED,
    payload
  }),

  getNotificationEpic: (action$) =>
    action$.pipe(
      ofType(GET_NOTIFICATION, LOGOUT_FULFILLED),
      mergeMap((action) =>
        iif(
          () => action.type === GET_NOTIFICATION,
          of(action.payload).pipe(
            mergeMap((payload) =>
              from(getNotification(payload)).pipe(
                map(notificationEpics.getNotificationFulfilled),
                catchError((err) => of(notificationEpics.getNotificationError(err)))
              )
            )
          ),
          of(notificationEpics.getNotificationFulfilled(null))
        )
      )
    ),

  setTwilioChatNotifications: (payload) => ({
    type: SET_TWILIO_CHAT_NOTIFICATIONS,
    payload
  }),

  setTwilioChatNotificationsFulfilled: (payload) => ({
    type: SET_TWILIO_CHAT_NOTIFICATIONS_FULFILLED,
    payload
  }),

  setTwilioChatNotificationsError: (payload) => ({
    type: SET_TWILIO_CHAT_NOTIFICATIONS_REJECTED,
    payload
  }),

  /**
   * Set notification for current user when new notification comes
   */
  setTwilioChatNotificationsEpic: (action$, state$) =>
    action$.pipe(
      ofType(SET_TWILIO_CHAT_NOTIFICATIONS),
      filter((action) => action.payload !== undefined),
      withLatestFrom(state$),

      map(([action]) => {
        return action.payload
      }),
      map(notificationEpics.setTwilioChatNotificationsFulfilled),
      catchError((err) => of(notificationEpics.setTwilioChatNotificationsError(err)))
    ),

  // setNotifications: (payload) => ({
  //   type: SET_NOTIFICATIONS,
  //   payload
  // }),

  setNotificationsFulfilled: (payload) => ({
    type: SET_NOTIFICATIONS_FULFILLED,
    payload
  }),

  setNotificationsError: (payload) => ({
    type: SET_NOTIFICATIONS_REJECTED,
    payload
  }),

  /**
   * Set notification for current user when new notification comes
   */
  setNotificationsEpic: (action$, state$) =>
    action$.pipe(
      ofType(NEW_NOTIFICATION_INCOMING),
      filter(
        (action) =>
          action.payload !== undefined && action.payload[SYSTEM_NOTIFICATION_FIELDS.PERSISTED.name]
      ),
      withLatestFrom(state$),
      map(([actions, state]) => [
        actions,
        state.getIn([
          REDUCER_STATE.NOTIFICATION.NAME,
          REDUCER_STATE.NOTIFICATION.FIELDS.NOTIFICATIONS
        ])
      ]),
      map(([action, notifications]) => {
        if (notifications) {
          return [action.payload, ...notifications]
        } else {
          return [action.payload]
        }
      }),
      map(notificationEpics.setNotificationsFulfilled),
      catchError((err) => of(notificationEpics.setNotificationsError(err)))
    ),

  /**
   * notification template
   */
  getNotificationTemplates: (payload) => ({
    type: GET_NOTIFICATION_TEMPLATES,
    payload
  }),

  getNotificationTemplatesFulfilled: (payload) => ({
    type: GET_NOTIFICATION_TEMPLATES_FULFILLED,
    payload
  }),

  getNotificationTemplatesError: (payload) => ({
    type: GET_NOTIFICATION_TEMPLATES_REJECTED,
    payload
  }),

  getNotificationTemplatesEpic: (action$) =>
    action$.pipe(
      ofType(GET_NOTIFICATION_TEMPLATES),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(getNotificationTemplates(action.payload)).pipe(
          map(notificationEpics.getNotificationTemplatesFulfilled),
          catchError((err) => of(notificationEpics.getNotificationTemplatesError(err)))
        )
      )
    ),

  createNotificationTemplate: (payload) => ({
    type: CREATE_NOTIFICATION_TEMPLATE,
    payload
  }),

  createNotificationTemplateFulfilled: (payload) => ({
    type: CREATE_NOTIFICATION_TEMPLATE_FULFILLED,
    payload
  }),

  createNotificationTemplateError: (payload) => ({
    type: CREATE_NOTIFICATION_TEMPLATE_REJECTED,
    payload
  }),

  createNotificationTemplateEpic: (action$) =>
    action$.pipe(
      ofType(CREATE_NOTIFICATION_TEMPLATE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(createNotificationTemplate(action.payload)).pipe(
          map(notificationEpics.createNotificationTemplateFulfilled),
          catchError((err) => of(notificationEpics.createNotificationTemplateError(err)))
        )
      )
    ),

  updateNotificationTemplate: (payload) => ({
    type: UPDATE_NOTIFICATION_TEMPLATE,
    payload
  }),

  updateNotificationTemplateFulfilled: (payload) => ({
    type: UPDATE_NOTIFICATION_TEMPLATE_FULFILLED,
    payload
  }),

  updateNotificationTemplateError: (payload) => ({
    type: UPDATE_NOTIFICATION_TEMPLATE_REJECTED,
    payload
  }),

  updateNotificationTemplateEpic: (action$) =>
    action$.pipe(
      ofType(UPDATE_NOTIFICATION_TEMPLATE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(updateNotificationTemplate(action.payload)).pipe(
          map(notificationEpics.updateNotificationTemplateFulfilled),
          catchError((err) => of(notificationEpics.updateNotificationTemplateError(err)))
        )
      )
    ),

  // Update template order
  updateNotificationTemplateOrder: (payload) => ({
    type: UPDATE_NOTIFICATION_TEMPLATE_ORDER,
    payload
  }),

  updateNotificationTemplateOrderFulfilled: (payload) => ({
    type: UPDATE_NOTIFICATION_TEMPLATE_ORDER_FULFILLED,
    payload
  }),

  updateNotificationTemplateOrderError: (payload) => ({
    type: UPDATE_NOTIFICATION_TEMPLATE_ORDER_REJECTED,
    payload
  }),

  updateNotificationTemplateOrderEpic: (action$) =>
    action$.pipe(
      ofType(UPDATE_NOTIFICATION_TEMPLATE_ORDER),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(updateNotificationTemplateOrder(action.payload)).pipe(
          map(notificationEpics.updateNotificationTemplateOrderFulfilled),
          catchError((err) => of(notificationEpics.updateNotificationTemplateOrderError(err)))
        )
      )
    ),

  deleteNotificationTemplate: (payload) => ({
    type: DELETE_NOTIFICATION_TEMPLATE,
    payload
  }),

  deleteNotificationTemplateFulfilled: (payload) => ({
    type: DELETE_NOTIFICATION_TEMPLATE_FULFILLED,
    payload
  }),

  deleteNotificationTemplateError: (payload) => ({
    type: DELETE_NOTIFICATION_TEMPLATE_REJECTED,
    payload
  }),

  deleteNotificationTemplateEpic: (action$) =>
    action$.pipe(
      ofType(DELETE_NOTIFICATION_TEMPLATE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(deleteNotificationTemplate(action.payload)).pipe(
          map(notificationEpics.deleteNotificationTemplateFulfilled),
          catchError((err) => of(notificationEpics.deleteNotificationTemplateError(err)))
        )
      )
    ),

  acknowledgeSender: (payload) => ({
    type: ACKNOWLEDGE_SENDER,
    payload
  }),

  acknowledgeSenderFulfilled: (payload) => ({
    type: ACKNOWLEDGE_SENDER_FULFILLED,
    payload
  }),

  acknowledgeSenderError: (payload) => ({
    type: ACKNOWLEDGE_SENDER_REJECTED,
    payload
  }),

  acknowledgeSenderEpic: (action$) =>
    action$.pipe(
      ofType(ACKNOWLEDGE_SENDER),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(acknowledgeSender(action.payload)).pipe(
          map(() => notificationEpics.acknowledgeSenderFulfilled(action.payload)),
          catchError((err) => of(notificationEpics.acknowledgeSenderError(err)))
        )
      )
    ),

  sendNotificationTemplate: (payload) => ({
    type: SEND_NOTIFICATION_TEMPLATE,
    payload
  }),

  sendNotificationTemplateFulfilled: (payload) => ({
    type: SEND_NOTIFICATION_TEMPLATE_FULFILLED,
    payload
  }),

  sendNotificationTemplateError: (payload) => ({
    type: SEND_NOTIFICATION_TEMPLATE_REJECTED,
    payload
  }),

  sendNotificationTemplateEpic: (action$) =>
    action$.pipe(
      ofType(SEND_NOTIFICATION_TEMPLATE),
      filter((action) => action.payload !== undefined),
      mergeMap((action) =>
        from(sendNotification(action.payload)).pipe(
          map(notificationEpics.sendNotificationTemplateFulfilled),
          catchError((err) => of(notificationEpics.sendNotificationTemplateError(err)))
        )
      )
    ),

  newNotificationIncoming: (payload) => ({
    type: NEW_NOTIFICATION_INCOMING,
    payload
  }),

  clearNewNotificationIds: (payload) => ({
    type: CLEAR_NOTIFICATION_IDS,
    payload
  }),

  // Avoid call register api multiple times
  setIsRegisteringNotification: (payload) => ({
    type: SET_IS_REGISTERING_NOTIFICATION,
    payload
  }),

  setIsShowAcknowledgeStatusModel: (payload) => ({
    type: SET_IS_SHOW_ACKNOWLEDGE_MODAL,
    payload
  })
}

export default notificationEpics
