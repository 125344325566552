import { BehaviorSubject, EMPTY, iif } from 'rxjs'
import { combineEpics } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

import authEpics from '@/store/epics/auth'
import liveCameraEpics from '@/store/epics/live-camera'
import conferenceEpics from '@/store/epics/conference'
import generalEpics from '@/store/epics/general'
import notificationEpics from '@/store/epics/notification'
import { BOOLEAN, ENV } from '@/constants'
import trackEpics from '@/store/epics/track'

// default registered epic
let epicRegistry = [
  authEpics.authEpic,
  conferenceEpics.endRoomEpic,
  conferenceEpics.inviteParticipantsEpic,
  conferenceEpics.joinRoomEpic,
  conferenceEpics.listenOnInvitationResponseNotification,
  conferenceEpics.listenOnUserInvitationStatusChangeEpic,
  conferenceEpics.responseInvitationEpic,
  conferenceEpics.updateInvitedUserInvitationStatusEpic,
  generalEpics.getApiVersionEpic,
  generalEpics.getGeneralInfoEpic,
  notificationEpics.getNotificationsEpic,
  notificationEpics.acknowledgeSenderEpic,
  notificationEpics.getNotificationEpic,
  trackEpics.getAndUpdateTrackFromNotifyEpic,
  liveCameraEpics.getLiveCameraStudiesEpic,
  liveCameraEpics.operateCameraEpics,
  liveCameraEpics.getCameraStatusEpics,
  liveCameraEpics.goToHomePositionEpics,
  liveCameraEpics.setHomePositionEpics
]

if (
  process.env.NODE_ENV?.toLowerCase() === ENV.PRODUCTION.toLowerCase() ||
  process.env.REACT_APP_ENABLE_SERVICE_WORKER_LOCAL?.toLowerCase() === BOOLEAN.TRUE.toLowerCase()
) {
  epicRegistry = [
    ...epicRegistry,
    notificationEpics.initFirebaseEpic,
    notificationEpics.initMessagingEpic,
    notificationEpics.requestNotificationPermissionEpic,
    notificationEpics.setNotificationsEpic,
    notificationEpics.setTwilioChatNotificationsEpic
  ]
}

export const epic$ = new BehaviorSubject(combineEpics(...epicRegistry))

const rootEpic = (action$, state$) =>
  epic$.pipe(
    mergeMap((epic) =>
      iif(
        () => {
          const isExist = epicRegistry.includes(epic)
          if (!isExist) {
            epicRegistry.push(epic)
          }
          return isExist
        },
        EMPTY,
        epic(action$, state$)
      )
    )
  )

export default rootEpic
